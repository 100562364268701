<template>
<section class="recitersView">
    <v-main>
        <div class="container--fluid" style="">
            <div class="all-card">
                <div class="top-bar-search">
                    <div class="search-section">
                        <form>
                            <div class="search">
                                <v-row>
                                    <v-col cols="8" style="padding-left: 4px">
                                        <search-input :style="styleSearch" />
                                    </v-col>
                                    <v-col cols="4">
                                        <div class="main-container-date">
                                            <button>بحث</button>
                                        </div>
                                    </v-col>
                                </v-row>
                            </div>
                        </form>
                    </div>
                    <div class="import-export-file">
                        <div style="display: flex;align-items: center">
                            <div class="select-way-reciters">
                                <!-- <select-option-customer class="mt-2" /> -->
                            </div>
                            <div class="export-file">
                                <import-file :icon="'fa fa-upload'" :name="'رفع ملف اكسل'" />
                            </div>
                            <div class="import-file">
                                <import-file :icon="'fa fa-cloud-download'" :name="'تصدير الملف'" />
                            </div>
                        </div>
                        <div class="add-new">
                            <dialog-modal :name-input="' إضافة مقرء جديد'" :title="'إضافة مقرء جديد'">
                                <div class="form-modal">
                                    <validation-observer ref="form" v-slot="{ invalid, handleSubmit }">

                                        <v-form @submit.prevent="handleSubmit(createItem)" :disabled="submitOnProgress">
                                            <v-row>
                                                <v-col cols="12">
                                                    <ValidationProvider name="اسم المقرء'" :rules="rules.reader_id" v-slot="{ errors }">
                                                        <v-select :items="employees" item-text="full_name" item-value="id" placeholder="اسم المقرء" :error-messages="errors" v-model="reader_id" outlined dense></v-select>
                                                    </ValidationProvider>
                                                </v-col>
                                                <v-col cols="12" lg="6" md="6">
                                                    <ValidationProvider name="الفروع" :rules="rules.branch" v-slot="{ errors }">
                                                        <select-input item-text="name" item-value="id" v-model="branch_id" :field-errors="errors" :label="'الفروع'" :name="'أختر الفرع'" :items="branches" />
                                                    </ValidationProvider>
                                                </v-col>
                                                <v-col cols="12" lg="6" md="6">
                                                    <ValidationProvider name="ايام التسميع" :rules="rules.day" v-slot="{ errors }">
                                                        <select-input item-text="text" item-value="value" v-model="day" :field-errors="errors" :label="'ايام التسميع'" :name="'أختر ايام التسميع'" :items="days" multiple />
                                                    </ValidationProvider>
                                                </v-col>
                                                <v-col cols="12" lg="6" md="6">
                                                    <ValidationProvider name="وقت التسميع" :rules="rules.from_time" v-slot="{ errors }">
                                                        <input-text field-type="number" :min-val="1" v-model="from_time" :field-errors="errors" :label-top="'التسميع من وقت'" :placeholder="'التسميع الى'" />
                                                    </ValidationProvider>
                                                </v-col>
                                                <v-col cols="12" lg="6" md="6">
                                                    <ValidationProvider name="وقت التسميع" :rules="rules.to_time" v-slot="{ errors }">
                                                        <input-text field-type="number" :min-val="1" v-model="to_time" :field-errors="errors" :label-top="'التسميع الى وقت'" :placeholder="'التسميع الى'" />
                                                    </ValidationProvider>
                                                </v-col>
                                                <v-col cols="12" lg="6" md="6">
                                                    <ValidationProvider name="كيفية التسميع" :rules="rules.type" v-slot="{ errors }">
                                                        <select-input item-text="text" item-value="value" v-model="type" :field-errors="errors" :label="'كيفية التسميع'" :name="'أختر كيفية التسميع'" :items="options" />
                                                    </ValidationProvider>
                                                </v-col>
                                                <v-col cols="12" lg="6" md="6">
                                                    <ValidationProvider name="موقع التسميع " :rules="rules.location" v-slot="{ errors }">
                                                        <input-text field-type="text" v-model="location" :field-errors="errors" :label-top="'موقع التسميع '" :placeholder="'التسميع الى'" />
                                                    </ValidationProvider>
                                                </v-col>
                                                <v-col cols="12">
                                                    <v-btn :disabled="invalid" type="submit" block color="#00B5AD" style="color: white;font-size: 15px;height: 45px">اضافة</v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-form>
                                    </validation-observer>
                                </div>
                            </dialog-modal>
                        </div>
                    </div>
                </div>
                <v-row>
                    <v-col v-for="reader in readers" :key="reader.id" cols="12" lg="4" md="6">
                        <card-reader :reader="reader" :show-btn="false" :show-edit-delete="true" />
                    </v-col>
                </v-row>
            </div>
            <pagination-components />
        </div>
    </v-main>
</section>
</template>

<script>
import PaginationComponents from "@/components/dashboard/paginationComponents";
import SearchInput from "@/components/search-input";
import ImportFile from "@/components/import-file";
import DialogModal from "@/components/dialogModal";
import SelectInput from "@/components/select-input";
import { mapGetters } from "vuex";
import CardReader from "@/components/cards/card-reader";
import InputText from "@/components/input-text";
import { fetchEmployees, fetchBranches } from 'Actions';
import {  getMethods,postMethods } from "Helper";
export default {
    name: "studentVacationsView",
    components: {
        InputText,
        CardReader,
    
        SelectInput,
        DialogModal,
        ImportFile,
        SearchInput,
        PaginationComponents
    },
    data() {
        return {
            items: [
                'ربط ', 'مراجعه', 'تكرار',
            ],
            styleSearch: {
                backgroundColor: 'transparent',
                border: "1px oslid #E2E2EA",
                borderRadius: "10px"
            },
            fetchEmployeesApiError: {
                status: false,
                error: {},
            },
            readers: [],
            employees: [],
            branches: [],
            from_time: 12.00,
            location: '',
            to_time: 12.00,
            days: [{
                    value: "Sunday",
                    text: "الاحد",
                },
                {
                    value: "Monday",
                    text: "الاثنين",
                },
                {
                    value: "Tuesday",
                    text: "الثلاثاء",
                },
                {
                    value: "Wednesday",
                    text: "الاربعاء",
                },
                {
                    value: "Thursday",
                    text: "الخميس",
                },
                {
                    value: "Friday",
                    text: "الجمعة",
                },
                {
                    value: "Saturday",
                    text: "السبت",
                }
            ],
            day: null,
            type: null,
            options: [{
                    value: 'online',
                    text: 'أونلاين',
                },
                {
                    value: 'offline',
                    text: 'اوفلاين',
                }
            ],
            reader_id: null,
            branch_id: null,
            loadingEmployeesStatus: true,
            rules: {
                reader_id: {
                    required: true,
                },
                branch_id: {
                    required: true,
                },
                day: {
                    required: true
                },
                type: {
                    required: true
                },
                from_time: {
                    required: true
                },
                to_time: {
                    required: true
                },
                location: {
                    required: false
                }
            },
           submitOnProgress: false,
               createItemApiError: {
               status: false,
                   error: { },
           },
        }
    },
    computed: {
        ...mapGetters('settings', ['allInterview'])
    },
    methods: {
         async createItem() {
            this.submitOnProgress = true;
            this.createItemApiError = {
                status: false,
                error: {},
            };

            let formData = new FormData();
            formData.append("reader_id", this.reader_id);
            formData.append("days", this.day);
            formData.append("branche_id", this.branch_id);
            formData.append("from_time", this.from_time);
            formData.append("to_time", this.to_time);
            formData.append("type", this.type);
            formData.append("location", this.location);

            try {
                let res = await postMethods('readers', formData);
                if (res.data.status) {
                    this.$toast.success("تمت العملية بنجاح");
                    this.getItems();
                } else {
                    this.$toast.error(res.data.message);
                    this.createItemApiError.status = true;
                    this.createItemApiError.error.message = res.data.message;
                }
            } catch (error) {
                this.$toast.error('فشلت العملية');
                this.createItemApiError.status = true;
                this.createItemApiError.error.message = error.response ? error.response.data : error;
            } finally {
                this.submitOnProgress = false;
            }
        },
        async getEmployees() {
            this.loadingEmployeesStatus = true;
            this.fetchEmployeesApiError = {
                status: false,
                error: {},
            };
            try {
                let res = await fetchEmployees();
                if (res.data.status) {
                    this.employees = res.data.data;
                } else {
                    this.fetchEmployeesApiError.status = true;
                    this.fetchEmployeesApiError.error.message = res.data.message;
                }
            } catch (error) {
                this.fetchEmployeesApiError.status = true;
                this.fetchEmployeesApiError.error.message = error.response ? error.response.data : error;
            } finally {
                this.loadingEmployeesStatus = false;
            }
        },
        async getBranches() {
            let res = await fetchBranches();
            if (res.data.status) {
                this.branches = res.data.data;
            }
        },
        async getItems() {
            try {
                let res = await getMethods('readers')
                this.readers = res.data.data;
            } catch (error) {
                console.log(error)
            }
        },
    },
    beforeMount() {
        this.$store.dispatch('settings/pageTitle', 'المقرئين')
    },
    created() {
        this.getItems();
        this.getEmployees();
        this.getBranches();
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/variable.scss";

.recitersView {
    background-color: $background-main-page;
    padding: 40px 15px;

    .container--fluid {
        border-radius: 15px;
        background-color: white;
        padding: 15px
    }

    .top-bar-search {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;

        @media only screen and (max-width: 1300px) {
            flex-direction: column;
        }

        .search-section {
            width: 35%;

            @media only screen and (max-width: 1300px) {
                width: 100%;
            }

            .search {
                width: 100%;
                display: flex;
                align-items: center;
                padding: 12px 0;

                .main-container-date {
                    display: flex;
                    align-items: center
                }

                button {
                    border: 1px solid #00B5AD;
                    padding: 10px 40px;
                    font-size: 14px;
                    border-radius: 10px;
                    color: #00B5AD;
                    margin-right: 10px;

                    @media only screen and (max-width: 1300px) {
                        width: 100%;
                    }
                }

            }
        }

    }

    .import-export-file {
        display: flex;
        align-items: center;
        margin-bottom: 8px;

        @media only screen and (max-width: 1300px) {
            width: 100%;

            div {
                width: 50%;

                label {
                    width: 100%;
                    display: inline-block;
                }
            }

            .export-file,
            .import-file {
                margin-top: 8px;
                text-align: center;
            }

            .text-center {
                width: 100%;
            }
        }

        @media only screen and (max-width: 1300px) {
            width: 100%;
            display: unset;

            div {
                width: 100%;
            }
        }

        div {
            margin: 0 5px;

            @media only screen and (max-width: 800px) {
                margin: 0 0px;
                padding: 0 5px;
            }
        }
    }

}
</style><style lang="scss">
.recitersView {

    .theme--light.v-input input,
    .theme--light.v-input textarea {
        font-size: 14px !important;
        font-weight: bold !important;
        color: #707070 !important;
        margin-top: 5px;
    }

    .v-input__icon i {
        font-size: 14px !important;
        font-weight: normal !important;
        color: #707070 !important;
    }

    .v-text-field__details {
        display: none;
    }

    .v-input__slot:focus .v-text-field__details {
        border: none !important;
        display: none;

    }

    .v-input__slot:active .v-text-field__details {
        border: none !important;
        display: none;

    }

    .theme--light.v-input {
        margin: 0 10px 0 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #D2D5E1;
        border-radius: 5px;
    }

    .v-calendar-daily__interval-text {
        color: transparent !important
    }

    .v-text-field {
        padding: 0;
    }

    .v-text-field__slot input {
        padding: 2px 0 !important;
    }

    .v-input__prepend-outer {
        margin-right: 3px;
    }
}

@media only screen and (max-width: 1000px) {
    .select-way-reciters {
        display: none;
    }
}
</style>
