import Vue from 'vue';
import Vuex from 'vuex';
import settings from './modules/settings';
// import general from './modules/general';
// import sidebar from './modules/sidebar';
import auth from './modules/auth';
// import createMutationsSharer from "vuex-shared-mutations";


Vue.use(Vuex);

export const store = new Vuex.Store({
    modules: {
        settings,
        // general,
        // sidebar,
        auth
    },
    // Enable strict mode in development to get a warning
    // when mutating state outside of a mutation.
    // https://vuex.vuejs.org/guide/strict.html
    strict: process.env.MIX_APP_ENV !== 'production',
    // plugins: [
    //     createMutationsSharer({ 
    //         predicate: [
    //             "settings/SET_SELECTED_LANGUAGE",
    //             "settings/SET_RTL_LAYOUT",
    //         ] 
    //     })
    // ]
});
