<template>
  <section class="question-interviews">
    <v-main>
      <v-row>
        <v-col cols="12" lg="12">
          <dialog-modal :width-size="800" :nameInput="'اضافة سؤال '" :title="'إضافة سؤال جديد'" ref="questionDialog">
            <step-bystep @add-btn-clicked="addQuestion()">
              <template v-slot:section-one>
                <div class="createQuestion-one">
                  <label class="custom-label-recitation-interview">أختر هنا نوع السؤال</label>
                  <select-input item-text="text" :label="''" v-model="question_type" item-value="value"
                    :items="questionType" :name="'أختر هنا نوع السؤال'"></select-input>
                </div>
              </template>
              <template v-slot:section-tow>
                <div class="createQuestion-one">
                  <div class="container-question-modal">
                    <div class="content-question" v-if="question_type == 'choice'">
                      <div class="question">
                        <v-row style="margin-bottom: 20px;">
                          <v-col cols="12" lg="12">
                            <input-text :label-top="'اكتب سؤال'" :placeholder="''" v-model="question_text" />
                          </v-col>
                          <v-col cols="12" lg="2">
                            <input-text field-type="number" :label-top="'درجة السؤال'" :placeholder="''"
                              v-model="grade" />
                          </v-col>
                          <v-col cols="12" lg="4">
                            <input-text :label-top="'اسم السؤال '" :placeholder="'السؤال الاول'"
                              v-model="question_name" />
                          </v-col>
                          <v-col cols="12" lg="6">
                            <select-input item-text="name" :label="''" v-model="template_id" item-value="id"
                              :items="templatesValues" :name="'أختر هنا النموذج السؤال'"></select-input>
                          </v-col>
                        </v-row>
                      </div>
                      <div class="all-answer">
                        <div class="content-answer">
                          <div class="image-option">
                            <div class="image">
                              <img :src="imageIcon.squareIcon2">
                            </div>
                            <div class="number-option">
                              <span>الاختيار الاول</span>
                            </div>
                          </div>
                          <div class="answer">
                            <div class="container-radio">
                              <input-text :label-top="'اكتب الاختيار'" :placeholder="''" v-model="choice1"
                                style="width: 80%;margin-bottom: 20px" />
                              <input type="radio" id="one" value="1" v-model="correct_answer" />
                              <label style="font-size:14px" for="one">الاختيار الصحيح</label>
                            </div>

                          </div>
                        </div>
                        <div class="content-answer">
                          <div class="image-option">
                            <div class="image">
                              <img :src="imageIcon.squareIcon2">
                            </div>
                            <div class="number-option">
                              <span>الاختيار الثانى</span>
                            </div>
                          </div>
                          <div class="answer">

                            <div class="container-radio">
                              <input-text :label-top="'اكتب الاختيار'" :placeholder="''" v-model="choice2"
                                style="width: 80%;margin-bottom: 20px" />
                              <input type="radio" id="tow" value="2" v-model="correct_answer" />
                              <label style="font-size:14px" for="tow">الاختيار الصحيح</label>

                            </div>

                          </div>
                        </div>
                        <div class="content-answer">
                          <div class="image-option">
                            <div class="image">
                              <img :src="imageIcon.squareIcon2">
                            </div>
                            <div class="number-option">
                              <span>الاختيار الثالث</span>
                            </div>
                          </div>
                          <div class="answer">

                            <div class="container-radio">
                              <input-text :label-top="'اكتب الاختيار'" :placeholder="''" v-model="choice3"
                                style="width: 80%;margin-bottom: 20px" />
                              <input type="radio" id="three" value="3" v-model="correct_answer" />
                              <label style="font-size:14px" for="three">الاختيار الصحيح</label>

                            </div>

                          </div>
                        </div>
                        <div class="content-answer">
                          <div class="image-option">
                            <div class="image">
                              <img :src="imageIcon.squareIcon2">
                            </div>
                            <div class="number-option">
                              <span> &nbsp;الاختيار الرابع</span>
                            </div>
                          </div>
                          <div class="answer">

                            <div class="container-radio">
                              <input-text :label-top="'اكتب الاختيار'" :placeholder="''" v-model="choice4"
                                style="width: 80%;margin-bottom: 20px" />
                              <input type="radio" id="four" value="4" v-model="correct_answer" />
                              <label style="font-size:14px" for="four">الاختيار الصحيح</label>

                            </div>

                          </div>
                        </div>

                      </div>
                    </div>
                    <div class="content-question" v-if="question_type == 'text'">
                      <div class="question">
                        <v-row style="margin-bottom: 20px;">
                          <v-col cols="12" lg="12">
                            <input-text :label-top="'اكتب سؤال'" :placeholder="''" v-model="question_text" />
                          </v-col>
                          <v-col cols="12" lg="2">
                            <input-text field-type="number" :label-top="'درجة السؤال'" :placeholder="''"
                              v-model="grade" />
                          </v-col>
                        
                          <v-col cols="12" lg="6">
                            <select-input item-text="name" :label="''" v-model="template_id" item-value="id"
                              :items="templatesValues" :name="'أختر هنا النموذج السؤال'"></select-input>
                          </v-col>
                        </v-row>
                      </div>
                      <!-- <h5>أكمل قوله تعالى: (يا أيها الناس اتقوا ربكم واخشوا يوماً لا يجزي والد عن ولده)</h5> -->
                      <div class="all-answer">
                        <input-text :label-top="'اسم السؤال '" :placeholder="'السؤال الاول'" v-model="question_name"
                          style="width: 100%; margin-bottom: 20px" />

                        <v-textarea name="input-7-4" v-model="text_result"></v-textarea>
                        <span class="rows-number">2000 سطر</span>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </step-bystep>
          </dialog-modal>
        </v-col>
     
      </v-row>
      <confirmation-dialog ref="deleteConfirmationDialog" heading="تأكيد عملية حذف السؤال"
        message='لن تتمكن من استرجاعه مجدداً' @onConfirm="deleteQuestion"></confirmation-dialog>

      <dialog-modal :width-size="800" :nameInput="'تعديل السؤال'" :title="'تعديل السؤال'" ref="editConfirmationDialog"
        style="display: none;">
           <step-bystep @add-btn-clicked="updateQuestion()">
                <template v-slot:section-one>
                  <div class="createQuestion-one">
                    <label class="custom-label-recitation-interview">أختر هنا نوع السؤال</label>
                    <v-select item-text="text" :label="''" v-model="edittype" item-value="value"
                      :items="questionType" :name="'أختر هنا نوع السؤال'"
                      outlined
                              dense
                      ></v-select>
                  </div>
                </template>
                <template v-slot:section-tow>
                  <div class="createQuestion-one">
                    <div class="container-question-modal">
                      <div class="content-question" v-if="edittype == 'choice'">
                        <div class="question">
                          <v-row style="margin-bottom: 20px;">
                            <v-col cols="12" lg="12">
                              <input-text :label-top="'اكتب سؤال'" :placeholder="''" v-model="editquestion_text" />
                            </v-col>
                            <v-col cols="12" lg="2">
                              <input-text field-type="number" :label-top="'درجة السؤال'" :placeholder="''"
                                v-model="editgrade" />
                            </v-col>
                            <v-col cols="12" lg="4">
                              <input-text :label-top="'اسم السؤال '" :placeholder="'السؤال الاول'"
                                v-model="editname" />
                            </v-col>
                            <v-col cols="12" lg="6">
                              <v-select item-text="name" :label="''" v-model="edittemplate_id" item-value="id"
                                :items="templatesValues" 
                                  outlined
                                dense
                                :name="'أختر هنا النموذج السؤال'"></v-select>
                            </v-col>
                          </v-row>
                        </div>
                        <div class="all-answer">
                          <div class="content-answer">
                            <div class="image-option">
                              <div class="image">
                                <img :src="imageIcon.squareIcon2">
                              </div>
                              <div class="number-option">
                                <span>الاختيار الاول</span>
                              </div>
                            </div>
                            <div class="answer">
                              <div class="container-radio">
                                <input-text :label-top="'اكتب الاختيار'" :placeholder="''" v-model="editchoice1"
                                  style="width: 80%;margin-bottom: 20px" />
                                <input type="radio" id="one" value="1" v-model="editchoice_right_answer" />
                                <label style="font-size:14px" for="one">الاختيار الصحيح</label>
                              </div>

                            </div>
                          </div>
                          <div class="content-answer">
                            <div class="image-option">
                              <div class="image">
                                <img :src="imageIcon.squareIcon2">
                              </div>
                              <div class="number-option">
                                <span>الاختيار الثانى</span>
                              </div>
                            </div>
                            <div class="answer">

                              <div class="container-radio">
                                <input-text :label-top="'اكتب الاختيار'" :placeholder="''" v-model="editchoice2"
                                  style="width: 80%;margin-bottom: 20px" />
                                <input type="radio" id="tow" value="2" v-model="editchoice_right_answer" />
                                <label style="font-size:14px" for="tow">الاختيار الصحيح</label>

                              </div>

                            </div>
                          </div>
                          <div class="content-answer">
                            <div class="image-option">
                              <div class="image">
                                <img :src="imageIcon.squareIcon2">
                              </div>
                              <div class="number-option">
                                <span>الاختيار الثالث</span>
                              </div>
                            </div>
                            <div class="answer">

                              <div class="container-radio">
                                <input-text :label-top="'اكتب الاختيار'" :placeholder="''" v-model="editchoice3"
                                  style="width: 80%;margin-bottom: 20px" />
                                <input type="radio" id="three" value="3" v-model="editchoice_right_answer" />
                                <label style="font-size:14px" for="three">الاختيار الصحيح</label>

                              </div>

                            </div>
                          </div>
                          <div class="content-answer">
                            <div class="image-option">
                              <div class="image">
                                <img :src="imageIcon.squareIcon2">
                              </div>
                              <div class="number-option">
                                <span> &nbsp;الاختيار الرابع</span>
                              </div>
                            </div>
                            <div class="answer">

                              <div class="container-radio">
                                <input-text :label-top="'اكتب الاختيار'" :placeholder="''" v-model="editchoice4"
                                  style="width: 80%;margin-bottom: 20px" />
                                <input type="radio" id="four" value="4" v-model="editchoice_right_answer" />
                                <label style="font-size:14px" for="four">الاختيار الصحيح</label>

                              </div>

                            </div>
                          </div>

                        </div>
                      </div>
                      <div class="content-question" v-if="edittype == 'text'">
                        <div class="question">
                          <v-row style="margin-bottom: 20px;">
                            <v-col cols="12" lg="12">
                              <input-text :label-top="'اكتب سؤال'" :placeholder="''" v-model="editquestion_text" />
                            </v-col>
                            <v-col cols="12" lg="2">
                              <input-text field-type="number" :label-top="'درجة السؤال'" :placeholder="''"
                                v-model="editgrade" />
                            </v-col>
                        
                            <v-col cols="12" lg="6">
                              <select-input item-text="name" :label="''" v-model="edittemplate_id" item-value="id"
                                :items="templatesValues" :name="'أختر هنا النموذج السؤال'"></select-input>
                            </v-col>
                          </v-row>
                        </div>
                        <!-- <h5>أكمل قوله تعالى: (يا أيها الناس اتقوا ربكم واخشوا يوماً لا يجزي والد عن ولده)</h5> -->
                        <div class="all-answer">
                          <input-text :label-top="'اسم السؤال '" :placeholder="'السؤال الاول'" v-model="editname"
                            style="width: 100%; margin-bottom: 20px" />

                          <v-textarea name="input-7-4" v-model="edittext_right_answer"></v-textarea>
                          <span class="rows-number">2000 سطر</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </step-bystep>
     
      </dialog-modal>
      <v-row>
        <v-col cols="12" lg="6" v-for="question in all_questions" :key="question.id">
          <div class="container--fluid">
            <div class="container-question">
              <div class="number-question">
                <div class="right-section">
                  <div class="icon">
                    <img :src="imageIcon.squareIcon" alt="image icon">
                  </div>
                  <h4> {{ question.question }}&nbsp; <span v-if="question.type != null"> ({{
                    question.type == "choice" ? 'أختياري' : 'مقالي' }})</span> </h4>
                </div>
                <div class="left-section">
                  
                  <div class="degree">
                    <span class="edit-icon"
                      @click="editquestion(question)">
                      <i class="fa fa-pencil" aria-hidden="true"></i> </span>
                  </div>
                  <div class="degree">
                    <span class="close-icon" @click="openDeleteItem(question.id)"><i class="fa fa-times"></i></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-main>
  </section>
</template>

<script>

import DialogModal from "@/components/dialogModal";
import InputText from "@/components/input-text";
import StepBystep from "@/components/stepBystep";
import SelectInput from "@/components/select-input";

import { deleteMethods, getMethods, postMethods, putMethods } from "@/api/Helper";
import { listInterviewTemplates } from '@/api/Actions';
export default {
  name: "templatesView",
  components: { InputText, DialogModal, StepBystep, SelectInput },
  data() {
    return {
      imageIcon: {
        squareIcon: require('@/assets/image/icon/Group 78247.png'),
        squareIcon2: require('@/assets/image/icon/Group 78248.png'),
        blockIcon: require('@/assets/image/icon/Icon ionic-md-remove-circle-outline.png'),
      },
      radioGroup: 1,
      templatesValues: [],
      all_questions: [],
      paths: [],
      question_type: null,
      homework_date: null,
      template_id: null,
      choice1: null,
      choice2: null,
      choice3: null,
      choice4: null,
      template_type: null,
      grade: 0,
      correct_answer: null,
      questions: [],
      question_text: "",
      text_result: "",
      question_name: "",
      edit_question: "",
      template_name: null,
      itemname2edit: null,
      itemtype2edit: null,
      itemdate2edit: null,
      itempath2edit: null,
      item2edit: null,
      displayShow: 'block',
      item2delete: null,
      editchoice1: null,
      editchoice2: null,
      editchoice3: null,
      editchoice4: null,
      editchoice_right_answer: null,
      editquestion_text: null,
      editgrade: null,
      editname: null,
      edittemplate_id: null,
      edittext_right_answer: null,
      edittype: null,
      questionType: [
        {
          'text': 'اختياري',
          'value': 'choice'
        },
        {
          'text': 'مقالى',
          'value': 'text'
        }
      ],
      templateTypes: [
        {
          'text': 'واجب',
          'value': 'homework'
        },
        {
          'text': 'مقابلة',
          'value': 'interview'
        }
      ],
      todos: [

      ],
      newTodo: "",
      newNumberOption: "",
      indexEditTodo: null,
      closedialog: false,
      rules: {
        itemtype2edit: {
          required: true,
        },
        template_name: {
          required: true,
        },
        template_type: {
          required: true,
        },
        question_type: {
          required: true,
        },
        homework_date: {
          required: true,
        },
      },

    }
    
  },
  methods: {
    editquestion(question) {
      this.editchoice1 = question.choice1;
      this.editchoice2 = question.choice2;
      this.editchoice3 = question.choice3;
      this.editchoice4 = question.choice4;
      this.editchoice_right_answer = question.choice_right_answer;
      this.editgrade = question.grade;
      this.editname = question.name;
      this.editquestion_text = question.question;
      this.edittemplate_id = question.template_id;
      this.edittext_right_answer = question.text_right_answer;
      this.edittype = question.type;      
      this.item2edit = question.id;
      this.$refs.editConfirmationDialog.openDialog();
    },
    openDeleteItem(item) {
      this.item2delete = item;
      this.$refs.deleteConfirmationDialog.openDialog();
    },
    async deleteQuestion() {
      try {
        this.$refs.deleteConfirmationDialog.close();
        let res = await deleteMethods(`questions/${this.item2delete}`, { 'id': this.item2delete });
        if (res.data.status == 1) {
          this.item2delete = null
          this.$toast.success("تمت العملية بنجاح");
          this.getTemplate();
        } else {
          this.$toast.error(res.data.message);
        }
      } catch (error) {
        this.$toast.error('فشلت العملية');
      }
    },
   
    async updateQuestion() {
       try {
        let formData = new FormData();
        formData.append("type", this.edittype);
        formData.append("template_id", this.edittemplate_id);
        formData.append("grade", this.editgrade);
        formData.append("name", this.editname);
        formData.append("question", this.editquestion_text);
        if (this.edittype == 'text') {
          formData.append("text_right_answer", this.edittext_right_answer);
        } else {
          formData.append("choice1", this.editchoice1);
          formData.append("choice2", this.editchoice2);
          formData.append("choice3", this.editchoice3);
          formData.append("choice4", this.editchoice4);
          formData.append("choice_right_answer", this.editchoice_right_answer);
        }
         this.$refs.editConfirmationDialog.close();
        let res = await putMethods(`questions/${this.item2edit}`, formData);
        if (res.data.status == 1 && res.data.code == 200) {
          this.getQuestions();
          this.$toast.success('تم الحفظ بنجاح');
        } else {
          this.$toast.error(res.data.message);
        }
      } catch (error) {
        if (error.response.status == 400) {
          this.$toast.error(error.response.data.message)
        } else {
          this.$toast.error('فشلت العملية');
        }
      }

    },
    async getTemplate() {
      try {
        let formData = new FormData();
        formData.append("name", this.template_name);

        let res = await listInterviewTemplates('');

        if (res.data.status) {
          for (let index = 0; index < res.data.data.length; index++) {
            this.templatesValues.push({ "id": res.data.data[index].id, "name": res.data.data[index].name });
          }

        } else {
          this.$toast.error(res.data.message);
        }
      } catch (error) {
        this.$toast.error('فشلت العملية');
      }
    },
    async getQuestions() {
      try {
        let formData = new FormData();
        formData.append("name", this.template_name);

        let res = await getMethods('questions');

        if (res.data.status) {
          this.all_questions = res.data.data
          console.log(res.data);

        } else {
          this.$toast.error(res.data.message);
        }
      } catch (error) {
        this.$toast.error('فشلت العملية');
      }
    },

    async addQuestion() {
      try {
        let formData = new FormData();
        formData.append("type", this.question_type);
        formData.append("template_id", this.template_id);
        formData.append("grade", this.grade);
        formData.append("name", this.question_name);
        formData.append("question", this.question_text);
        if (this.question_type == 'text') {
          formData.append("text_right_answer", this.text_result);
        } else {
          formData.append("choice1", this.choice1);
          formData.append("choice2", this.choice2);
          formData.append("choice3", this.choice3);
          formData.append("choice4", this.choice4);
          formData.append("choice_right_answer", this.correct_answer);
        }
        this.$refs.questionDialog.close();
        let res = await postMethods('questions', formData);
        console.log(res.data);
        if (res.data.status == 1 && res.data.code == 200) {
          this.$toast.success('تم الحفظ بنجاح');
        } else {
          this.$toast.error(res.data.message);
        }
      } catch (error) {
        if (error.response.status == 400) {
          this.$toast.error(error.response.data.message)
        } else {
          this.$toast.error('فشلت العملية');
        }
      }

    },
  
  },
  beforeMount() {
    this.$store.dispatch('settings/pageTitle', 'كل الاسئلة');
  },
  created() {
    this.getTemplate();
    this.getQuestions();
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/css/variable.scss";

.button-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00B5AD;
  padding: 8px 15px;
  margin-top: 5px;
  border-radius: 5px;

  i {
    font-size: 15px;
    margin-top: 10px;
    margin-left: 5px;
  }
}

.question-interviews {
  background-color: $background-main-page;
  padding: 20px 15px 40px 15px;

  @media only screen and (max-width: 700px) {
    padding: 20px 8px 40px 8px;
  }

  .container--fluid {
    border-radius: 15px;
  }

  .container-question {
    display: v-bind('displayShow');
    background-color: white;
    border-radius: 20px;
    padding-bottom: 30px;
    overflow: hidden;

    .number-question {
      display: flex;
      background-color: rgba(56, 91, 114, 0.16);
      padding: 25px 30px;
      justify-content: space-between;
      align-items: center;

      .right-section {
        display: flex;
        align-items: center;

        .icon {
          width: 28px;
          margin-left: 15px;
          display: flex;
          align-items: center;

          img {
            width: 100%;
          }
        }

        h3 {
          font-size: 22px !important;
          font-weight: bold !important;
          color: #354052;

          @media only screen and (max-width: 700px) {
            font-size: 16px !important;
          }
        }
      }

      .left-section {
        display: flex;

        .degree {
          display: flex;
          align-items: center;

          h3 {
            font-size: 22px !important;
            color: #272727;
            margin-left: 10px;

            @media only screen and (max-width: 700px) {
              font-size: 16px !important;
            }
          }

          input.number {
            width: 40px;
            background-color: #FAFBFC;
            padding: 4px 8px;
            border: 1px solid rgba(6, 83, 254, 0.2);
            border-radius: 10px;
            color: #385B72;
            margin-left: 50px;
            margin-right: 5px;
            font-size: 18px;
            display: inline-block;

          }

          span.close-icon {
            margin-left: 10px;
            background-color: rgba(231, 29, 54, 0.25);
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            font-weight: 300 !important;
            font-size: 15px;
            cursor: pointer;
            color: rgba(231, 29, 54, 0.76);

            @media only screen and (max-width: 700px) {
              width: 30px;
              height: 30px;
            }
          }

          a.show-icon {
            margin-left: 10px;
            background-color: white;
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            cursor: pointer;
            font-weight: 300 !important;
            font-size: 15px;

            @media only screen and (max-width: 700px) {
              width: 30px;
              height: 30px;
            }
          }

          span.edit-icon {
            margin-left: 10px;
            background-color: orange;
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            cursor: pointer;
            font-weight: 300 !important;
            font-size: 15px;
            color: white;

            @media only screen and (max-width: 700px) {
              width: 30px;
              height: 30px;
            }
          }
        }
      }
    }

    .content-question {
      padding: 30px 50px;

      @media only screen and (max-width: 700px) {
        padding: 30px 15px !important;
      }

      .question {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .type-question {
          @media only screen and (max-width: 700px) {
            display: none;
          }
        }

        h5 {
          color: #272727;
          font-size: 18px;
          margin-bottom: 20px;

          @media only screen and (max-width: 700px) {
            font-size: 16px;
          }
        }

        span {
          font-size: 15px;
          color: #385B72;
        }
      }
    }

    .all-answer {
      width: 100%;
      position: relative;

      .content-answer {
        display: flex;
        align-items: center;

        .image-option {
          display: flex;
          align-items: center;
          margin-bottom: 19px;
          margin-left: 20px;

          @media only screen and (max-width: 700px) {
            display: none !important;
          }

          .image {
            width: 22px;
            margin-top: 5px;

            img {
              width: 100%;
            }
          }

          .number-option {
            margin-right: 10px;
            margin-bottom: 7px;

            span {
              font-size: 15px !important;
            }
          }

        }

        .answer {
          width: 83%;

          .container-radio {
            border: 1px solid rgba(6, 83, 254, 0.21);
            border-radius: 5px;
            padding: 25px 15px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            @media only screen and (max-width: 700px) {
              .v-label {
                display: none !important;
              }
            }

            p {
              margin-bottom: 0;
              font-size: 16px;
              text-align: right;
              position: absolute;
              right: 2%;

              @media only screen and (max-width: 700px) {
                font-size: 14px;
              }

            }
          }
        }

        .delete-option {
          border: 1px solid rgba(6, 83, 254, 0.21);
          border-radius: 5px;
          margin-right: 5px;
          display: flex;
          align-items: center;
          padding: 14px 5px;
          cursor: pointer;
          margin-bottom: 19px !important;

          img {
            width: 22px;
          }
        }
      }

      .rows-number {
        display: inline-block;
        position: absolute;
        left: 5%;
        bottom: 25%;
        font-size: 12px;
        color: #272727;

      }

      .add-new-option {
        position: absolute;
        bottom: -2%;
        left: 1%;
        font-size: 14px;
        text-decoration: underline
      }
    }
  }

  .v-input--radio-group--column .v-radio {
    margin-bottom: 0;
  }

}
</style>
<style lang="scss">
.question-interviews {
  .v-size--default {
    padding-top: 22px !important;
    padding-bottom: 24px !important;
  }

  .v-input--selection-controls .v-input__slot>.v-label,
  .v-input--selection-controls .v-radio>.v-label {
    color: #00B5AD !important;
    margin-bottom: 7px;

    @media only screen and (max-width: 700px) {
      font-size: 13px;
    }
  }

  .v-application .primary--text {
    color: #00B5AD !important;
  }

  .v-icon.v-icon {
    font-size: 20px !important;
  }

  .v-input--radio-group--column .v-radio {
    margin-bottom: 0;
    width: 100% !important;
    position: absolute !important;
    z-index: 1;
  }

  .v-input--selection-controls.v-input {
    margin-top: 0px !important;
  }

  .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)>.v-input__control>.v-input__slot {
    box-shadow: none !important;
    border: 1px solid rgba(6, 83, 254, 0.15);

  }

  .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)>.v-input__control {
    padding-top: 15px;
  }

  .v-textarea.v-text-field--enclosed .v-text-field__slot textarea {
    font-size: 16px !important;
  }

  .input-7-4 {
    border: 1px solid #00B5AD
  }

  .modal-custom .button-modal {
    width: 100%;
  }


}


label.custom-label-recitation-interview {
  display: block;
  margin-bottom: 10px;
  font-size: 14px;
  background-color: white;
  position: absolute;
  top: 14%;
  z-index: 50;
  right: 4%;
}

label.custom-label-recitation-interview {
  font-size: 14px !important;
}

.createQuestion-one {
  padding: 20px 0;
}

.container-question-modal {
  background-color: white;
  border-radius: 20px;
  overflow: hidden;

  .content-question {
    padding: 10px 10px;

    .question {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .type-question {
        @media only screen and (max-width: 700px) {
          display: none;
        }
      }

      h5 {
        color: #272727;
        font-size: 18px;
        margin-bottom: 20px;
      }

      span {
        font-size: 15px;
        color: #385B72;
      }
    }
  }

  .all-answer {
    width: 100%;
    position: relative;

    .content-answer {
      display: flex;
      align-items: center;

      .image-option {
        display: flex;
        align-items: center;
        margin-bottom: 19px;
        margin-left: 10px;

        @media only screen and (max-width: 700px) {
          display: none !important;
        }

        .image {
          width: 22px;
          margin-top: 5px;

          img {
            width: 100%;
          }
        }

        .number-option {
          margin-right: 10px;
          margin-bottom: 7px;

          span {
            font-size: 15px !important;
          }
        }

      }

      .answer {
        width: 77%;

        @media only screen and (max-width: 700px) {
          width: 100%;
        }

        .container-radio {
          border: 1px solid rgba(6, 83, 254, 0.21);
          border-radius: 5px;
          padding: 25px 15px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          p {
            margin-bottom: 0;
            font-size: 16px;
            text-align: right;
            position: absolute;
            right: 2%;
          }
        }
      }

      .delete-option {
        border: 1px solid rgba(6, 83, 254, 0.21);
        border-radius: 5px;
        margin-right: 5px;
        display: flex;
        align-items: center;
        padding: 14px 5px;
        cursor: pointer;
        margin-bottom: 19px !important;

        img {
          width: 22px;
        }
      }
    }

    .rows-number {
      display: inline-block;
      position: absolute;
      left: 5%;
      bottom: 25%;
      font-size: 12px;
      color: #272727;

    }

    .add-new-option {
      position: absolute;
      bottom: -2%;
      left: 1%;
      font-size: 14px;
      text-decoration: underline
    }
  }

}

.v-dialog {
  .v-input--selection-controls {
    margin-top: 0 !important;
  }

  .container-question-modal .all-answer .content-answer .answer .container-radio {
    padding: 10px !important;
  }

  .v-size--default {
    padding-top: 22px !important;
    padding-bottom: 24px !important;
  }
}

.v-btn__content {
  font-size: 18px;
}</style>
