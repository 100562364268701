import { render, staticRenderFns } from "./card-edit-fatra.vue?vue&type=template&id=4b7c8ccf&scoped=true&"
import script from "./card-edit-fatra.vue?vue&type=script&lang=js&"
export * from "./card-edit-fatra.vue?vue&type=script&lang=js&"
import style0 from "./card-edit-fatra.vue?vue&type=style&index=0&id=4b7c8ccf&prod&lang=scss&scoped=true&"
import style1 from "./card-edit-fatra.vue?vue&type=style&index=1&id=4b7c8ccf&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b7c8ccf",
  null
  
)

export default component.exports