<template>
  <section class="recitation-interview">
    <v-main>
      <div class="container--fluid">
        <!-- section video call-->
        
        <div class="section-video-call" v-if="items.type=='online'">
          <div class="image-video" :style="{ backgroundImage: 'url(' + imageVideoCall + ')' }">
            <div class="image2-video">
              <img :src="imageVideoCall2" alt="image">
            </div>
            <div class="btn-video">
              <div class="setting-video">
                <span> <img :src="iconBtnVideo.videoSetting" alt="image"></span>
                <span> <img :src="iconBtnVideo.chat" alt="image"></span>
                <span class="btn-close"> <img :src="iconBtnVideo.usersAdd" alt="image"></span>
              </div>
              <div class="close-video">
                <span> <img :src="iconBtnVideo.replaceScreen" alt="image"></span>
                <span> <img :src="iconBtnVideo.video" alt="image"></span>
                <span class="btn-close"> <img :src="iconBtnVideo.closeVideo" alt="image"></span>
                <span> <img :src="iconBtnVideo.mute" alt="image"></span>
                <span> <img :src="iconBtnVideo.fullScreen" alt="image" style="margin-top: 1px"></span>

              </div>
            </div>
          </div>
        </div>
        <div class="details-video">
          <v-row>
            <v-col cols="12" lg="5" md="5" >
              <div class="information-student">
                <div class="name-image">
                  <div class="image">
                    <img :src="imageStudent" alt="image student">
                  </div>
                  <div class="name">
                    <p>{{ items.student_name  }}</p>
                    <span>طالب في فصل مالك ابن انس</span>
                  </div>
                </div>
                <div class="details-call-student">
                  <v-row>
                    <v-col cols="6" lg="4" md="6"  style="padding-left: 5px;padding-right: 5px">
                      <div class="path">
                        <div class="image">
                          <img :src="require('@/assets/image/icon/Color.png')" alt="image">
                        </div>
                        <div class="text-path">
                          <p>المسار</p>
                          <span>{{ items.path }}</span>
                        </div>
                      </div>
                    </v-col>
                    <!-- <v-col cols="6" lg="4" md="6"  style="padding-left: 5px;padding-right: 5px">
                      <div class="path">
                        <div class="image">
                          <img :src="require('@/assets/image/icon/Group 78359.png')" alt="image">
                        </div>
                        <div class="text-path">
                          <p> الفصل</p>
                          <span></span>
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="6" lg="4" md="6"  style="padding-left: 5px;padding-right: 5px">
                      <div class="path">
                        <div class="image">
                          <img :src="require('@/assets/image/icon/Group 77857.png')" alt="image">
                        </div>
                        <div class="text-path">
                          <p>مقدار الفترة</p>
                          <span>{{ items.duration }}</span>
                        </div>
                      </div>
                    </v-col> -->
                    <v-col cols="6" lg="4" md="6"  style="padding-left: 5px;padding-right: 5px">
                      <div class="path">
                        <div class="image">
                          <img :src="require('@/assets/image/icon/Group 78361.png')" alt="image">
                        </div>
                        <div class="text-path">
                          <p>نوعه</p>
                          <span>{{ items.type_text }}</span>
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="6" lg="4" md="6"  style="padding-left: 5px;padding-right: 5px">
                      <div class="path">
                        <div class="image">
                          <img :src="require('@/assets/image/icon/Group 77770.png')" alt="image">
                        </div>
                        <div class="text-path">
                          <p>تاريخ الاختبار</p>
                          <span>{{ items.appointment_date }}</span>
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="6" lg="4" md="6"  style="padding-left: 5px;padding-right: 5px">
                      <div class="path">
                        <div class="image">
                          <img :src="require('@/assets/image/icon/New folder/Color.png')" alt="image">
                        </div>
                        <div class="text-path">
                          <p>وقت الاختبار</p>
                          <span>{{ items.time }}</span>
                        </div>
                      </div>
                    </v-col>
                    
                   
                  </v-row>
                </div>
              </div>
            </v-col>
            <v-col cols="12" lg="7" md="7">
              <div class="form-recitation">
                <validation-observer  v-slot="{ handleSubmit }" >

                <v-form @submit.prevent="handleSubmit(createAdminssion)" >
                  <v-row>
                    <v-col cols="12" class="mt-2">
                      <ValidationProvider name="تفعيل الادخال قبل بداية البرنامج" v-slot="{ errors }">
                        <label for="">تفعيل الادخال قبل بداية البرنامج</label>
                          <select-input item-text="text" item-value="value" v-model="activeOption" :field-errors="errors" :label="''" :placeholder="'تفعيل الادخال قبل بداية البرنامج'" :name="'نعم'" :items="booleanOptions" />
                      </ValidationProvider>
                    </v-col>
                    <v-col cols="6" class="mt-4"> 
                      <input-text :label-top="'مقدار الاتقان بالاوجه'" v-model="amount_perfiction" :placeholder="'مقدار الاتقان بالاوجه'"/>
                    </v-col>
                    <v-col cols="12" class="mt-2" lg="6">
                       <input-text :label-top="'تحديد بداية الطالب'" :placeholder="'رقم الصفحة'" v-model="page_number"/>
                      </v-col>
                    <v-col cols="12" class="mt-2" lg="6">
                       <input-text :label-top="'توصية المشرف'" :placeholder="'توصية المشرف '" v-model="supervisor_message"/>
                      </v-col>
                      <v-col cols="6">
                        <select-input :label="''" v-model="result" :name="'أختر من هنا نتيجة المقابلة'"   item-text="text" item-value="value" :items="resultOptions"/>
                      </v-col>
                      <v-col cols="6" v-if="result==0">
                        <select-input :label="'تحديد موعد اخر'" v-model="other_meeting" :name="'أختر من هنا تحديد موعد اخر'" item-text="text" item-value="value" :items="booleanOptions"/>
                      </v-col>
                       <v-col cols="12" lg="6" md="6" v-if="result==0 && other_meeting ==1 ">
                          <ValidationProvider name="تاريخ اخر" v-slot="{ errors }">
                            <input-text  field-type="datetime-local" v-model="appointment_date" :fieldErrors="errors" :label-top="'تاريخ اخر'" :placeholder="'تاريخ اخر'"/>
                          </ValidationProvider>                            
                        </v-col>                      
                    <v-btn block  type="submit" class="mt-3 pb-6 pt-5"  style="color: white" color="#00B5AD">حفظ</v-btn>
                  </v-row>
                  </v-form>
                  </validation-observer>

              </div>
            </v-col>

          </v-row>
          <v-row>
             <section class="question-interviews"  cols="12" lg="12" md="12">
        <div class="container--fluid">
          <div class="todo shadow-lg  rounded-3" v-for="question in questions" :key="question.id">
            <div class="container-question" v-if="question.type == 'text'">
              <div class="number-question">
                <div class="right-section">
                  <div class="icon">
                    <img :src="imageIcon.squareIcon" alt="image icon">
                  </div>
                  <h3>{{ question.name }}</h3>
                </div>
                <div class="left-section">
                  <div class="degree">
                    <h3>درجة السؤال</h3>
                    <input type="text" placeholder="50" :value="question.grade" class="number ml-4" disabled>
                  </div>
                </div>
              </div>
             
            </div>
            <!-- choice -->
            <div class="container-question" v-if="question.type == 'choice'">
              <div class="number-question">
                <div class="right-section">
                  <div class="icon">
                    <img :src="imageIcon.squareIcon" alt="image icon">
                  </div>
                  <h3> {{ question.name }} </h3>
                </div>
                <div class="left-section">
                  <div class="degree">
                    <h3>درجة السؤال</h3>
                    <input type="text" placeholder="50" :value="question.grade" class="number ml-4" disabled>
                  </div>
                </div>
              </div>
           
            </div>
            <!-- choice -->
          </div>
          </div>
          </section>
          </v-row>
          </div>
        </div>
    </v-main>
  </section>
</template>

<script>


import { mapGetters } from "vuex";
import SelectInput from "@/components/select-input";

import InputText from "@/components/input-text";
import { fetchInterView, updateInterviwe } from 'Actions';
import { getMethods } from "@/api/Helper";

export default {
  name: "interviewsView",
  components: {InputText, SelectInput },
  props: ['id'],
  data() {
    return {
      imageStudent: require('@/assets/image/avatar/ebc8fbda-c735-4d9e-a8be-c5329afba775.png'),
      imageVideoCall: require('@/assets/image/istockphoto-1051420210-170667a.jpg'),
      imageVideoCall2: require('@/assets/image/Rectangle 3240.png'),
      iconBtnVideo: {
        videoSetting: require('@/assets/image/icon/ic-settings-48px 1.png'),
        chat: require('@/assets/image/icon/people_alt.png'),
        usersAdd: require('@/assets/image/icon/chat.png'),
        closeVideo: require('@/assets/image/icon/Vector3.png'),
        replaceScreen: require('@/assets/image/icon/Vector1.png'),
        mute: require('@/assets/image/icon/Vector4.png'),
        video: require('@/assets/image/icon/Vector2.png'),
        fullScreen: require('@/assets/image/icon/Vector.png'),
      },
      imageIcon: {
        squareIcon: require('@/assets/image/icon/Group 78247.png'),
        squareIcon2: require('@/assets/image/icon/Group 78248.png'),
        blockIcon: require('@/assets/image/icon/Icon ionic-md-remove-circle-outline.png'),
      },
      supervisor_message:null,
      items: [],
      result: null,
      amount_perfiction:null,
      page_number:null,
      appointment_date: null,
      questions: [],

      resultOptions: [
        {
          value: 1,
          text:'مقبول'
        },
        {
          value: 0,
          text:'مرفوض'
        }
      ],
      other_meeting:null,
      activeOption: null,
      booleanOptions: [
        {
          value: 0,
          text:'لا'
        },
        {
          value: 1,
          text:'نعم'
        }
      ]
    }
  },
  computed: {
    ...mapGetters('settings', ['recitationDetails'])
  },
 methods: {
    async fetchItem() {
      const id = this.$route.params.id
      let res = await fetchInterView(id);
      if (res.data.status) {
        this.items = res.data.data;
        this.getQuestions();
      }
   },
     async getQuestions() {

      try {
        let res = await getMethods('questions/' + this.items.template_id);
        if (res.data.status) {
          this.questions = res.data.data;
        }
      } catch (error) {
        if (error.response.status == 400) {
          this.$toast.error(error.response.data.message)
        } else {
          this.$toast.error('فشلت العملية');
        }
      }
    },
     async createAdminssion() {
      try {
        let formData = new FormData();
        formData.append("activeOption", this.activeOption);
        formData.append("perfection_value", this.amount_perfiction);
        formData.append("page_number", this.page_number);
        formData.append("meeting_resualt", this.result);
        formData.append("new_appointment", this.other_meeting);
        formData.append("new_appointment_date", this.appointment_date);
        formData.append("supervisor_message", this.supervisor_message);
        let res = await updateInterviwe(this.$route.params.id, formData);
        if (res.data.status) {
          this.$toast.success("تمت العملية بنجاح");
        } else {
          this.$toast.error(res.data.message);
        }
      } catch (error) {
        this.$toast.error('فشلت العملية');
      } finally {
        this.submitOnProgress = false;
      }
    },
  },
  beforeMount() {
    this.$store.dispatch('settings/pageTitle', 'المقابلات')
    this.$store.dispatch('settings/subTitleTop', ['إبراهيم علي المالكي'])
    this.$store.dispatch('settings/showTopTitle')
  },
  beforeUpdate() {
    this.$store.dispatch('settings/showTopTitle')
  },
  created() {
    this.fetchItem();
    
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/variable.scss";

.recitation-interview {
  background-color: $background-main-page;
  padding: 20px 15px 40px 15px;


  .container--fluid {
    border-radius: 15px;
    background-color: white;
    padding: 10px 15px 15px 15px;

    .image-video {
      width: 100%;
      height: 90vh;
      margin-bottom: 20px;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      position: relative;
      border-radius: 20px;
      @media only screen and (max-width: 800px) {
        height: 50vh;
      }
      img {
        width: 100%;
      }

      .image2-video {
        width: 110px;
        position: absolute;
        left: 5%;
        bottom: 5%;
        @media only screen and (max-width: 800px) {
          right: 2%;
          top: 2%;
        }
        img {
          width: 100%;
        }
      }

      .btn-video {
        position: absolute;
        display: flex;
        align-items: center;
        width: 100%;
        bottom: 2%;
        height: 50px;
        padding: 0 30px;
        @media only screen and (max-width: 800px) {
            justify-content: center;
        }
        .setting-video {
          display: flex;
          width: 35%;
          @media only screen and (max-width: 800px) {
            position: absolute;
            right: 0;
            margin-left: 50px;
            display: unset;
            bottom: 50%;
          }
          span {
            margin: 0 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 43px;
            height: 43px;
            border-radius: 50%;
            cursor: pointer;

            img {
              width: 55%;
            }
          }
        }

        .close-video {
          display: flex;
          align-items: center;

          span {
            margin: 0 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 35px;
            height: 35px;
            background-color: white;
            border-radius: 50%;
            box-shadow: 2px 2px 6px 2px rgba(0, 0, 0, 0.2);
            cursor: pointer;

            img {
              width: 43%;
            }
          }

          .btn-close {
            background-color: red;
            border-radius: 10px;
            width: 48px;
            height: 48px;
            img {
              width: 55%;
            }
          }
        }

      }
    }

    .details-video {
      padding: 10px 0;
      .information-student {
        border-left: 2px solid #D7E4FF;
        padding-left: 50px;
        padding-right: 10px;
        @media only screen and (max-width: 960px) {
          border-left: none;
        }
        .name-image {
          display: flex;
          align-items: center;
          justify-content: right;
          margin-bottom: 15px;

          .image {
            width: 70px;

            img {
              width: 100%;
            }
          }

          .name {
            width: 100%;
            position: relative;
            margin-right: 20px;


            p {
              font-size: 15px;
              font-weight: bold;
              margin-bottom: 0;
            }

            span {
              font-size: 13px;
              color: #B4B4B4;
            }
          }

        }

        .details-call-student {
          .path {
            display: flex;

            .image {
              background-color: rgba(56, 91, 114, 0.2);
              border-radius: 50%;
              width: 40px;
              height: 40px;
              min-width: 40px;
              max-height: 40px;
              display: flex;
              justify-content: center;
              align-items: center;

              img {
                width: 45%;
              }
            }

            .text-path {
              margin-right: 8px;

              p {
                margin-bottom: 0;
                color: #0F1222;
                font-size: 14px;
                font-weight: bold;
              }

              span {
                color: #B4B4B4;
                font-size: 13px;
              }
            }
          }

        }
      }
      .form-recitation{
        padding:0 20px;
      }
    }


  }


}

</style>
<style lang="scss" scoped>
@import "@/assets/css/variable.scss";

.question-interviews {
  background-color: $background-main-page;
width: 100%;
  @media only screen and (max-width: 700px) {
    padding: 20px 8px 40px 8px;
  }

  .container--fluid {
    border-radius: 15px;
    padding: 10px 15px 15px 15px;
  }

  .container-question {
    display: v-bind('displayShow');
    background-color: white;
    border-radius: 20px;
    padding-bottom: 30px;
    overflow: hidden;
    margin-bottom: 30px;

    .number-question {
      display: flex;
      background-color: rgba(56, 91, 114, 0.16);
      padding: 25px 30px;
      justify-content: space-between;
      align-items: center;

      .right-section {
        display: flex;
        align-items: center;

        .icon {
          width: 28px;
          margin-left: 15px;
          display: flex;
          align-items: center;

          img {
            width: 100%;
          }
        }

        h3 {
          font-size: 22px !important;
          font-weight: bold !important;
          color: #354052;

          @media only screen and (max-width: 700px) {
            font-size: 16px !important;
          }
        }
      }

      .left-section {
        .degree {
          display: flex;
          align-items: center;

          h3 {
            font-size: 22px !important;
            color: #272727;
            margin-left: 10px;

            @media only screen and (max-width: 700px) {
              font-size: 16px !important;
            }
          }

          input.number {
            width: 40px;
            background-color: #FAFBFC;
            padding: 4px 8px;
            border: 1px solid rgba(6, 83, 254, 0.2);
            border-radius: 10px;
            color: #385B72;
            margin-left: 50px;
            margin-right: 5px;
            font-size: 18px;
            display: inline-block;

          }

          span.close-icon {
            margin-left: 10px;
            background-color: rgba(231, 29, 54, 0.25);
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            font-weight: 300 !important;
            font-size: 15px;
            color: rgba(231, 29, 54, 0.76);

            @media only screen and (max-width: 700px) {
              width: 30px;
              height: 30px;
            }
          }
        }
      }
    }

    .content-question {
      padding: 30px 50px;

      @media only screen and (max-width: 700px) {
        padding: 30px 15px !important;
      }

      .question {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .type-question {
          @media only screen and (max-width: 700px) {
            display: none;
          }
        }

        h5 {
          color: #272727;
          font-size: 18px;
          margin-bottom: 20px;

          @media only screen and (max-width: 700px) {
            font-size: 16px;
          }
        }

        span {
          font-size: 15px;
          color: #385B72;
        }
      }
    }

    .all-answer {
      width: 100%;
      position: relative;

      .content-answer {
        display: flex;
        align-items: center;

        .image-option {
          display: flex;
          align-items: center;
          margin-bottom: 19px;
          margin-left: 20px;

          @media only screen and (max-width: 700px) {
            display: none !important;
          }

          .image {
            width: 22px;
            margin-top: 5px;

            img {
              width: 100%;
            }
          }

          .number-option {
            margin-right: 10px;
            margin-bottom: 7px;

            span {
              font-size: 15px !important;
            }
          }

        }

        .answer {
          width: 100%;

          .container-radio {
            border: 1px solid rgba(6, 83, 254, 0.21);
            border-radius: 5px;
            padding: 25px 15px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            @media only screen and (max-width: 700px) {
              .v-label {
                display: none !important;
              }
            }

            p {
              margin-bottom: 0;
              font-size: 16px;
              text-align: right;
              position: absolute;
              right: 2%;

              @media only screen and (max-width: 700px) {
                font-size: 14px;
              }

            }
          }
        }

        .delete-option {
          border: 1px solid rgba(6, 83, 254, 0.21);
          border-radius: 5px;
          margin-right: 5px;
          display: flex;
          align-items: center;
          padding: 14px 5px;
          cursor: pointer;
          margin-bottom: 19px !important;

          img {
            width: 22px;
          }
        }
      }

      .rows-number {
        display: inline-block;
        position: absolute;
        left: 5%;
        bottom: 25%;
        font-size: 12px;
        color: #272727;

      }

      .add-new-option {
        position: absolute;
        bottom: -2%;
        left: 1%;
        font-size: 14px;
        text-decoration: underline
      }
    }
  }

  .v-input--radio-group--column .v-radio {
    margin-bottom: 0;
  }

}
</style>
<style lang="scss">
.question-interviews {
  .v-size--default {
    padding-top: 22px !important;
    padding-bottom: 24px !important;
  }

  .v-input--selection-controls .v-input__slot>.v-label,
  .v-input--selection-controls .v-radio>.v-label {
    color: #00B5AD !important;
    margin-bottom: 7px;

    @media only screen and (max-width: 700px) {
      font-size: 13px;
    }
  }

  .v-application .primary--text {
    color: #00B5AD !important;
  }

  .v-icon.v-icon {
    font-size: 20px !important;
  }

  .v-input--radio-group--column .v-radio {
    margin-bottom: 0;
    width: 100% !important;
    position: absolute !important;
    z-index: 1;
  }

  .v-input--selection-controls.v-input {
    margin-top: 0px !important;
  }

  .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)>.v-input__control>.v-input__slot {
    box-shadow: none !important;
    border: 1px solid rgba(6, 83, 254, 0.15);

  }

  .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)>.v-input__control {
    padding-top: 15px;
  }

  .v-textarea.v-text-field--enclosed .v-text-field__slot textarea {
    font-size: 16px !important;
  }

  .input-7-4 {
    border: 1px solid #00B5AD
  }

  .modal-custom .button-modal {
    width: 100%;
  }


}


label.custom-label-recitation-interview {
  display: block;
  margin-bottom: 10px;
  font-size: 14px;
  background-color: white;
  position: absolute;
  top: 14%;
  z-index: 50;
  right: 4%;
}

label.custom-label-recitation-interview {
  font-size: 14px !important;
}

.createQuestion-one {
  padding: 20px 0;
}

.container-question-modal {
  background-color: white;
  border-radius: 20px;
  overflow: hidden;

  .content-question {
    padding: 10px 10px;

    .question {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .type-question {
        @media only screen and (max-width: 700px) {
          display: none;
        }
      }

      h5 {
        color: #272727;
        font-size: 18px;
        margin-bottom: 20px;
      }

      span {
        font-size: 15px;
        color: #385B72;
      }
    }
  }

  .all-answer {
    width: 100%;
    position: relative;

    .content-answer {
      display: flex;
      align-items: center;

      .image-option {
        display: flex;
        align-items: center;
        margin-bottom: 19px;
        margin-left: 10px;

        @media only screen and (max-width: 700px) {
          display: none !important;
        }

        .image {
          width: 22px;
          margin-top: 5px;

          img {
            width: 100%;
          }
        }

        .number-option {
          margin-right: 10px;
          margin-bottom: 7px;

          span {
            font-size: 15px !important;
          }
        }

      }

      .answer {
        @media only screen and (max-width: 700px) {
          width: 100%;
        }

        .container-radio {
          border: 1px solid rgba(6, 83, 254, 0.21);
          border-radius: 5px;
          padding: 25px 15px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          p {
            margin-bottom: 0;
            font-size: 16px;
            text-align: right;
            position: absolute;
            right: 2%;
          }
        }
      }

      .delete-option {
        border: 1px solid rgba(6, 83, 254, 0.21);
        border-radius: 5px;
        margin-right: 5px;
        display: flex;
        align-items: center;
        padding: 14px 5px;
        cursor: pointer;
        margin-bottom: 19px !important;

        img {
          width: 22px;
        }
      }
    }

    .rows-number {
      display: inline-block;
      position: absolute;
      left: 5%;
      bottom: 25%;
      font-size: 12px;
      color: #272727;

    }

    .add-new-option {
      position: absolute;
      bottom: -2%;
      left: 1%;
      font-size: 14px;
      text-decoration: underline
    }
  }

}

.v-dialog {
  .v-input--selection-controls {
    margin-top: 0 !important;
  }

  .container-question-modal .all-answer .content-answer .answer .container-radio {
    padding: 10px !important;
  }

  .v-size--default {
    padding-top: 22px !important;
    padding-bottom: 24px !important;
  }
}

.v-btn__content {
  font-size: 18px;
}</style>
