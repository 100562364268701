import { render, staticRenderFns } from "./recitationView.vue?vue&type=template&id=0379900a&scoped=true&"
import script from "./recitationView.vue?vue&type=script&lang=js&"
export * from "./recitationView.vue?vue&type=script&lang=js&"
import style0 from "./recitationView.vue?vue&type=style&index=0&id=0379900a&prod&lang=scss&scoped=true&"
import style1 from "./recitationView.vue?vue&type=style&index=1&id=0379900a&prod&lang=scss&scoped=true&"
import style2 from "./recitationView.vue?vue&type=style&index=2&id=0379900a&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0379900a",
  null
  
)

export default component.exports