<template>
  <div class="card-branches">
    
    <div class="name-branches">
      <div class="name">
        <div class="name-branch">
          <p>{{ name }}</p>
        </div>
      </div>
    </div>

    <div class="path-time">
      <validation-observer  v-slot="{ handleSubmit }">
         <v-form @submit.prevent="handleSubmit(createDuration)">
          <v-row>
            <v-col cols="12" md="6">
              <ValidationProvider name="هدف الفترة" :rules="rules.goal" v-slot="{ errors }">
                  <label>هدف الفترة</label>
                  <v-select v-model="goal" :field-errors="errors" :name="'هدف الفترة'" :items="mainbnds" item-text="text" item-value="value" outlined dense ></v-select>       
              </ValidationProvider>
            </v-col>
            <v-col cols="12" md="6">
              <input type="hidden" name="name" :value="name">
              <ValidationProvider name="مفدار الفترة" :rules="rules.faces" v-slot="{ errors }">
                <label>مفدار الفترة</label>
                  <v-text-field type="number"  v-model="faces" :error-messages="errors" dense outlined placeholder="10 مرة"></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="12" md="6">
              <input type="hidden" name="name" :value="name">
              <ValidationProvider name="عدد أيام الغياب" :rules="rules.absentDays" v-slot="{ errors }">
                <label>عدد أيام الغياب</label>
                  <v-text-field type="number"  v-model="absentDays" :error-messages="errors" dense outlined placeholder="عدد أيام الغياب"></v-text-field>
              </ValidationProvider>
            </v-col>
           
            
            <v-col cols="12" md="6">
         
              <ValidationProvider name="البنود الفعاله" :rules="rules.activeBand" v-slot="{ errors }">
                <select-input v-model="activeBand" multiple item-text="text" item-value="value" :field-errors="errors" :label="'البنود الفعالة'" :name="'البنود الفعالة'" :items="mainbnds"/>
              </ValidationProvider>
            </v-col>
            <v-col cols="12" md="6">
              <ValidationProvider name="إختبار نهاية الفترة" :rules="rules.fatraEndTest" v-slot="{ errors }">
                <select-input item-text="name" item-value="value" v-model="fatraEndTest" :field-errors="errors" :label="'إختبار نهاية الفترة'" :name="'إختبار نهاية الفترة'" :items="booleanOptions"/>
              </ValidationProvider>
            </v-col>
            <v-col cols="12" md="6">
              <ValidationProvider name="عرض نهاية الفترة" :rules="rules.showFatraEnd" v-slot="{ errors }">
                <select-input item-text="name" item-value="value" v-model="showFatraEnd" :field-errors="errors" :label="'عرض نهاية الفترة'" :name="'عرض نهاية الفترة'" :items="booleanOptions"/>
              </ValidationProvider>
            </v-col>
          </v-row>
          <div class="text-card">
            <div class="btn-done">
               <v-btn type="submit" block color="#00B5AD" class="mt-3 mb-4" style="color: white;font-size: 15px;height: 45px">حفظ</v-btn>
            </div>
          </div>
        </v-form>
      </validation-observer>
    </div>


  </div>
</template>

<script>
import SelectInput from "@/components/select-input";
import { postMethods, getMethods } from '@/api/Helper';

export default {
  name: "card-branches",
  components: {
    SelectInput,
 
  },
  props: {
    path_id: {
    },
    duration_id: {
      type: Number
    },
    
    goalOptions: {
      type: Array,
      default: () => [],
    },
    bonodsOptions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      imageStudent: require('@/assets/image/avatar/ebc8fbda-c735-4d9e-a8be-c5329afba775.png'),
      goal: null,
      localDurationId: this.duration_id,

      // duration_id: null,
      faces: null,
      name: null,
      mainbnds:[],
      absentDaysReached: null,
      activeBand: [],
      fatraEndTest: null,
      showFatraEnd: null,
      absentDays: null,
      bonod: [
        {
          id: 1,
          value: 1,
          text: "حفظ"
        },
        {
          id: 2,
          value: 2,
          text: "إتقان"
        },
        {
          id: 3,
          value: 3,
          text: "سماع"
        },
        {
          id: 4,
          value: 4,
          text: "تكرار"
        },
        {
          id: 5,
          value: 5,
          text: "ربط"
        },
        {
          id: 6,
          value: 6,
          text: "مراجعة"
        },
        {
          id: 7,
          value: 7,
          text: "تسميع"
        },
        {
          id: 8,
          value: 8,
          text: "إختبار"
        },
        {
          id: 9,
          value: 9,
          text: "تلاوة"
        },
        {
          id: 10,
          value: 10,
          text: "السرد الأسبوعي"
        },
      ],
      absentDaysReachedOptions: [
        {
          name: 'إنذار',
          id: 1,
          value:"yellow-card"
        },
        {
          name: 'إستبعاد',
          id: 2,
          value:"red-card"
        },
        {
          name: 'إعادة',
          id: 3,
          value:"reassign"
        },
      ],
      booleanOptions: [
        {
          id: 1,
          name: 'نعم',
          value: "yes",
        },
        {
          id: 2,
          name: 'لا',
          value: "no",
        },
      ],
      rules: {
        goal: {
          required: true,
        },
        faces: {
          required: true,
        },
        absentDaysReached: {
          required: true,
        },
        absentDays: {
          required: true,
        },
        activeBand: {
          required: true,
        },
        fatraEndTest: {
          required: true,
        },
        showFatraEnd: {
          required: true,
        },
      }
    }
  },
  watch: {
  duration_id(newVal) {
    this.localDurationId = newVal; // Sync with parent prop changes
  }
},
  methods: {
     getBonods() {
      let bonods = this.bonodsOptions;
      this.mainbnds = [];
      for (let i = 0; i < bonods.length; i++) {
        this.mainbnds.push(this.bonod[bonods[i] - 1]);
      }
    },
    async fetchItem() {
      
      let res = await getMethods('durations/' + this.localDurationId);
      if (res.data.status == 1) {
        this.track = res.data.data;
   
        
        if(res.data.data){
        
        this.name = res.data.data.name;
          this.goal = res.data.data.duration_goal;
          this.faces = res.data.data.duration_value;
          this.absentDays = res.data.data.absence_days_ended;
        console.log(this.goal)
        // this.step1.pathNums = res.data.data.duration_numbers;
        // this.step1.conserveParts = res.data.data.min_units;
        // this.step1.selectedBonod = res.data.data.band_type_id;
        // this.step1.commitment = res.data.data.text;
        // this.step1.mainBnd = res.data.data.main_band_id - 1;
        // this.step2.selectedBranch = res.data.data.branche_id;
        // for (let i = 0; i < res.data.data.band_type_id.length; i++) {
        //   this.mainbnds.push(this.bonod[res.data.data.band_type_id[i] - 1]);
        // }
        // if (res.data.data.warning1) {
        //   this.step2.mistakesRepeatEnabled = res.data.data.warning1.repeat_mistakes;
        //   this.step2.mistakesRepeatNum = parseInt(res.data.data.warning1.num_mistakes);
        //   this.step2.warningName = res.data.data.warning1.name;
        //   this.step2.warningToIndar = parseInt(res.data.data.warning1.transfer_to_yellow_card_after);
        //   this.step2.excludeFromProgram = parseInt(res.data.data.warning1.program_out);
        //   this.step2.indaratNums = parseInt(res.data.data.warning1.program_out_yellow_card_number);
        //   this.step2.resetWarnings = parseInt(res.data.data.warning1.duration);
        //   this.step2.resetWarningsDuration = res.data.data.warning2.duration;
        //   this.step2.selectedPath = res.data.data.warning2.duration_id;
        //   this.step2.pathWarningName = res.data.data.warning2.name;
        //   this.step2.pathWarningToIndar = res.data.data.warning2.transfer_to_yellow_card_after;
        //   this.step2.pathExcludeFromProgram = res.data.data.warning2.program_out;
        //   this.step2.pathIndaratNums = res.data.data.warning2.program_out_yellow_card_number;
        //   }
        }
      }
    },    
    async createDuration(){
      let formData = new FormData();
      formData.append("name",this.name);
      formData.append("path_id",this.path_id);
      formData.append("duration_goal",this.goal);
      formData.append("duration_value",this.faces);
      formData.append("absence_days",this.absentDays);
      // formData.append("absence_days_ended",this.absentDaysReached);
      formData.append("active_band_id",this.activeBand);
      formData.append("end_duration_exam",this.fatraEndTest);
      formData.append("end_duration_show",this.showFatraEnd);
      console.log(formData);
      try {
        let res;
        if (this.localDurationId == null) {
           res = await postMethods('durations', formData);
        } else {
          res = await postMethods('durations/updateduration', formData);
        }
       if (res.data.status == 1 && res.data.code == 200) {
         this.$toast.success('تم الحفظ بنجاح');
          this.localDurationId = res.data.data;
        } else {
          this.$toast.error(res.data.message);
        }
      } catch (error) {
        if (error.response.status == 400) {
          this.$toast.error(error.response.data.message)
        } else {
          this.$toast.error('فشلت العملية');
        }
      }
    }
  },
   created() {
    this.getBonods();
    this.fetchItem();
  }
}
</script>


<style lang="scss" scoped>
.card-branches {
  border: 1px solid #D2D5E1;
  border-radius: 5px;
  padding: 20px 10px 0 10px;

  .name-branches {
    display: flex;
    justify-content: right;
    margin-bottom: 15px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(143, 146, 161, 0.26);

    .image {
      width: 22%;
      padding-top: 6px;

      img {
        width: 100%;
      }
    }

    .name {
      width: 100%;
      position: relative;
      margin-right: 10px;

      .name-branch {
        display: flex;
        align-items: center;

        p {
          font-size: 21px;
          font-weight: bold;
          margin-bottom: 10px;
        }

        i {
          background-color: #00B5AD;
          margin-right: 13px;
          width: 21px;
          height: 21px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          font-size: 11px;
          font-weight: 300;
          color: white;
        }
      }

      //p {
      //  font-size: 15px;
      //  font-weight: bold;
      //  margin-bottom: 0;
      //}

      span {
        font-size: 13px;
        color: #B4B4B4;
      }
    }
  }

  .text-card {
    p {
      color: #B4B4B4;
      font-size: 14px;
      line-height: 2em;
    }
  }

  .avatar-branch {
    display: flex;
    align-items: center;

    span {
      margin-right: -3%;
      width: 27px;
      height: 27px;
      border-radius: 50%;

      img {
        width: 100%;
      }
    }

    span:first-child {
      margin-right: 0;
    }

    span.icon-plus {
      background-color: #1FD0A3;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 13px;
      color: white;
      border: 2px solid white;

      i {
        margin-top: 2px;
      }
    }

  }

  .status-student {
    position: absolute;
    left: 2%;
    top: -2%;
    background-color: #DBF7F0;
    text-align: center;
    color: #1FD0A3;
    font-size: 11px;
    padding: 10px 15px;
    border-radius: 10px;
    margin-bottom: 30px;
    margin-right: 10px;
  }


  .path-time {
    display: flex;
    justify-content: space-around;
    padding: 15px 10px;


    .col {
      padding-right: 2px;
      padding-left: 2px
    }

    .path {
      display: flex;

      .image {
        background-color: rgba(56, 91, 114, 0.2);
        border-radius: 50%;
        width: 40px;
        height: 40px;
        min-width: 40px;
        max-height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 45%;
        }
      }

      .text-path {
        margin-right: 8px;

        p {
          margin-bottom: 0;
          color: #0F1222;
          font-size: 14px;
          font-weight: bold;
        }

        span {
          color: #B4B4B4;
          font-size: 13px;
        }
      }
    }
  }

  .last-of-type {
    border-bottom: 1px solid rgba(143, 146, 161, 0.26);
  }


  .edit-delete {
    display: flex;
    justify-content: space-around;
    color: #B4B4B4;
    padding-left: 5px;
    padding-right: 5px;
    cursor: pointer !important;

    .edit, .delete {
      padding-top: 20px;
      padding-bottom: 25px;

    }

    .delete {
      i {
        padding-left: 3px;
      }
    }

    .v-btn:not(.v-btn--round).v-size--default {
      background-color: transparent !important;
      border: none;
      outline: none;
      box-shadow: none;
      color: #B4B4B4 !important;
      cursor: pointer !important;
    }

    .theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
      background-color: transparent !important;
    }

    i {
      font-size: 15px;
      margin-right: 10px;
      margin-top: 5px;
      background-color: #FAFCFE;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      border: 1px solid #D2D5E1;
    }

    i.fa-pencil {
      padding-left: 5px;
    }

    .border {
      border-right: 1px solid rgba(143, 146, 161, 0.26);
      padding: 0;
    }

    span {
      font-size: 15px;
    }

  }


}
</style>
<style lang="scss">
.card-branches {
  .v-btn:not(.v-btn--round).v-size--default {
    background-color: #00B5AD !important;
    font-size: 14px !important;
    color: white;
    border-radius: 5px !important;
  }

}

</style>
