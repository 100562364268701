<template>
  <section class="login-page">
    <v-main class="main-container">
      <v-row class="ma-0">
        <!-- right section image -->
        <v-col cols="12" md="6" lg="5" class="pa-0">
          <div class="overly">
            <img :src="images.overlay" alt="overlay">
          </div>
        </v-col>

        <!-- left section form -->
        <v-col cols="12 " md="6" lg="7" class="pa-0">
          <section class="left-section">
            <!--   content all section -->
            <div class="content-section">
              <!--  section header image logo-->
              <header-component/>

              <!--  section form-->
              <div class="form-section">
                <h4>مرحبا بكم معنا في ناظم نسخة البرامج النوعية </h4>
                <h2>تسجيل الدخول </h2>
                <v-row>
                  <v-col cols="12">
                      <v-alert dense type="error" v-if="loginApiError.status" >
                          {{ loginApiError.error.message }}
                      </v-alert>
                  </v-col>
                </v-row>
                <validation-observer  v-slot="{ invalid, handleSubmit }" >
                  <v-form @submit.prevent="handleSubmit(login)" :disabled="submitOnProgress">
                    <div class="group-field">
                      <label>اسم المستخدم</label>
                      <ValidationProvider name="اسم المستخدم" :rules="rules.username" v-slot="{ errors }">
                        <v-text-field v-model="username" :error-messages="errors" placeholder="اكتب اسم المستخدم"></v-text-field>
                      </ValidationProvider>
                    </div>
                    <div class="group-field">
                      <label>كلمه المرور</label>
                      <ValidationProvider name="كلمة المرور" :rules="rules.password" v-slot="{ errors }">
                        <v-text-field
                            v-model="password"
                            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="show1 ? 'text' : 'password'"
                            @click:append="show1 = !show1"
                            placeholder="اكتب كلمه المرور"
                            required
                            :error-messages="errors"
                        ></v-text-field>
                      </ValidationProvider>
                    </div>

                    <div class="remember-login">
                      <div class="checkbox-input">
                        <v-checkbox
                            v-model="rememberMe"
                            :label="`حفظ تسجيل دخول تلقائي`"
                        ></v-checkbox>
                      </div>
                      <div class="password-remember">
                        <router-link class="custom-link" to="/">هل نسيت كلمة المرور</router-link>
                      </div>
                    </div>
                    <div class="submit-button">
                      <v-btn type="submit" :disabled="invalid" depressed color="#508ABA" class="text-white btn-custom">
                        تسجيل دخول
                      </v-btn>
                    </div>
                    <div class="create-account">
                      <span>ليس لديك حساب</span>
                      <router-link to="/signup" class="custom-link"> تسجيل جديد</router-link>
                    </div>
                  </v-form>
                </validation-observer>
              </div>
              <!--  section copy right -->
              <copyrights-component/>
            </div>
          </section>
        </v-col>
      </v-row>
    </v-main>
  </section>
</template>

<script>
import HeaderComponent from "@/views/loginSignup/components/headerComponent";
import CopyrightsComponent from "@/views/loginSignup/components/copyrightsComponent";
import {mapGetters} from "vuex";

export default {
  name: "loginView",
  components: {CopyrightsComponent, HeaderComponent},
  data() {
    return {
      images: {
        overlay: require('@/assets/image/logo/Pateeren.png'),
      },
      show1: false,
      username: '',
      password: '',
      rememberMe: false,
      submitOnProgress: false,
      loginApiError: {
          status: false,
          error: {},
      },
      rules: {
        username: {
          required: true,
        },
        password: {
          required: true,
        },
      }
    }
  },
  computed: {
    ...mapGetters("settings",[
        'linkBarRight', 
        'showBarRight',
    ]),
  },
  methods: {
    async login() {
        this.submitOnProgress = true;

        this.loginApiError = {
            status: false,
            error: {},
        };

        let userCreds = {
          username: this.username,
          password: this.password,
        };

        if(this.rememberMe) userCreds.remember = true;

        // try {
          let res = await this.$store.dispatch('auth/handlerLogin', userCreds);
          if(res.status) {
              this.loginApiError.status = false;
              this.$router.push({ name: 'dashboard' });
          }else {
            this.loginApiError.status = true;
            this.loginApiError.error.message = res.error;
          }
        // } catch (error) {
        //   this.loginApiError.status = true;
        //   this.loginApiError.message = error.response ? error.response.data : error;
        // } finally {
        //   this.submitOnProgress = false;
        // }
    },
  },
  beforeMount() {
    this.$store.dispatch('settings/showBarRight');
  }

}
</script>

<style lang="scss" scoped>
@import "@/assets/css/variable.scss";


.login-page {
  @media (min-width: 1280px) {
    .col-lg-7 {
      flex: 0 0 54.3333333333%;
      max-width: 54.3333333333%;
    }
    .col-lg-5 {
      flex: 0 0 45.666667%;
      max-width: 45.666667%;
    }
  }

  .main-container {
    min-height: 100vh;
    background-image: url("@/assets/image/logo/Sign In.png");
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;

    .overly {
      position: absolute;
      right: 0;
      width: 120px;
      opacity: 0.8;

      img {
        width: 100%;
      }
    }

    //start left section

    .left-section {
      padding: 20px 10PX;
      min-height: 100vh;
      background-color: white;
      border-radius: 0 20px 20px 0;
      width: 100%;

      .content-section {
        width: 70%;
        margin: 0 auto;
      }

      .logo-image {
        width: 100%;

        .image {
          width: 70%;

        }
      }

      .form-section {
        h4 {
          font-size: 17px;
          line-height: 24px;
          margin-bottom: 20px;
          color: #707070;
          font-weight: 600;

        }

        h2 {
          font-size: 38px;
          line-height: 30px;
          margin-bottom: 30px;
          color: #000000;
        }

        label {
          font-size: 18px;
          color: #272727;
          margin-bottom: 10px;
          display: inline-block;
        }

        .remember-login {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .checkbox-input {

            input[type=checkbox] {
              margin-left: 15px;
              padding: 10px;
              transform: scale(1.2);
            }


          }
        }

        .password-remember {
          .custom-link {
            color: $linkColor;
            font-size: 16px;
          }
        }

        .submit-button {
          margin-top: 20px;
          margin-bottom: 20px;

          .btn-custom {
            width: 100%;
            min-height: 64px;
            font-size: 16px;
            color: white;
          }
        }

        .create-account {
          text-align: center;

          .custom-link {
            font-size: 16px !important;
            color: $linkColor;
          }

          span {
            font-size: 16px;
            margin-left: 5px;
            color: $linkColor;
          }
        }
      }
    }

    //end left section
  }
}

</style>

<!--custom style input vuetify-->
<style lang="scss">
.login-page {
  input {
    background-color: #FAFBFC !important;
    padding-right: 20px !important;
    opacity: 0.8;
    color: #385B72 !important;
    font-size: 17px !important;
    border-radius: 6px !important;
    border: 1px solid #8692A6;
    width: 100%;
  }

  ::placeholder {
    font-size: 15px;
  }


  .v-field__input {
    opacity: .8;
    border: none;
    min-height: 48px;
  }

  //.v-field__outline {
  //  display: none;
  //}
  .group-field {
    position: relative !important;
  }

  .v-input__icon {
    position: absolute;
    left: 3%;
    top: 50%;
    transform: translateY(-50%);
    font-size: 15px;
  }

  .v-messages__message {
    font-size: 16px !important;
    margin-bottom: 10px;
  }

  .v-application--is-ltr .v-messages {
    text-align: right !important;
  }

  .login-page .v-messages__message {
    text-align: center !important;
  }

  .v-text-field {
    padding-top: 0;
    margin-top: 0;
  }

  .group-field {
    line-height: 1em;
  }

  .theme--light.v-input input, .theme--light.v-input textarea {
    padding: 20px;
  }

  .theme--light.v-text-field > .v-input__control > .v-input__slot:before {
    border: none !important;
  }

  .v-text-field > .v-input__control > .v-input__slot:after {
    border: none !important;
  }

  .form-section {
    .remember-login {
      .v-label {
        margin-right: 8px !important;
      }
    }
  }


}

</style>
