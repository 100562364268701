<template>
  <button>بحث</button>
</template>

<script>
export default {
  name: "btnSearch"
}
</script>

<style scoped>
button {
  border: 1px solid #00B5AD;
  padding: 10px 40px;
  font-size: 14px;
  border-radius: 10px;
  color: #00B5AD;
  width: 100%;
}
</style>
