import { store } from '../store';
import { arrayUnique } from 'Helper';
import { getRoles } from "@/plugins/cookies";

export const auth = ({ next }) => {
  console.log('ss');
  if (getRoles()) {
    return next();
  } else {
    next({ name: 'login' })
  }
}

export const guest = ({ next }) => {
  if (store.getters['auth/authenticated']) {
    next({ name: 'forbiddenAcees' })
  } else {
    next()
  }
}
export const checkPermission = ({ next, to }) => {
  if (to.meta.requiresPermission && to.meta.permission.length) {
    let user = getRoles();

    console.log('user');
    console.log(user);
    console.log('user');
    if (!user) {
      next({ name: 'login' })
    } else {
      let user_roles = JSON.parse(user);
      let user_total_permissions = [];
      user_roles.forEach(role => {
        user_total_permissions = user_total_permissions.concat(role.permissions);
      });
      console.log(user_total_permissions)

      let requiredPerms = to.meta.permission.map(v => v.toLowerCase());
      let pass = arrayUnique(user_total_permissions).find((permission) => {
        return requiredPerms.includes(permission.toLowerCase());
      });
      if (pass) {
        next();
      } else {
        console.log(user_roles)
        next({ name: 'forbiddenAcees' })
      }
    }
  }
}

