<template>
  <section class="login-page">
    <v-main class="main-container">
      <v-row class="ma-0">
        <!-- right section image -->
        <v-col cols="12" md="6" lg="5" class="pa-0">
          <div class="overly">
            <img :src="images.overlay" alt="overlay">
          </div>
        </v-col>

        <!-- left section form -->
        <v-col cols="12 " md="6" lg="7" class="pa-0">
          <section class="left-section">
            <!--   content all section -->
            <div class="content-section">
              <!--  section header image logo-->
              <header-component/>

              <!--  section form-->
              <div class="form-section">
                <h4>مرحبا بكم معنا في ناظم نسخة البرامج النوعية </h4>
                <div class="d-flex align-baseline">
                  <h2>التسجيل</h2>
                  <div class="me-3 ">
                    <span class="v-stepper__step__step primary ms-2">1</span>
                    <span class="fs-16 font-weight-bold">بيانات أساسية</span>
                  </div>

                  <v-divider class="mx-2"></v-divider>
                  <div>
                    <span class="v-stepper__step__step primary ms-2">2</span>
                    <span class="fs-16 font-weight-bold">بيانات إضافية</span>
                  </div>
                </div>

                <v-stepper class="elevation-0" v-model="step">

                  <v-stepper-items>

                    <v-stepper-content step="1">
                      
                      <validation-observer  v-slot="{ invalid, handleSubmit }" >
                        <v-form @submit.prevent="handleSubmit(register)" :disabled="submitOnProgress">
                          <div class="group-field">
                            <label>اسم المستخدم</label>
                            <ValidationProvider mode="lazy" name="إسم المستخدم" :rules="rules.username" v-slot="{ errors }">
                              <v-text-field v-model="username" :error-messages="errors" placeholder="اكتب اسم المستخدم"></v-text-field>
                            </ValidationProvider>
                          </div>
                          <div class="group-field">
                            <label>البريد الالكتروني</label>
                            <ValidationProvider mode="lazy" name="البريد الإلكتروني" :rules="rules.email" v-slot="{ errors }">
                              <v-text-field v-model="email" :error-messages="errors" placeholder="أكتب البريد الالكتروني"></v-text-field>
                            </ValidationProvider>
                          </div>
                          <div class="group-field">
                            <label>الإسم</label>
                            <ValidationProvider mode="lazy" name="الإسم" :rules="rules.firstName" v-slot="{ errors }">
                              <v-text-field v-model="firstName" :error-messages="errors" placeholder="اكتب إسمك"></v-text-field>
                            </ValidationProvider>
                          </div>
                          <div class="group-field">
                            <label>اسم الأب</label>
                            <ValidationProvider mode="lazy" name="إسم الأب" :rules="rules.middleName" v-slot="{ errors }">
                              <v-text-field v-model="middleName" :error-messages="errors" placeholder="اكتب اسم الأب"></v-text-field>
                            </ValidationProvider>
                          </div>
                          <div class="group-field">
                            <label>اسم العائلة</label>
                            <ValidationProvider mode="lazy" name="إسم العائلة" :rules="rules.lastName" v-slot="{ errors }">
                              <v-text-field v-model="lastName" :error-messages="errors" placeholder="اكتب اسم العائلة"></v-text-field>
                            </ValidationProvider>
                          </div>
                          <v-col cols="12" lg="12">
                              <div class="group-field">

                                <label>المسار</label>
                                <ValidationProvider mode="lazy" name="المسار" :rules="rules.path_id" v-slot="{ errors }">
                                  <v-select
                                      :items="paths"
                                      item-text="text"
                                      item-value="id"
                                      placeholder="المسار"
                                      :error-messages="errors"
                                      v-model="path_id"
                                  ></v-select>
                                </ValidationProvider>
                              </div>
                          </v-col> 
                          <div class="submit-button">
                            <v-btn @click="step = 2" :disabled="invalid" depressed color="#508ABA" class="text-white btn-custom">
                              التالي
                            </v-btn>
                          </div>
      
                        </v-form>
                      </validation-observer>
                    </v-stepper-content>
                    <v-stepper-content step="2">
                      <validation-observer  v-slot="{ invalid, handleSubmit }" >
                        <v-form @submit.prevent="handleSubmit(register)" :disabled="submitOnProgress">
                          <v-row>
                            <v-col cols="12" lg="6">
                              <div class="group-field">
                                <label>رقم الهوية</label>
                                <ValidationProvider mode="lazy" name="رقم الهوية" :rules="rules.id" v-slot="{ errors }">
                                  <v-text-field 
                                    v-model.number="id" 
                                    :error-messages="errors" 
                                    placeholder="رقم الهوية"
                                    required
                                    ></v-text-field>
                                </ValidationProvider>
                              </div>
                            </v-col>
                            <v-col cols="12" lg="6">
                              <div class="group-field">
                                <label>صورة الهوية</label>
                                <v-file-input
                                    placeholder="ارفع هنا صورة الهوية"
                                    v-model="photoId"
                                ></v-file-input>
                              </div>
                            </v-col>
                            <v-col cols="12" lg="6">
                              <div class="group-field">

                                <label>الجنس</label>
                                <ValidationProvider mode="lazy" name="الجنس" :rules="rules.gender" v-slot="{ errors }">
                                  <v-select
                                      :items="genders"
                                      item-text="name"
                                      item-value="value"
                                      placeholder="الجنس"
                                      :error-messages="errors"
                                      v-model="gender"
                                  ></v-select>
                                </ValidationProvider>
                              </div>
                            </v-col>
                            <v-col cols="12" lg="6">
                              <div class="group-field">

                                <label>دولة الاقامة</label>
                                <ValidationProvider mode="lazy" name="دولة الاقامة" :rules="rules.country" v-slot="{ errors }">
                                  <v-select
                                      :items="countries"
                                      placeholder="دولة الاقامة"
                                      :error-messages="errors"
                                      v-model="country"
                                  ></v-select>
                                </ValidationProvider>
                              </div>
                            </v-col>
                            <v-col cols="12" lg="6">
                              <div class="group-field">
                                <label>رمز المنطقة</label>
                                <ValidationProvider mode="lazy" name="رمز المنطقة" :rules="rules.postal" v-slot="{ errors }">
                                  <v-text-field v-model="postal" :error-messages="errors" placeholder="رمز المنطقة"></v-text-field>
                                </ValidationProvider>
                              </div>
                            </v-col>
                            <v-col cols="12" lg="6">
                              <div class="group-field">
                                <label>المؤهل العلمي</label>
                                <ValidationProvider mode="lazy" name="المؤهل العلمي" :rules="rules.education" v-slot="{ errors }">
                                  <v-text-field v-model="education" :error-messages="errors" placeholder="المؤهل العلمي"></v-text-field>
                                </ValidationProvider>
                              </div>
                            </v-col>
                            <v-col cols="12" lg="6">
                              <div class="group-field">

                                <label>الجنسية</label>
                                <ValidationProvider mode="lazy" name="الجنسية" :rules="rules.nationality" v-slot="{ errors }">
                                  <v-select
                                      :items="countries"
                                      placeholder="الجنسية"
                                      :error-messages="errors"
                                      v-model="nationality"
                                  ></v-select>
                                </ValidationProvider>
                              </div>
                            </v-col>
                            <v-col cols="12" lg="6">
                              <div class="group-field">
                                <label>مدينة الاقامة</label>
                                <ValidationProvider mode="lazy" name="مدينة الاقامة" :rules="rules.city" v-slot="{ errors }">
                                  <v-text-field v-model="city" :error-messages="errors" placeholder="المدينه"></v-text-field>
                                </ValidationProvider>
                              </div>
                            </v-col>
                            <v-col cols="12" lg="6">
                              <div class="group-field">
                                <label>الحي السكني</label>
                                <ValidationProvider mode="lazy" name="الحي السكني" :rules="rules.address" v-slot="{ errors }">
                                  <v-text-field v-model="address" :error-messages="errors" placeholder="الحي السكني"></v-text-field>
                                </ValidationProvider>
                              </div>
                            </v-col>
                            <v-col cols="12" lg="6">
                              <div class="group-field">
                                <label>الفرع</label>
                                <ValidationProvider mode="lazy" name="الفرع" :rules="rules.branch" v-slot="{ errors }">
                                  <v-select
                                      :items="branches"
                                      item-text="name"
                                      item-value="id"
                                      placeholder="الفرع"
                                      :error-messages="errors"
                                      v-model="selectedBranch"
                                  ></v-select>
                                </ValidationProvider>
                              </div>
                            </v-col>
                          </v-row>
                          <div class="d-flex align-baseline">
                            <div class="submit-button">
                              <v-btn type="submit" :disabled="invalid" depressed color="#508ABA" class="text-white btn-custom">
                                تسجيل
                              </v-btn>
                            </div>
                            <v-btn class="me-3" @click="step = 1" text>
                              رجوع
                            </v-btn>
                          </div>

                        </v-form>
                      </validation-observer>
                      
                    </v-stepper-content>

                  </v-stepper-items>

                  <div class="create-account">
                    <span> لديك حساب؟</span>
                    <router-link to="/login" class="custom-link"> تسجيل الدخول</router-link>
                  </div>

                </v-stepper>

              </div>
              <!--  section copy right -->
              <copyrights-component/>
            </div>
          </section>
        </v-col>
      </v-row>
    </v-main>
  </section>
</template>

<script>
import HeaderComponent from "@/views/loginSignup/components/headerComponent";
import CopyrightsComponent from "@/views/loginSignup/components/copyrightsComponent";
import {mapGetters} from "vuex";
import { registerStudent, fetchBranches, listPaths } from 'Actions';

export default {
  name: "loginView",
  components: {CopyrightsComponent, HeaderComponent
  },
  data() {
    return {
      step: 1,
      username: '',
      firstName: '',
      middleName: '',
      lastName: '',
      email: '',
      id: '',
      path_id: null,
      photoId: null,
      country: null,
      nationality: null,
      city: '',
      address: '',
      branches: [],
      paths: [],
      selectedBranch: null,
      education: '',
      postal: '',
      genders: [
        {
          name: 'ذكر',
          value: 'male',
        },
        {
          name: 'أنثى',
          value: 'female',
        }
      ],
      gender: null,
      images: {
        overlay: require('@/assets/image/logo/Pateeren.png'),
      },
      submitOnProgress: false,
      loadingBranchesStatus: true,
      fetchBranchesApiError: {
          status: false,
          error: {},
      },
      createStudentApiError: {
          status: false,
          error: {},
      },
      rules: {
        username: {
          required: true,
          alpha_dash: true,
          uniq: ['students/check-username', 'username']
        },
        email: {
          required: true,
          email: true,
          uniq: ['students/check-email', 'email']
        },
        firstName: {
          required: true,
        },
        path_id: {
          required: true,
        },
        middleName: {
          required: true,
        },
        lastName: {
          required: true,
        },
        id: {
          required: true,
          digits: 10,
          uniq: ['students/check-id-number', 'national_id']
        },
        country: {
          required: true,
        },
        city: {
          required: true,
        },
        nationality: {
          required: true,
        },
        education: {
          required: true,
        },
        address: {
          required: true,
        },
        postal: {
          required: true,
        },
        gender: {
          required: true,
        },
        branch: {
          required: true,
        },
      }
    }
  },
  computed: {
    ...mapGetters("settings",[
        'linkBarRight', 
        'showBarRight',
        'countries',
    ]),
  },
  methods: {
     async getPaths() {

        let res = await listPaths();
        if (res.data.status) {
          this.paths = res.data.data;
        }
    },
    async register() {
        this.submitOnProgress = true;

        this.createStudentApiError = {
            status: false,
            error: {},
        };

        let formData = new FormData();
        formData.append("username", this.username);
        formData.append("first_name", this.firstName);
        if(this.middleName) formData.append("father_name", this.middleName);
        formData.append("last_name", this.lastName);
        formData.append("email", this.email);
        formData.append("national_id", this.id);
        formData.append("national_id_image", this.photoId);
        formData.append("nationality", this.nationality);
        formData.append("program_type", this.path_id);
        formData.append("country", this.country);
        formData.append("city", this.city);
        formData.append("city_code", this.postal);
        formData.append("address", this.address);
        formData.append("sex", this.gender);
        formData.append("education", this.education);
        formData.append("branche_id", this.selectedBranch);
        
        try {
            let res = await registerStudent(formData);
            if (res.data.status) {
                this.$toast.success("تمت العملية بنجاح");
                this.$router.push({ name: 'login' });
            } else {
                this.$toast.error(res.data.message);
                this.createStudentApiError.status = true;
                this.createStudentApiError.error.message = res.data.message;
            }
        } catch (error) {
            if (error.response.status == 422) {
               this.$toast.error(error.response.data.message);
            }
          this.createStudentApiError.status = true;
          console.log(error.response);
          this.createStudentApiError.message = error.response ? error.response.data : error;

        } finally {
            this.submitOnProgress = false;
        }
    },
    async getBranches() {
        this.loadingBranchesStatus= true;
        this.fetchBranchesApiError = {
            status: false,
            error: {},
        };
        try {
            let res = await fetchBranches();
            if (res.data.status) {
                this.branches = res.data.data;
            } else {
                this.fetchBranchesApiError.status = true;
                this.fetchBranchesApiError.error.message = res.data.message;
            }
        } catch (error) {
            this.fetchBranchesApiError.status = true;
            this.fetchBranchesApiError.error.message = error.response ? error.response.data : error;
        } finally {
            this.loadingBranchesStatus = false;
        }
    },
    setup() {
      this.getBranches();
      this.getPaths();
    }
  },
  beforeMount() {
    this.$store.dispatch('settings/showBarRight');
  },
  created() {
    this.setup();
  }

}
</script>

<style lang="scss" scoped>
@import "@/assets/css/variable.scss";


.login-page {
  @media (min-width: 1280px) {
    .col-lg-7 {
      flex: 0 0 54.3333333333%;
      max-width: 54.3333333333%;
    }
    .col-lg-5 {
      flex: 0 0 45.666667%;
      max-width: 45.666667%;
    }
  }

  .main-container {
    min-height: 100vh;
    background-image: url("@/assets/image/logo/Sign In.png");
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;

    .overly {
      position: absolute;
      right: 0;
      width: 120px;
      opacity: 0.8;

      img {
        width: 100%;
      }
    }

    //start left section

    .left-section {
      padding: 20px 10PX;
      min-height: 100vh;
      background-color: white;
      border-radius: 0 20px 20px 0;
      width: 100%;

      .content-section {
        width: 70%;
        margin: 0 auto;
      }

      .logo-image {
        width: 100%;

        .image {
          width: 70%;

        }
      }

      .form-section {
        h4 {
          font-size: 17px;
          line-height: 24px;
          margin-bottom: 20px;
          color: #707070;
          font-weight: 600;

        }

        h2 {
          font-size: 38px;
          line-height: 30px;
          margin-bottom: 30px;
          color: #000000;
        }

        label {
          font-size: 18px;
          color: #272727;
          margin-bottom: 10px;
          display: inline-block;
        }

        .submit-button {
          margin-top: 20px;
          margin-bottom: 20px;

          .btn-custom {
            width: 100%;
            min-height: 64px;
            font-size: 16px;
            color: white;
          }
        }

        .create-account {
          text-align: center;

          .custom-link {
            font-size: 16px !important;
            color: $linkColor;
          }

          span {
            font-size: 16px;
            margin-left: 5px;
            color: $linkColor;
          }
        }
      }
    }

    //end left section
  }
}

</style>

<!--custom style input vuetify-->
<style lang="scss">

.fs-16 {
  font-size: 16px !important;
}

.login-page {
  input {
    background-color: #FAFBFC !important;
    padding-right: 20px !important;
    opacity: 0.8;
    color: #385B72 !important;
    font-size: 17px !important;
    border-radius: 6px !important;
    border: 1px solid #8692A6;
    width: 100%;
  }

  ::placeholder {
    font-size: 15px;
  }


  .v-field__input {
    opacity: .8;
    border: none;
    min-height: 48px;
  }

  //.v-field__outline {
  //  display: none;
  //}
  .group-field {
    position: relative !important;
  }

  .v-input__icon {
    position: absolute;
    left: 3%;
    top: 50%;
    transform: translateY(-50%);
    font-size: 15px;
  }

  .v-messages__message {
    font-size: 16px !important;
    margin-bottom: 10px;
  }

  .v-application--is-ltr .v-messages {
    text-align: right !important;
  }

  .login-page .v-messages__message {
    text-align: center !important;
  }

  .v-text-field {
    padding-top: 0;
    margin-top: 0;
  }

  .group-field {
    line-height: 1em;
  }

  .theme--light.v-input input, .theme--light.v-input textarea {
    padding: 20px;
  }

  .theme--light.v-text-field > .v-input__control > .v-input__slot:before {
    border: none !important;
  }

  .v-text-field > .v-input__control > .v-input__slot:after {
    border: none !important;
  }
}

</style>
