<template>
  <div class="input-text">
    <label>{{ labelTop }}</label>
    <v-text-field
        :type="fieldType"
        :placeholder="placeholder"
        :min="minVal"
        :max="maxVal"
        :disabled="disabled"
        :value="value"
        @input="handleInput"
        @blur="handleInput"
        v-model="content"
    ></v-text-field>
    <span class="fs-14 error--text" v-if="fieldErrors.length">{{ fieldErrors[0] }}</span>
  </div>
</template>

<script>
export default {
  name: "input-text",
  props: {
    fieldType: {
      type: String,
      default: '',
    },
    value: {
    },
    minVal: {
      type: Number,
      default: null,
    },
    maxVal: {
      type: Number,
      default: null,
    },
    labelTop: {
      required: true,
    },
    placeholder: {
      required: true,
    }, disabled: {
      default: false,
    },
    fieldErrors: {
      required: false,
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      content: this.value,
    }
  },
  methods: {
    handleInput () {
      this.$emit('input', this.content)
    },
  }
}
</script>

<style lang="scss" >



.input-text{
  position: relative;
  .v-text-field {
    border: 1px solid rgba(6, 83, 254, 0.18) !important;
    border-radius: 5px;
    padding-top: 5px !important;
  }

  .v-text-field__details {
    display: none;
  }

  label {
    padding-right: 0px !important;
    margin-bottom: 10px;
  }

  .v-text-field .v-label {
    top: 3px !important;

  }

  input::placeholder {
    color: #CBCACA !important;
    font-size: 13px !important;
  }

  label {
    display: block;
    margin-bottom: 10px;
    font-size: 14px !important;
    background-color: white;
    position: absolute;
    top: -13px !important;
    z-index: 1;
    padding: 0 1px;
    right: 11px !important;
    font-weight: bold;

  }
  label.custom-label{
    font-size: 14px !important;
  }

  .v-input__slot {
    margin-bottom: 0 !important;
    padding-bottom: 5px;
  }
  .v-input__slot input {
    font-size: 14px;
  }

}

</style>
