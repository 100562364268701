<template>
<section class="add-tracks">
    <v-main>
        <div class="container--fluid" style="border-radius: 15px; background-color: white; padding: 15px">

            <div class="step-by-step-Tracks">

                <v-tabs v-model="tab" background-color="transparent" color="basil" grow>
                    <v-tab ripple href='#tab-1'>
                        البيانات العامة
                        <div class="dote dote-one">
                            <i class="fa fa-dot-circle-o"></i>
                        </div>
                    </v-tab>
                    <v-tab ripple href='#tab-2'>
                        القوانين الادارية
                        <div class="dote dote-tow">
                            <i class="fa fa-dot-circle-o"></i>
                        </div>
                    </v-tab>
                    <v-tab ripple href='#tab-3'>
                        الفترات
                        <div class="dote dote-three">
                            <i class="fa fa-dot-circle-o"></i>
                        </div>
                    </v-tab>
                    <v-tab ripple href='#tab-4'>
                        البنود
                        <div class="dote dote-four">
                            <i class="fa fa-dot-circle-o"></i>
                        </div>
                    </v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab">
                    <validation-observer v-slot="{  handleSubmit,invalid }">
                        
                        <v-tab-item value='tab-1'>
                            <v-card-text>
                                    <v-form @submit.prevent="handleSubmit(addTrack)" :disabled="submitOnProgress">
                                    <validation-observer v-slot="{ invalid1 }">
                                        <div class="createQuestion-one">
                                            <div class="mt-3">
                                                <ValidationProvider name="اسم المسار" :rules="rules.step1.name" v-slot="{ errors }">
                                                    <input-text v-model="step1.name" :fieldErrors="errors" :label-top="'أكتب هنا إسم المسار'" :placeholder="'أكتب هنا إسم المسار'" />
                                                </ValidationProvider>
                                            </div>
                                            <div class="mt-6">
                                                <ValidationProvider name="مدة المسار" :rules="rules.step1.pathDuration" v-slot="{ errors }">
                                                    <input-text field-type="number" :min-val="1" v-model="step1.pathDuration" :fieldErrors="errors" :label-top="'مدة المسار'" :placeholder="'أكتب هنا مدة المسار'" />
                                                </ValidationProvider>
                                            </div>
                                            <div class="mt-6" style="position: relative">
                                                <ValidationProvider name="أجزاء حفظ الطلاب" :rules="rules.step1.conserveParts" v-slot="{ errors }">
                                                    <select-input v-model="step1.conserveParts" multiple :field-errors="errors" :label="'اجزاء حفظ الطلاب'" :name="'أجزاء حفظ الطلاب'" :items="conservePartsOptions" />
                                                </ValidationProvider>
                                            </div>
                                            <div class="mt-6" style="position: relative">
                                                <ValidationProvider name="بنود المسار" :rules="rules.step1.selectedBonod" v-slot="{ errors }">
                                                    <select-input @change="getBonods()" multiple v-model="step1.selectedBonod" :field-errors="errors" :label="'بنود المسار'" :name="'بنود المسار'" item-text="text" item-value="value" :items="bonod" />
                                                </ValidationProvider>
                                            </div>
                                            <div class="mt-6" style="position: relative">
                                                <ValidationProvider name="البند الرئيسي للمسار" :rules="rules.step1.mainBnd" v-slot="{ errors }">
                                                    <select-input :disabled="!step1.selectedBonod.length" v-model="step1.mainBnd" :field-errors="errors" :label="'البند الرئيسي للمسار'" :name="'البند الرئيسي للمسار'" :items="mainbnds" item-text="text" item-value="value" />
                                                </ValidationProvider>
                                            </div>
                                            <div class="mt-6" style="position: relative">
                                                <ValidationProvider name="عدد الفترات" :rules="rules.step1.pathNums" v-slot="{ errors }">
                                                    <input-text field-type="number" :min-val="1" v-model="step1.pathNums" :field-errors="errors" :label-top="'عدد الفترات'" :placeholder="'عدد الفترات'" />
                                                </ValidationProvider>
                                            </div>
                                            <div>
                                                <ValidationProvider name="نص التعهد" :rules="rules.step1.commitment" v-slot="{ errors }">
                                                    <text-area v-model="step1.commitment" :field-errors="errors" :label="'نص التعهد'" :placeholder="'أكتب هنا نص التعهد'" />
                                                </ValidationProvider>
                                            </div>
                                            <div class="uploadFile mt-7">
                                                <drag-and-drop-uploader />
                                            </div>
                                        </div>
                                        <v-btn :disabled="invalid1" class="btn-form" block color="#00B5AD" @click="changeTabNext2()">التالي</v-btn>
                                    </validation-observer>
                                </v-form>
                                </v-card-text>
                            </v-tab-item>
                        

                            <v-tab-item value='tab-2'>
                                <v-card-text>

                                    <validation-observer v-slot="{ invalid2 }">
                                        <div class="createQuestion-tow">
                                            <div class="mt-6" style="position: relative">
                                                <ValidationProvider name="هل يوجد تكرار أخطاء" :rules="rules.step2.mistakesRepeatEnabled" v-slot="{ errors }">
                                                    <select-input item-text="text" item-value="value" v-model="step2.mistakesRepeatEnabled" :field-errors="errors" :label="'هل يوجد تكرار اخطاء '" :name="'نعم'" :items="booleanOptions" />
                                                </ValidationProvider>
                                            </div>
                                            <div class="mt-6">
                                                <ValidationProvider name="عدد تكرار الأخطاء" :rules="rules.step2.mistakesRepeatNum" v-slot="{ errors }">
                                                    <input-text field-type="number" :min-val="0" v-model="step2.mistakesRepeatNum" :field-errors="errors" :label-top="'عدد تكرار الاخطاء'" :placeholder="'10 مرة '" />
                                                </ValidationProvider>
                                            </div>
                                            <div class="mt-6" style="position: relative">
                                                <ValidationProvider name="الفروع" :rules="rules.step2.selectedBranch" v-slot="{ errors }">
                                                    <select-input item-text="name" item-value="id" v-model="step2.selectedBranch" :field-errors="errors" :label="'الفروع'" :name="'أختر الفروع'" :items="branches" />
                                                </ValidationProvider>
                                            </div>
                                            <div class="mt-6">
                                                <ValidationProvider name="إسم التحذير" :rules="rules.step2.warningName" v-slot="{ errors }">
                                                    <input-text v-model="step2.warningName" :field-errors="errors" :label-top="'إسم التحذير'" :placeholder="'أكتب إسم التحذير'" />
                                                </ValidationProvider>
                                            </div>
                                            <div class="mt-6" style="position: relative">
                                                <ValidationProvider name="التأخير" :rules="rules.step2.late" v-slot="{ errors }">
                                                    <select-input item-text="text" item-value="value" v-model="step2.late" :field-errors="errors" :label="'التأخير'" :name="'التأخير'" :items="booleanOptionsLate" />
                                                </ValidationProvider>
                                            </div>
                                            <div class="mt-6" style="position: relative">
                                                <ValidationProvider name="الغياب" :rules="rules.step2.absence" v-slot="{ errors }">
                                                    <select-input item-text="text" item-value="value" v-model="step2.absence" :field-errors="errors" :label="'الغياب'" :name="'الغياب'" :items="booleanOptionsLate" />
                                                </ValidationProvider>
                                            </div>
                                            <div class="mt-6" style="position: relative">
                                                <ValidationProvider name="تحويل عدد من التحذيرات إلى إنذار" :rules="rules.step2.warningToIndar" v-slot="{ errors }">
                                                    <select-input item-text="text" item-value="value" v-model="step2.warningToIndar" :field-errors="errors" :label="'تحويل عدد من التحذيرات إلى إنذار'" :name="'تحويل عدد من التحذيرات إلى إنذار'" :items="booleanOptions" />
                                                </ValidationProvider>
                                            </div>
                                            <div v-if="step2.warningToIndar === 1" class="mt-6" style="position: relative">
                                                <ValidationProvider name="كم عدد الإنذارات" :rules="rules.step2.indaratNums" v-slot="{ errors }">
                                                    <input-text field-type="number" :min-val="1" v-model="step2.indaratNums" :field-errors="errors" :label-top="'كم عدد الإنذارات'" :placeholder="'عدد الإنذارات'" />
                                                </ValidationProvider>
                                            </div>
                                            <div class="mt-6" style="position: relative">
                                                <ValidationProvider name="إستبعاد من البرنامج" :rules="rules.step2.excludeFromProgram" v-slot="{ errors }">
                                                    <select-input item-text="text" item-value="value" v-model="step2.excludeFromProgram" :field-errors="errors" :label="'إستبعاد من البرنامج'" :name="'إستبعاد من البرنامج'" :items="excludeFromProgramOptions" />
                                                </ValidationProvider>
                                            </div>
                                            <div v-if="step2.excludeFromProgram === 2" class="mt-6" style="position: relative">
                                                <ValidationProvider name="عند وصول الطالب إلى عدد الإنذارات" :rules="rules.step2.indaratNumsToExclude" v-slot="{ errors }">
                                                    <input-text field-type="number" :min-val="1" v-model="step2.indaratNumsToExclude" :field-errors="errors" :label-top="'عند وصول الطالب إلى عدد الإنذارات'" :placeholder="'عدد الإنذارات'" />
                                                </ValidationProvider>
                                            </div>
                                            <div class="mt-6" style="position: relative">
                                                <ValidationProvider name="التصفير" :rules="rules.step2.resetWarnings" v-slot="{ errors }">
                                                    <select-input item-text="text" item-value="value" v-model="step2.resetWarnings" :field-errors="errors" :label="'التصفير'" :name="'التصفير'" :items="booleanOptions" />
                                                </ValidationProvider>
                                            </div>
                                            <div v-if="step2.resetWarnings === 1" class="mt-6" style="position: relative">
                                                <ValidationProvider name="نحديد المدة" :rules="rules.step2.resetWarningsDuration" v-slot="{ errors }">
                                                    <select-input item-text="text" item-value="value" v-model="step2.resetWarningsDuration" :field-errors="errors" :label="'تحديد المدة'" :name="'تحديد المدة'" :items="durationOptions" />
                                                </ValidationProvider>
                                            </div>

                                            <div class="mt-6" style="position: relative">
                                                <ValidationProvider name="إختيار الفترة" :rules="rules.step2.selectedPath" v-slot="{ errors }">
                                                    <select-input item-text="name" item-value="value" :disabled="!fatarat.length" v-model="step2.selectedPath" :field-errors="errors" :label="'إختيار الفترة'" :name="'إختيار الفترة'" :items="fatarat" />
                                                </ValidationProvider>
                                            </div>
                                            <div class="mt-6">
                                                <ValidationProvider name="إسم التحذير" :rules="rules.step2.pathWarningName" v-slot="{ errors }">
                                                    <input-text v-model="step2.pathWarningName" :field-errors="errors" :label-top="'إسم التحذير'" :placeholder="'أكتب إسم التحذير'" />
                                                </ValidationProvider>
                                            </div>
                                            <div class="mt-6" style="position: relative">
                                                <ValidationProvider name="تحويل عدد من التحذيرات إلى إنذار" :rules="rules.step2.pathWarningToIndar" v-slot="{ errors }">
                                                    <select-input item-text="text" item-value="value" v-model="step2.pathWarningToIndar" :field-errors="errors" :label="'تحويل عدد من التحذيرات إلى إنذار'" :name="'تحويل عدد من التحذيرات إلى إنذار'" :items="booleanOptions" />
                                                </ValidationProvider>
                                            </div>
                                            <div class="mt-6" style="position: relative">
                                                <ValidationProvider name="إستبعاد من البرنامج" :rules="rules.step2.pathExcludeFromProgram" v-slot="{ errors }">
                                                    <select-input item-text="text" item-value="value" v-model="step2.pathExcludeFromProgram" :field-errors="errors" :label="'إستبعاد من البرنامج'" :name="'إستبعاد من البرنامج'" :items="excludeFromProgramOptions" />
                                                </ValidationProvider>
                                            </div>
                                            <div v-if="step2.pathExcludeFromProgram === 1" class="mt-6" style="position: relative">
                                                <ValidationProvider name="كم عدد الإنذارات" :rules="rules.step2.pathIndaratNums" v-slot="{ errors }">
                                                    <input-text field-type="number" :min-val="1" v-model="step2.pathIndaratNums" :field-errors="errors" :label-top="'كم عدد الإنذارات'" :placeholder="'عدد الإنذارات'" />
                                                </ValidationProvider>
                                            </div>
                                            <div v-if="step2.pathExcludeFromProgram === 2" class="mt-6" style="position: relative">
                                                <ValidationProvider name="عند وصول الطالب إلى عدد الإنذارات" :rules="rules.step2.pathIndaratNumsToExclude" v-slot="{ errors }">
                                                    <input-text field-type="number" :min-val="1" v-model="step2.pathIndaratNumsToExclude" :field-errors="errors" :label-top="'عند وصول الطالب إلى عدد الإنذارات'" :placeholder="'عدد الإنذارات'" />
                                                </ValidationProvider>
                                            </div>
                                            <div class="mt-6" style="position: relative">
                                                <ValidationProvider name="التصفير" :rules="rules.step2.pathRresetWarnings" v-slot="{ errors }">
                                                    <select-input item-text="text" item-value="value" v-model="step2.pathRresetWarnings" :field-errors="errors" :label="'التصفير'" :name="'التصفير'" :items="booleanOptions" />
                                                </ValidationProvider>
                                            </div>
                                            <div v-if="step2.pathRresetWarnings === 1" class="mt-6" style="position: relative">
                                                <ValidationProvider name="نحديد المدة" :rules="rules.step2.pathResetWarningsDuration" v-slot="{ errors }">
                                                    <select-input item-text="text" item-value="value" v-model="step2.pathResetWarningsDuration" :field-errors="errors" :label="'تحديد المدة'" :name="'تحديد المدة'" :items="durationOptions" />
                                                </ValidationProvider>
                                            </div>
                                        </div>
                                        <div style="display: flex">
                                            <v-btn class="ml-4" @click="changeTabPrev2()" style="background-color: transparent;color: #00B5AD;border: 1px solid #00B5AD;box-shadow: none">السابق</v-btn>
                                            <v-btn :disabled="invalid2" class="btn-form ml-5" color="#00B5AD" block @click="changeTabNext3()">التالي</v-btn>
                                        </div>
                                    </validation-observer>
                                </v-card-text>
                            </v-tab-item>

                            <v-tab-item value='tab-3'>
                                <v-card-text>
                                    <div class="createQuestion-three">
                                        <v-row>
                                            <v-col v-for="fatra in fatarat" :key="fatra.id" cols="12" lg="4" md="6">
                                                <card-edit-fatra :name="fatra.name" :path_id="path_id" :items-path-array="[]" :path-time-section="true" :text-card="false" :show-btn="false" :avatar-branch="false" :show-edit-delete="true" :check-icon-show="false" :path-line="false" :path-link="'detailsBranches'" :goal-options="step1.selectedBonod" :bonods-options="step1.selectedBonod" />
                                            </v-col>
                                        </v-row>
                                    </div>
                                    <div style="display: flex">
                                        <v-btn class="ml-4 mt-5" @click="changeTabPrev3()" style="background-color: transparent;color: #00B5AD;border: 1px solid #00B5AD;box-shadow: none">السابق</v-btn>
                                        <v-btn class="btn-form mt-5" color="#00B5AD" block @click="changeTabNext4()">التالي</v-btn>
                                    </div>
                                </v-card-text>
                            </v-tab-item>

                            <v-tab-item value='tab-4'>
                                <v-card-text>
                                    <div class="createQuestion-four">
                                        <v-row>
                                            <v-col v-for="bnd in step1.selectedBonod" :key="bnd" cols="12" lg="4" md="6">
                                                <card-edit-path-bnd :name="bnd" :path_id="path_id"  :items-path-array="[]" :path-time-section="true" :text-card="false" :show-btn="false" :avatar-branch="false" :show-edit-delete="true" :check-icon-show="false" :path-line="false" :path-link="'detailsBranches'" :goal-options="step1.selectedBonod" :bonods-options="step1.selectedBonod" />
                                            </v-col>
                                        </v-row>
                                    </div>
                                    <div style="display: flex">
                                        <v-btn class="ml-4 mt-6" @click="changeTabPrev4()" style="background-color: transparent;color: #00B5AD;border: 1px solid #00B5AD;box-shadow: none">السابق</v-btn>
                                        <v-btn class="btn-form mt-6" :disabled="invalid" type="submit" color="#00B5AD" block>اضافة</v-btn>
                                    </div>
                                </v-card-text>
                            </v-tab-item>

                    </validation-observer>
                </v-tabs-items>
            </div>

        </div>
    </v-main>
</section>
</template>

<script>
import { mapGetters } from "vuex";
import SelectInput from "@/components/select-input";
import InputText from "@/components/input-text";
import TextArea from "@/components/textArea";
import CardEditFatra from "@/components/cards/card-edit-fatra";
import CardEditPathBnd from "@/components/cards/card-edit-path-bnd";
import DragAndDropUploader from "@/components/DragAndDropUploader";
import { fetchBranches } from 'Actions';
import {  postMethods } from "Helper";

export default {
    name: "addTracksView",
    components: {
        DragAndDropUploader,
        TextArea,
        InputText,
        SelectInput,
        CardEditFatra,
        CardEditPathBnd,
    },
    data() {
        return {
            submitOnProgress: false,
            createTermApiError: {
                status: false,
                error: {},
            },
            imageStudent: require('@/assets/image/avatar/ebc8fbda-c735-4d9e-a8be-c5329afba775.png'),
            path_id: null,
            goal: null,
            faces: null,
            absentDaysReached: null,
            activeBand: null,
            fatraEndTest: null,
            showFatraEnd: null,
            absentDays: null,
            step1: {
                name: '',
                pathDuration: null,
                conserveParts: [null,2],
                selectedBonod: [1],
                mainBnd: null,
                pathNums: null,
                commitment: ''
            },
            step3: {
                goal: null,
                faces: null,
                absentDaysReached: null,
                activeBand: null,
                fatraEndTest: null,
                showFatraEnd: null,
                absentDays: null
            },
            step2: {
                mistakesRepeatEnabled: null,
                mistakesRepeatNum: null,
                selectedBranch: null,
                warningName: '',
                warningToIndar: null,
                excludeFromProgram: null,
                indaratNums: null,
                indaratNumsToExclude: null,
                resetWarnings: null,
                selectedPath: null,
                pathWarningName: '',
                pathWarningToIndar: null,
                late: null,
                absence: null,
                pathExcludeFromProgram: null,
                pathIndaratNums: null,
                resetWarningsDuration: null,
                pathIndaratNumsToExclude: null,
                pathRresetWarnings: null,
                pathResetWarningsDuration: 1
            },
            step4: {
                pathAmount: null,
                bndAmount: null,
                bndStart: null,
                excute: null,
                bndDegree: null,
                notExcutedWarning: null,
                warning: null,
                nextDay: null,
                daily: null,
            },
            mainbnds:[],
            conservePartsOptions: ["الكل",1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30],
            bonod: [
                {
                    id: 1,
                    value: 1,
                    text:"حفظ"
                },
                {
                    id: 2,
                    value: 2,
                    text:"إتقان"
                },
                {
                    id: 3,
                    value: 3,
                    text:"سماع"
                },
                {
                    id: 4,
                    value: 4,
                    text:"تكرار"
                },
                {
                    id: 5,
                    value: 5,
                    text:"ربط"
                },
                {
                    id: 6,
                    value: 6,
                    text:"مراجعة"
                },
                {
                    id: 7,
                    value: 7,
                    text:"تسميع"
                },
                {
                    id: 8,
                    value: 8,
                    text:"إختبار"
                },
                {
                    id: 9,
                    value: 9,
                    text:"تلاوة"
                },
                {
                    id: 10,
                    value: 10,
                    text:"السرد الأسبوعي"
                },  
            ],
            tab: 1,
            branches: [],
            excludeFromProgramOptions: [
                {
                    id: 1,
                    text: 'نعم',
                    value: 1,
                },
                {
                    id: 2,
                    text: 'لا',
                    value: 0,
                },
                {
                    id: 3,
                    text: 'إيقاف مؤقت',
                    value: 2,
                },
            ],
            absentDaysReachedOptions: [
                {
                    name: 'إنذار',
                    id: 1,
                },
                {
                    name: 'إستبعاد',
                    id: 2,
                },
                {
                    name: 'إعادة',
                    id: 3,
                },
            ],
            durationOptions: [{
                    id: 1,
                    text: 'شهري',
                    value: 1,
                },
                {
                    id: 2,
                    text: 'فصلي',
                    value: 2,
                },
            ],
            items: [
                'الحفظ', 'اتقان', 'سماع', 'تكرار', 'ربط', 'مراجعه', 'تسميع', 'اختبار', 'تلاوة', 'سرد اسبوعي',
            ],
            booleanOptions: [{
                    id: 1,
                    text: 'نعم',
                    value: 1,
                },
                {
                    id: 2,
                    text: 'لا',
                    value: 0,
                },
            ],
            booleanOptionsLate: [{
                    id: 'alert',
                    text: 'تنبيه',
                    value: 'alert',
                },
                {
                    id: 'warning',
                    text: 'انذار',
                    value: 'warning',
                },
                {
                    id: 'nothing',
                    text: 'لا يوجد',
                    value: 'nothing',
                },
            ],
            rules: {
                step1: {
                    name: {
                        required: true,
                    },
                    pathDuration: {
                        required: true,
                    },
                    conserveParts: {
                        required: true,
                    },
                    selectedBonod: {
                        required: true,
                    },
                    mainBnd: {
                        required: true,
                    },
                    pathNums: {
                        required: true,
                    },
                    commitment: {
                        required: true,
                    },
                },
                step2: {
                    mistakesRepeatEnabled: {
                        required: true,
                    },
                    mistakesRepeatNum: {
                        required: true,
                    },
                    selectedBranch: {
                        required: true,
                    },
                    warningName: {
                        required: true,
                    },
                    warningToIndar: {
                        required: true,
                    },
                    late: {
                        required: true,
                    },
                    absence: {
                        required: true,
                    },
                    excludeFromProgram: {
                        required: true,
                    },
                    indaratNums: {
                        required: true,
                    },
                    indaratNumsToExclude: {
                        required: true,
                    },
                    resetWarnings: {
                        required: true,
                    },
                    selectedPath: {
                        required: true,
                    },
                    pathWarningName: {
                        required: true,
                    },
                    pathWarningToIndar: {
                        required: true,
                    },
                  
                    pathExcludeFromProgram: {
                        required: true,
                    },
                    pathIndaratNums: {
                        required: true,
                    },
                    pathIndaratNumsToExclude: {
                        required: true,
                    },
                    pathRresetWarnings: {
                        required: true,
                    },
                    pathResetWarningsDuration: {
                        required: true,
                    },
                },
                step3: {
                    goal: {
                        required: true,
                    },
                    faces: {
                        required: true,
                    },
                    absentDays: {
                        required: true,
                    },
                    absentDaysReached: {
                        required: true,
                    },
                    activeBand: {
                        required: true,
                    },
                    fatraEndTest: {
                        required: true,
                    },
                    showFatraEnd: {
                        required: true,
                    }
                },
                step4: {
                    pathAmount: {
                        required: true,
                    },
                    bndAmount: {
                        required: true,
                    },
                    bndStart: {
                        required: true,
                    },
                    excute: {
                        required: true,
                    },
                    bndDegree: {
                        required: true,
                    },
                    notExcutedWarning: {
                        required: true,
                    },
                    warning: {
                        required: true,
                    },
                    nextDay: {
                        required: true,
                    },
                    daily: {
                        required: true,
                    },
                }
            },
        }
    },
    computed: {
        ...mapGetters('settings', ['allInterview']),
        fatarat() {
            let pathNums = this.step1.pathNums;
            if (!pathNums) return [];
            let fatarat = [];
            for (let i = 0; i < pathNums; i++) {
                fatarat[i] = {
                    id: i + 1,
                    name: 'الفترة' + (i + 1),
                    value: i + 1,
                };
            }

            return fatarat;
        },
       
    },
    methods: {
         getBonods() {
            let bonods = this.step1.selectedBonod;
            this.mainbnds = [];
            for (let i = 0; i < bonods.length; i++) {
                this.mainbnds.push(this.bonod[bonods[i]-1]);
            }
        },
        async changeTabNext2() {
            try{
                let formData = new FormData();
                formData.append("name", this.step1.name);
                formData.append("day_numbers", this.step1.pathDuration);
                formData.append("min_units", this.step1.conserveParts);
                formData.append("duration_numbers", this.step1.pathNums);
                formData.append("text", this.step1.commitment);
                formData.append("band_type_id", this.step1.selectedBonod);
                formData.append("main_band_id", this.step1.mainBnd);
                let res;
                if (this.path_id == null) {   
                    res = await postMethods('paths/step1', formData);
                } else {
                    formData.append("path_id", this.path_id);
                    res = await postMethods('paths/updatestep1', formData);
                }
                console.log(res.data)
                if (res.data.status == 1 && res.data.code == 200) {   
                    this.$toast.success('تم الحفظ بنجاح');
                    this.tab = 'tab-2';
                    this.valueBorder = '67%';
                    this.backgroundColorDote2 = '#00B5AD';
                    this.path_id = res.data.data;
                } else {
                    this.$toast.error(res.data.message);
                }
            } catch (error) {
                if (error.response.status == 400) {
                    this.$toast.error(error.response.data.message)
                } else {
                    this.$toast.error('فشلت العملية');
                }
            } 
        },
        
        async changeTabNext3() {
            try {
                let formData = new FormData();
                formData.append("path_id", this.path_id);
                formData.append("branche_id", this.step2.selectedBranch);
                formData.append("mistake_repeat", this.step2.mistakesRepeatEnabled);
                formData.append("mistake_repeat_number", this.step2.mistakesRepeatNum);
                formData.append("warning_name", this.step2.warningName);
                formData.append("transfer_to_yello", this.step2.warningToIndar);
                formData.append("late", this.step2.late);
                formData.append("absence", this.step2.absence);
                formData.append("repeat_mistakes", this.step2.mistakesRepeatEnabled);
                formData.append("num_mistakes", this.step2.mistakesRepeatNum);
                formData.append("program_out", this.step2.excludeFromProgram);
                if (this.step2.excludeFromProgram == 1) {
                    formData.append("program_out_yellow_card_number", this.step2.indaratNums);
                }
                formData.append("reset", this.step2.resetWarnings);
                if (this.resetWarnings == 1) {   
                    formData.append("reset_duration", this.step2.resetWarningsDuration);
                }
                formData.append("duration_id", this.step2.selectedPath);
                formData.append("duration_warning_name", this.step2.pathWarningName);
                formData.append("duration_transfer_to_yello", this.step2.pathWarningToIndar);
                formData.append("duration_program_out", this.step2.pathExcludeFromProgram);
                if (this.step2.pathExcludeFromProgram == 1) {
                    formData.append("duration_program_out_yellow_card_number", this.step2.pathIndaratNums);
                }
                
                formData.append("duration_reset", this.step2.pathRresetWarnings);
                if (this.step2.pathRresetWarnings == 1) {
                    formData.append("duration_reset_duration", this.step2.pathResetWarningsDuration);
                }
                let res = await postMethods('paths/step2', formData);
                console.log(res.data)
                if (res.data.status == 1 && res.data.code == 200) {
                    this.$toast.success('تم الحفظ بنجاح');
                    this.tab = 'tab-3';
                    this.valueBorder = '90%';
                    this.backgroundColorDote3 = '#00B5AD';
                } else {
                    this.$toast.error(res.data.message);
                }
            } catch (error) {
                if (error.response.status == 400) {
                    this.$toast.error(error.response.data.message)
                } else {
                    this.$toast.error('فشلت العملية');
                }
            }


        },
      changeTabNext4() {
            this.addTrack();

            this.tab = 'tab-4'
            this.valueBorder = '100%'
            this.backgroundColorDote4 = '#00B5AD'
        },
      changeTabPrev2() {

            this.tab = 'tab-1'
            this.valueBorder = '40%'
            this.backgroundColorDote2 = '#E6E6E6'
        },
        changeTabPrev3() {
            this.tab = 'tab-2'
            this.valueBorder = '67%'
            this.backgroundColorDote3 = '#E6E6E6'
        },
        changeTabPrev4() {
            this.tab = 'tab-3'
            this.valueBorder = '90%'
            this.backgroundColorDote4 = '#E6E6E6'
        },
        async getBranches() {
            let res = await fetchBranches();
            if (res.data.status) {
                this.branches = res.data.data;
            }
        },
        async addTrack() {
            // this.submitOnProgress = true;

            // this.createItemApiError = {
            //   status: false,
            //   error: {},
            // };
          let formData = new FormData();
    
            formData.append("name", this.step1.name);
            formData.append("day_numbers", this.step1.pathDuration);
            formData.append("min_units", this.step1.conserveParts);
            formData.append("duration_numbers", this.step1.pathNums);
            formData.append("text", this.step1.commitment);
            formData.append("band_type_id", this.step1.selectedBonod);
            formData.append("main_band_id", this.step1.mainBnd);
            formData.append("branche_id", this.step2.selectedBranch);
            formData.append("goal", this.step3.goal);
            // formData.append("duration.*.name", this.duration.*.name);
            // formData.append("duration.*.duration_numbers", this.duration.*.duration_numbers);
            // formData.append("duration.*.duration_goal", this.duration.*.duration_goal);
            // formData.append("duration.*.duration_value", this.duration.*.duration_value);
            // formData.append("duration.*.absence_days", this.duration.*.absence_days);
            // formData.append("duration.*.active_band_id", this.duration.*.active_band_id);
            // formData.append("duration.*.end_duration_exam", this.duration.*.end_duration_exam);
            // formData.append("duration.*.end_duration_show", this.duration.*.end_duration_show);          
            // formData.append("band.*.band_type_id", this.band.*.band_type_id);
            // formData.append("band.*.duration_id", this.band.*.duration_id);
            // formData.append("band.*.band_value", this.band.*.band_value);
            // formData.append("band.*.band_start", this.band.*.band_start);
            // formData.append("band.*.active_band_id", this.band.*.active_band_id);
            // formData.append("band.*.after_band_value_done", this.band.*.after_band_value_done);
            // formData.append("band.*.band_grade", this.band.*.band_grade);
            // formData.append("band.*.not_implemented", this.band.*.not_implemented);
            // formData.append("band.*.yellow_card", this.band.*.yellow_card);
            // formData.append("band.*.next_day_value_add", this.band.*.next_day_value_add);
            // formData.append("band.*.absence_days_numbers", this.band.*.absence_days_numbers);
            // formData.append("band.*.daily_band_value", this.band.*.daily_band_value);
            // try {
                let res = await postMethods('paths', formData);
                if (res.data.status) {
                  this.$toast.success('تم الحفظ بنجاح');
                } else {
                    this.$toast.error(res.data.message);
                    // this.createItemApiError.status = true;
                    // this.createItemApiError.error.message = res.data.message;
                }
            // } catch (error) {
            //     this.$toast.error('فشلت العملية');

            //     this.createItemApiError.status = true;
            //     this.createItemApiError.error.message = error.response ? error.response.data : error;
            // } finally {
            //     this.submitOnProgress = false;
            // }
            // this.submitOnProgress = false;

        },
        setup() {
            this.getBranches();
        }
    },
    beforeMount() {
        this.$store.dispatch('settings/pageTitle', 'المسارات')
        this.$store.dispatch('settings/subTitleTop', ['إضافة مسار'])
        this.$store.dispatch('settings/showTopTitle')
    },
    created() {
        this.setup();
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/variable.scss";

.step-by-step-Tracks {
    .dote {
        position: absolute;
        bottom: -27%;
        z-index: 1;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all .2s ease-in-out;

        i {
            font-size: 19px;
            color: white;
            margin-left: 1px;
        }
    }

    .dote-one {
        background-color: #00B5AD;
    }

    .dote-tow {
        background-color: v-bind('backgroundColorDote2');
    }

    .dote-three {
        background-color: v-bind('backgroundColorDote3');
    }

    .dote-four {
        background-color: v-bind('backgroundColorDote4');
    }

    .btn-form {
        color: white;
    }
}

.add-tracks {
    background-color: $background-main-page;
    padding: 40px 15px;

    .date-customer-component label {
        right: 15%;
    }
}

.uploadFile {
    input {
        display: none;
    }

    label {
        cursor: pointer;
        padding: 8px 8px;
        color: #00B5AD;
        font-size: 14px;
        background-color: white;
        border-radius: 5px;
        box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.15);
    }
}
</style><style lang="scss">
.step-by-step-Tracks {
    .v-sheet.v-card:not(.v-sheet--outlined) {
        box-shadow: none;
    }

    .v-tabs-slider-wrapper {
        color: #00B5AD;
    }

    .v-slide-group__content {
        border-bottom: 1.5px solid rgba(180, 180, 180, 0.47);
    }

    .v-slide-group__content:before {
        content: '';
        width: v-bind('valueBorder');
        height: 3px;
        background-color: #00B5AD;
        position: absolute;
        bottom: -4%;
        transition: all .2s ease-in-out;
    }

    .v-slide-group__wrapper {
        position: relative;
        overflow: inherit !important;
        contain: inherit;
    }

    .v-tabs-slider {
        display: none;
    }

    .v-tab--active,
    .v-tab {
        font-size: 14px !important;
        padding-bottom: 20px;
        font-weight: bold !important;
    }
}

.add-tracks {
    label {
        color: rgba(0, 0, 0, 0.6);
        font-size: 13px !important;
        right: 1%;
        z-index: 1;
    }

    .date-customer-component label {
        right: 1%;
        z-index: 1;
    }

    .select-component label {
        top: -12px;
        right: 1%;
        z-index: 1;
    }

    .v-input__slot input {
        padding-right: 12px;
    }

    .v-input__prepend-outer {
        left: 1.5% !important;
    }

    .v-text-field__slot .v-label {
        left: auto !important;
        color: #CBCACA;
    }

    .date-customer-component .v-text-field__slot {
        padding-bottom: 0 !important;
    }

    .v-slide-group__content {
        transform: initial !important;
    }

    .select-component label {
        right: 12px !important;
    }
}
</style>
