<template>
  <div class="card-registrationProgram">
    <div class="name-student">
      <div class="image">
        <img :src="photo" alt="">
      </div>
      <div class="name">
        <p>{{ name }}</p>
        <span>{{ className }}</span>
      </div>
    </div>

    <div class="information-student">
      <v-row>
        <v-col cols="6" >
          <div class="path">
            <div class="image">
              <img :src="require('@/assets/image/informationStudent/Repeat Grid 9.png')">
            </div>
            <div class="text-path">
              <p>{{ email }}</p>
            </div>
          </div>
        </v-col>
        <v-col cols="6" >
          <div class="path">
            <div class="image">
              <img :src="require('@/assets/image/informationStudent/Repeat Grid 6.png')">
            </div>
            <div class="text-path">
              <p>{{ national_id }}</p>
            </div>
          </div>
        </v-col>
        <div class=" last-of-type" style="width: 100% "></div>
      </v-row>
    </div>

    <div class="path-time">
      <v-row>
       
        <v-col cols="6">
          <div class="path">
            <div class="image">
              <img :src="require('@/assets/image/icon/Group 77770.png')">
            </div>
            <div class="text-path">
              <p>تاريخ البداية</p>
              <span>{{ student.start_date }}</span>
            </div>
          </div>
        </v-col>
        <v-col cols="6">
          <div class="path">
            <div class="image">
              <img :src="require('@/assets/image/icon/Group 77770.png')">
            </div>
            <div class="text-path">
              <p>أيام التسميع</p>
              <span>{{ student.conserve_days }}</span>
            </div>
          </div>
        </v-col>
        <v-col cols="6">
          <div class="path">
            <div class="image">
              <img :src="require('@/assets/image/icon/Group 78359.png')">
            </div>
            <div class="text-path">
              <p>نوع البرنامج</p>
              <span>{{ student.program_type }}</span>
            </div>
          </div>
        </v-col>
        <v-col cols="6">
          <div class="path">
            <div class="image">
              <img :src="require('@/assets/image/icon/Color.png')">
            </div>
            <div class="text-path">
              <p>المسار</p>
              <span>{{ student.path }}</span>
            </div>
          </div>
        </v-col>
        <div class="last-of-type" style="width: 100%"></div>
      </v-row>
    </div>
    <slot></slot>
    <div class="btn-done">
      <v-row>
      
         <v-col cols="12" lg="3" v-if="status == 0">
          <v-btn @click="openConfirmRequest()"  class="mt-3 mb-4 accept-btn" >
            <span>قبول </span>
          </v-btn>
        </v-col>
        
        <v-col cols="12" lg="12" v-if="status == 1" class="alert-success">مقبول</v-col>
        <v-col cols="12" lg="12" v-if="status == 2" class="alert-danger">مرفوض</v-col>
        <v-col cols="12" lg="12" v-if="status == 3" class="alert-info">تم تحديدمقابلة</v-col>
        
        <v-col cols="12" lg="3" v-if="status == 0">
            <v-btn :color-value="'transparent'" style="color: black;width:100%"  @click="openRejectRequest()"  class="btn-reject mt-3 mb-4  btn-submit " >
              <span>رفض </span>
            </v-btn>
        </v-col>
          <v-col cols="12" lg="6" v-if="status == 0">
          <dialog-modal :name-input="'تحديد موعد مقابلة'" :title="'تحديد موعد مقابلة'">
            <div class="form-modal">
              <validation-observer  v-slot="{ invalid, handleSubmit }" >
                <v-form @submit.prevent="handleSubmit(addInterview)">
                  <v-row>
                    <v-col cols="12" lg="6" md="6">
                      <ValidationProvider name="اسم الطالب" >
                        <input-text :disabled="true" v-model="student_name"  :label-top="'الطالب'" :placeholder="'الطالب'"/>
                      </ValidationProvider>                          
                    </v-col>
                    <v-col cols="12" lg="6" md="6">
                        <ValidationProvider name="نموذج اسئلة المقابلة" :rules="rules.template_id" v-slot="{ errors }">
                              <select-input :label="'نموذج اسئلة المقابلة'" :fieldErrors="errors"  v-model="template_id" :name="'أختر نموذج اسئلة المقابلة'"   item-text="name"
                                        item-value="id" :items="templates"/>
                                      </ValidationProvider>
                            </v-col>
                    <v-col cols="12" lg="6" md="6">
                      <ValidationProvider name="تاريخ المقابلة" :rules="rules.appointment_date" v-slot="{ errors }">
                      <input-text  field-type="date" v-model="appointment_date" :fieldErrors="errors" :label-top="'تاريخ المقابلة'" :placeholder="'تاريخ المقابلة'"/>
                      </ValidationProvider>
                        
                    </v-col>
                    <v-col cols="12" lg="6" md="6">
                      <ValidationProvider name="وقت المقابلة" :rules="rules.conserve_time" v-slot="{ errors }">
                        <input-text  field-type="time" v-model="conserve_time" :fieldErrors="errors" :label-top="'وقت المقابلة'" :placeholder="'وقت المقابلة'"/>
                      </ValidationProvider>                          
                    </v-col>
                    <v-col cols="12" lg="6" md="6">
                            <select-input :label="'كيفية التسميع'" v-model="type"  :name="'أونلاين'" item-value="id" item-text="text" :items="types"/>
                          </v-col>

                    <v-col cols="12">
                      <v-btn block color="#00B5AD" :disabled="invalid" type="submit" style="color: white;font-size: 15px;height: 45px">اضافة</v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </validation-observer>
            </div>
          </dialog-modal>
        </v-col>
      </v-row>

    </div>
      <confirmation-dialog ref="confirmItemConfirmationDialog" heading="تأكيد عملية القبول " message='سيم قبول الطلب' @onConfirm="confirmItem"></confirmation-dialog>
      <confirmation-dialog ref="rejectItemConfirmationDialog" heading="تأكيد عملية الرفض " message='سيم رفض الطلب' @onConfirm="rejectItem"></confirmation-dialog>


    <!--    <div v-show="showEditDelete" class="edit-delete">-->
    <!--      <v-btn disabled class="edit">-->
    <!--        <span>تعديل </span>-->
    <!--        <i class="fa fa-pencil"></i>-->
    <!--      </v-btn>-->
    <!--      <div class="border"></div>-->

    <!--      <v-btn disabled class="delete">-->
    <!--        <span>حذف </span>-->
    <!--        <i class="fa fa-trash-o"></i>-->
    <!--      </v-btn>-->
    <!--    </div>-->
  </div>
</template>

<script>
// import BtnSubmit from "@/components/btnSubmit";
import { confirmRegister, rejectRegister } from 'Actions';
import DialogModal from "@/components/dialogModal";
import InputText from "@/components/input-text";
import { postMethods } from '@/api/Helper';
import SelectInput from "@/components/select-input";
import { listInterviewTemplates } from '@/api/Actions';


export default {
  name: "card-registrationProgram",
  components: { DialogModal,InputText,SelectInput },
  props: {
    name: {
      type: String
    },
    photo: {
      type: String,
      default: require('@/assets/image/avatar/ebc8fbda-c735-4d9e-a8be-c5329afba775.png')
    },
    phone: {
      type: String
    },
    national_id: {
      type: String
    },
    email: {
      type: String
    },
    className: {
      type: String
    },
    statusStudent: {
      type: String
    },
    popstatus: {
      type: Number
    },
    student: {
      type: Object
    },
    informationStudentArray: {
      type: Array
    },
    idCardStudent: {
      type: Number
    },
    // showEditDelete: {
    //   type: Boolean,
    //   default: false
    // },

    nameBtn: {
      type: String,
      default: 'تسميع'
    }
  },
  data() {
    return {
      photos: require('@/assets/image/avatar/ebc8fbda-c735-4d9e-a8be-c5329afba775.png'),
      status:0,
      appointment_date:null,
      conserve_time: null,
            templates: [],
      template_id: null,
      
      student_name:this.name,
      types: [
        {
          id:'online',
          text:'اونلاين ',
        },
        {
          id:'in_location',
          text:'حضورية',
        }
      ],
      type:null,
      rules: {
        conserve_time: {
          required: true,
        },
        appointment_date: {
          required: true,
        },
        template_id: {
          required: true,
        },
        type: {
          required: true,
        },

      }
    }
  },
  methods: {
    openConfirmRequest() {
      this.$refs.confirmItemConfirmationDialog.openDialog();
    },
     async getTemplate() {
      try {
        let res = await listInterviewTemplates('interview')
        this.templates = res.data.data;
      } catch (error) {
        console.log(error)
      }
    },
    async addInterview() {
      try {
        let formData = new FormData();
        formData.append("student_id", this.student.id);
        formData.append("template_id", this.template_id);
        formData.append("appointment_date", this.appointment_date);
        formData.append("time", this.conserve_time);
        formData.append("type", this.type);
        let res = await postMethods('meeting-appointments',formData);
        if (res.data.status) {
          this.$toast.success("تمت العملية بنجاح");
          window.location.reload()

        } else {
          this.$toast.error(res.data.message);
        }
      } catch (error) {
        this.$toast.error('فشلت العملية');
      } 
    },
    openRejectRequest() {
      this.$refs.rejectItemConfirmationDialog.openDialog();
    },
    async confirmItem() {
      this.$refs.confirmItemConfirmationDialog.close();
      try {
        let res = await confirmRegister(this.idCardStudent);
        if (res.data.status) {
          this.$toast.success('تمت العملية بنجاح');
          this.status = res.data.data.status;
          this.$router.push({ name: 'registrationProgram' });
        } else {
          this.$toast.error(res.data.message);
      }
      } catch (error) {
        this.$toast.error("حدث خطأ ما");
      }
    },
    async rejectItem() {
      this.$refs.rejectItemConfirmationDialog.close();
      try {
        let res = await rejectRegister(this.idCardStudent);
        if (res.data.status) {
          this.$toast.success('تمت العملية بنجاح');
          this.status = res.data.data.status;
          this.$router.push({ name: 'registrationProgram' });
        } else {
          this.$toast.error(res.data.message);
        }
      } catch (error) {
        this.$toast.error("حدث خطأ ما");
      }
    },
    setup() {
      this.status=this.popstatus
    },
  },
  created() {
    this.setup();
    this.getTemplate();
  }
}
</script>


<style lang="scss" scoped>
.card-registrationProgram {
  border: 1px solid #D2D5E1;
  border-radius: 5px;
  padding: 20px 10px 0px 10px;

  .name-student {
    display: flex;
    justify-content: right;
    margin-bottom: 15px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(143, 146, 161, 0.26);

    .image {
      width: 70px;
      padding-top: 6px;

      img {
        width: 100%;
      }
    }

    .name {
      width: 100%;
      position: relative;
      margin-right: 10px;


      p {
        font-size: 15px;
        font-weight: bold;
        margin-bottom: 0;
      }

      span {
        font-size: 13px;
        color: #B4B4B4;
      }
    }
  }

  .status-student {
    position: absolute;
    left: 2%;
    top: -2%;
    background-color: #DBF7F0;
    text-align: center;
    color: #1FD0A3;
    font-size: 11px;
    padding: 10px 15px;
    border-radius: 10px;
    margin-bottom: 30px;
    margin-right: 10px;
  }

  .information-student {
    display: flex;
    justify-content: space-around;
    padding: 15px 10px;


    .col {
      padding-right: 2px;
      padding-left: 2px
    }

    .path {
      display: flex;

      .image {
        border-radius: 50%;
        min-width: 30px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 100%;
        }
      }

      .text-path {
        margin-right: 5px;
        width: 100%;

        p {
          margin-bottom: 0;
          color: #0F1222;
          font-size: 13.5px;
          font-weight: bold;
        }

        span {
          color: #B4B4B4;
          font-size: 13px;
        }
      }
    }
  }

  .path-time {
    display: flex;
    justify-content: space-around;
    padding: 15px 10px;


    .col {
      padding-right: 2px;
      padding-left: 2px
    }

    .path {
      display: flex;

      .image {
        background-color: rgba(56, 91, 114, 0.2);
        border-radius: 50%;
        width: 40px;
        height: 40px;
        min-width: 40px;
        max-height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 45%;
        }
      }

      .text-path {
        margin-right: 8px;

        p {
          margin-bottom: 0;
          color: #0F1222;
          font-size: 14px;
          font-weight: bold;
        }

        span {
          color: #B4B4B4;
          font-size: 13px;
        }
      }
    }
  }

  .last-of-type {
    border-bottom: 1px solid rgba(143, 146, 161, 0.26);
    padding-top: 15px;
  }


  .edit-delete {
    display: flex;
    justify-content: space-around;
    color: #B4B4B4;
    padding-left: 5px;
    padding-right: 5px;
    cursor: pointer !important;

    .edit,
    .delete {
      padding-top: 20px;
      padding-bottom: 25px;

    }

    .delete {
      i {
        padding-left: 3px;
      }
    }

    .theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
      background-color: transparent !important;
    }

    i {
      font-size: 15px;
      margin-right: 10px;
      margin-top: 5px;
      background-color: #FAFCFE;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      border: 1px solid #D2D5E1;
    }

    i.fa-pencil {
      padding-left: 5px;
    }

    .border {
      border-right: 1px solid rgba(143, 146, 161, 0.26);
      padding: 0;
    }

    span {
      font-size: 15px;
    }

  }


}
</style>
<style lang="scss">
.card-registrationProgram {
  .btn-reject {
    .btn-submit-component .btn-submit {
      color: #b50000 !important;
      box-shadow: none;
      border: 1px solid #b50000 !important;
    }
  }
  .btn-interview {
    .btn-submit-component .btn-submit {
      color: #00B5AD !important;
      box-shadow: none;
      font-size: 10px;
      border: 1px solid #00B5AD !important;
    }
  }
  .accept-btn {
      color: #00B5AD !important;
      box-shadow: none;
      padding:10px;
      width: 100%;
      border: 1px solid #00B5AD !important;
  }
}
.alert-success{
  width:100%;
  text-align:center;
  background: aliceblue;

}
.alert-danger{
  width:100%;
  text-align:center;
  background: #b50000d4;
  color:white
}
.alert-info{
  width:100%;
  text-align:center;
  background: #77e4dbd4;
  color:white
}
.btn-submit {
      color: #b50000 !important;
      box-shadow: none;
      border: 1px solid #b50000 !important;
    }
</style>
