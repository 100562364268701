<template>
  <section class="classes-student">
    <v-main>
      <div class="container--fluid" style="border-radius: 15px; background-color: white; padding: 15px">
        <div class="all-card">
          <div class="top-bar-search">
            <div class="search-section">
              <form>
                <div class="search">
                  <v-row>
                    <v-col cols="12" lg="5" style="padding-left: 4px">
                      <search-input @search-input-updated="searchChanged" :style="styleSearch">
                      </search-input>
                    </v-col>
                    <v-col cols="12" lg="7">
                      <div class="date-top" >
                        <div class="container-date">
                          <date-select-modal @date-changed="startDateChanged"/>
                        </div>
                        <div class="container-date">
                          <date-select-modal @date-changed="endDateChanged"/>
                        </div>
                        <!-- <div class="input-search">
                          <btn-search/>
                        </div> -->
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </form>
            </div>
            <div class="import-export-file">
              <div style="display: flex">
                <div class="export-file">
                  <import-file :icon="'fa fa-upload'" :name="'رفع ملف اكسل'"/>
                </div>
                <div class="import-file">
                  <import-file :icon="'fa fa-cloud-download'" :name="'تصدير الملف'"/>
                </div>
              </div>
              <div class="add-new">
                <dialog-modal :name-input="'إضافة فصل جديدة'" :title="'إضافة فصل جديدة'">
                  <div class="form-modal">
                    <validation-observer ref="form" v-slot="{ invalid, handleSubmit }" >
                      <v-form @submit.prevent="handleSubmit(createTerm)" :disabled="submitOnProgress">
                        <v-row>
                          <v-col cols="12">
                            <ValidationProvider name="الإسم" :rules="rules.name" v-slot="{ errors }">
                              <input-text v-model="name" :fieldErrors="errors" :label-top="'أكتب هنا إسم الفصل'" :placeholder="'أكتب هنا إسم الفصل'"/>
                            </ValidationProvider>
                          </v-col>
                          <v-col cols="12" lg="6" md="6">
                              <ValidationProvider name="تاريخ البداية" :rules="rules.startDate" v-slot="{ errors }">
                                <date-customer v-model="startDate" :field-errors="errors" @data-entered="startDate = $event" :label="'تاريخ البداية'" :name-placeholder="'تاريخ البداية'"/>
                              </ValidationProvider>
                          </v-col>
                          <v-col cols="12" lg="6" md="6">
                            <ValidationProvider name="تاريخ النهاية" :rules="rules.endDate" v-slot="{ errors }">
                              <date-customer v-model="endDate" :field-errors="errors" @data-entered="endDate = $event" :label="'تاريخ النهاية'" :name-placeholder="'تاريخ النهاية'"/>
                            </ValidationProvider>
                          </v-col>
                          <v-col cols="12" lg="6" md="6">
                            <ValidationProvider name="بداية اليوم" :rules="rules.startDay" v-slot="{ errors }">
                              <input-text  v-model="startDay" :field-errors="errors" :label-top="'بداية اليوم '" :placeholder="'بداية اليوم '" :items="items"/>
                            </ValidationProvider>
                          </v-col>
                          <v-col cols="12" lg="6" md="6">
                            <ValidationProvider name="إختبار نهاية الفصل" :rules="rules.termFinalExam" v-slot="{ errors }">
                              <select-input item-text="text" item-value="value" v-model="termFinalExam"  :field-errors="errors" :label="'اختبار نهاية الفصل '" :name="'نعم/لا'" :items="booleanOptions"/>
                            </ValidationProvider>
                          </v-col>
                          <v-col cols="12" lg="6" md="6">
                            <ValidationProvider name=" عرض نهاية الفصل" :rules="rules.showEndTerm" v-slot="{ errors }">
                              <select-input item-text="text" item-value="value" v-model="showEndTerm"  :field-errors="errors"  :label="'عرض نهاية الفصل'" :name="'نعم/لا'" :items="booleanOptions"/>
                            </ValidationProvider>
                          </v-col>
                          <v-col cols="12" lg="6" md="6">
                             <ValidationProvider name="حالة الفصل" :rules="rules.status" v-slot="{ errors }">
                              <select-input item-text="text" item-value="value" v-model="status" :field-errors="errors" :label="'حالة الفصل'" :name="'فعال / غير فعال'" :items="booleanOptions"/>
                            </ValidationProvider>
                          </v-col>
                          <v-col cols="12">
                            <v-btn type="submit" :disabled="invalid" block color="#00B5AD" style="color: white;font-size: 15px;height: 45px">اضافة</v-btn>
                          </v-col>
                        </v-row>
                      </v-form>
                    </validation-observer>
                  </div>
                </dialog-modal>
              </div>
            </div>
          </div>
          <v-row>
            <v-col cols="12">
            <v-data-iterator
                  :items="terms"
                  no-results-text="0 نتائج مطابقة"
                  :search="search"
                  hide-default-footer
                  :page.sync="page"
                  :items-per-page.sync="itemsPerPage"
                  @page-count="pageCount = $event"
                  class="mt-6"
                  :loading="loadingTermsStatus"
              >
                <template v-slot:default="props">
                  <v-row>
                    <v-col v-for="term in props.items" :key="term.id" cols="12" lg="4" md="6">
                      <card-terms 
                        :term="term"
                        @edit-btn-clicked="openEditTerm(term)"
                        @delete-btn-clicked="openDeleteTerm(term.id)" 
                      />
                    </v-col>
                  </v-row>
                </template>
                 <!-- DataTable text to show when table is empty -->
                <template v-slot:no-data>
                    <div class="d-flex justify-center align-center">
                        <span>لا يوجد أي فصول</span>
                    </div>
                </template>

                <!-- DataTable custom footer -->
                <template v-slot:[`footer.page-text`]="{ pageStart, pageStop, itemsLength }">
                    <span>{{ pageStart }}-{{ pageStop }} من {{ itemsLength }}</span>
                </template>

                <template v-slot:loading>
                    <div class="d-flex justify-center align-center">
                        <v-progress-circular
                            color="primary"
                            indeterminate
                        ></v-progress-circular>
                    </div>
                </template>

            </v-data-iterator>
            </v-col>
             <v-col cols="12">
                <v-pagination
                    v-if="pageCount > 1"
                    v-model="page"
                    :length="pageCount"
                    class="mt-5"
                    :total-visible="10"
                ></v-pagination>
              </v-col>

          </v-row>
        </div>
        <!-- <pagination-components/> -->
      </div>
    </v-main>

    <confirmation-dialog ref="deleteTermConfirmationDialog" heading="تأكيد عملية حذف الفصل" message='لن تتمكن من استرجاعه مجدداً' @onConfirm="deleteTerm"></confirmation-dialog>
    <edit-term v-if="editTermDialogStatus" :dialog-status="editTermDialogStatus" @close-edit-term="closeEditTermDialog" @branch-updated="termUpdated" :term="term2edit"></edit-term>

  </section>
</template>

<script>

import SearchInput from "@/components/search-input";
import DateSelectModal from "@/components/dashboard/dateSelectModal";
import ImportFile from "@/components/import-file";
import DialogModal from "@/components/dialogModal";
import SelectInput from "@/components/select-input";
import {mapGetters} from "vuex";
import DateCustomer from "@/components/date-customer";
import cardTerms from "@/components/cards/card-terms";
import InputText from "@/components/input-text";
import { deleteTerm } from "Actions";
import { getMethods, postMethods } from "Helper";
import EditTerm from './EditTerm.vue';

export default {
  name: "classesStudentView",
  components: {
    InputText,
    cardTerms,
    DateCustomer,
    SelectInput,
    EditTerm,
    DialogModal, ImportFile, DateSelectModal, SearchInput, //PaginationComponents
  },
  data() {
    return {
      name: '',
      startDate: null,
      endDate: null,
      termFinalExam: 0,
      status: 0,
      showEndTerm: 0,
      startDay: null,
      items: [
        'ربط ', 'مراجعه', 'تكرار',
      ],
      styleSearch: {
        backgroundColor: 'transparent',
        border: "1px solid #E2E2EA",
        borderRadius: "10px",

      },
      terms: [],
      itemsPerPage: 8,
      page: 1,
      pageCount: 0,
      search: '',
      loadingTermsStatus: true,
      fetchTermsApiError: {
          status: false,
          error: {},
      },
      submitOnProgress: false,
      createTermApiError: {
          status: false,
          error: {},
      },
      term2edit: null,
      term2delete: null,
      editTermDialogStatus: false,
      booleanOptions: [
        {
          id: 1,
          text: 'نعم',
          value: 1,
        },
        {
          id: 2,
          text: 'لا',
          value: 0,
        },
      ],
      rules: {
        name: {
          required: true,
        },
        startDate: {
          required: true,
        },
        endDate: {
          required: true,
        },
        status: {
          required: true,
        },
        showEndTerm: {
          required: true,
        },
        termFinalExam: {
          required: true,
        },
        startDay: {
          required: true,
        },
      }
    }
  },
  computed: {
    ...mapGetters('settings', ['allInterview'])
  },
  methods: {
      async getItems() {
      try {
        let res = await getMethods('terms')
        
        this.terms = res.data.data.data;
        this.pageCount=res.data.data.meta.lastPage
      } catch (error) {
        console.log(error)
      }
    },


    async createTerm() {
      this.submitOnProgress = true;

      this.createTermApiError = {
          status: false,
          error: {},
      };
      let formData = new FormData();
      formData.append("name", this.name);
      formData.append("start_date", this.startDate);
      formData.append("end_date", this.endDate);
      formData.append("day_start_hour", this.startDay);
      formData.append("end_term_exam", this.termFinalExam);
      formData.append("end_term_show", this.showEndTerm);
      formData.append("is_active", this.status);
      formData.append("company_id", this.status);
      try {
          let res = await postMethods('terms',formData);
          if (res.data.status) {
            this.resetFields();
            this.termUpdated();
          } else {
            this.$toast.error(res.data.message);
              this.createTermApiError.status = true;
              this.createTermApiError.error.message = res.data.message;
          }
      } catch (error) {
        this.$toast.error('فشلت العملية');
            console.log(error.response.data);
        
          this.createTermApiError.status = true;
          this.createTermApiError.error.message = error.response ? error.response.data : error;
      } finally {
          this.submitOnProgress = false;
      }
    },
    openEditTerm(term) {
      this.term2edit = term;
      this.editTermDialogStatus = true;
    },
    openDeleteTerm(term) {
      this.term2delete = term;
      this.$refs.deleteTermConfirmationDialog.openDialog();
    },
    async deleteTerm() {

        this.$refs.deleteTermConfirmationDialog.close();

        try {
            let res = await deleteTerm(this.term2delete);
            if (res.data.status) {
                this.term2delete = null;
                this.$toast.success('تمت العملية بنجاح');
                this.getItems();
            } else {
                this.$toast.error(res.data.message);
            }
        } catch (error) {
            this.$toast.error("حدث خطأ ما");
        }
    },
    termUpdated() {
      this.closeEditTermDialog();
      this.$toast.success('تمت العملية بنجاح');
      this.getItems();
    },
    closeEditTermDialog() {
      this.editTermDialogStatus = false;
      this.term2edit = null;
    },
    resetFields() {
      this.name = "";
      this.startDate = this.endDate = this.startDay = null;
      this.showEndTerm = this.status = this.termFinalExam = 0;
      this.$nextTick(() => {
        this.$refs.form.reset();
      });
    },
    searchChanged(val) {
      this.search = val.data
    },
    startDateChanged(val) {
      this.startDate = val;
      this.getItems();
    },
    endDateChanged(val) {
      this.endDate = val;
      this.getItems();
    },
    setup() {
      this.getItems();
    },
  },
  beforeMount() {
    this.$store.dispatch('settings/pageTitle', 'الفصول')
  },
  created() {
    this.setup();
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/variable.scss";

.classes-student {
  background-color: $background-main-page;
  padding: 40px 15px;

  .top-bar-search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    @media only screen and (max-width: 1250px) {
      flex-direction: column;
    }

    .search-section {
      width: 55%;
      @media only screen and (max-width: 1250px) {
        width: 100%;
      }
      .date-top {
        display: flex;
        align-items: center;
        @media only screen and (max-width: 1250px) {
          .container-date {
            width: 33%;

            .theme--light.v-input {
              width: 100%;
            }
          }
          .input-search {
            width: 33%;
          }
        }
      }
      .search {
        display: flex;
        align-items: center;
        padding: 12px 0;

        button {
          border: 1px solid #00B5AD;
          padding: 10px 40px;
          font-size: 14px;
          border-radius: 10px;
          color: #00B5AD;
          margin-right: 10px;
        }

      }
    }

  }

  .import-export-file {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    @media only screen and (max-width: 1250px) {
      width: 100%;
      div {
        width: 50%;

        label {
          width: 100%;
          display: inline-block;
        }
      }
      .export-file, .import-file {
        margin-top: 8px;
        text-align: center;
      }
      .text-center {
        width: 100%;
      }
    }
    @media only screen and (max-width: 800px) {
      width: 100%;
      display: unset;
      div {
        width: 100%;
      }
    }

    div {
      margin: 0 5px;
      @media only screen and (max-width: 800px) {
        margin: 0 0px;
        padding: 0 5px;
      }
    }
  }

}

</style>
<style lang="scss">
.theme--light.v-text-field > .v-input__control > .v-input__slot:before {
  display: none !important;
  border: none !important;
}

.classes-student {
  .theme--light.v-input input, .theme--light.v-input textarea {
    font-size: 14px !important;
    font-weight: bold !important;
    color: #707070 !important;
    margin-top: 5px;
  }

  .v-input__icon i {
    font-size: 14px !important;
    font-weight: normal !important;
    color: #707070 !important;
  }

  .v-text-field__details {
    display: none;
  }

  .v-input__slot:focus .v-text-field__details {
    border: none !important;
    display: none;

  }

  .v-input__slot:active .v-text-field__details {
    border: none !important;
    display: none;

  }

  .theme--light.v-input {
    margin: 0px 10px 0px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #D2D5E1;
    border-radius: 5px;
  }

  .v-calendar-daily__interval-text {
    color: transparent !important
  }

  .v-text-field {
    padding: 0;
  }

  .v-text-field__slot input {
    padding: 2px 0 !important;
  }

  .v-input__prepend-outer {
    margin-right: 3px;
  }


}

.form-modal {
  padding: 15px 0;

  .col-12 {
    position: relative;
  }

  .v-label {
    right: 0 !important;
    left: auto !important;
  }

  label {
    display: block;
    margin-bottom: 10px;
    font-size: 15px;
    background-color: white;
    position: absolute;
    top: 0;
    z-index: 50;
    right: 7%;
  }

  label.custom-label {
    font-size: 14px !important;
  }

  .v-input__slot {
    margin-bottom: 0 !important;
  }

  .custom-input {
    .v-text-field {
      border: 1px solid rgba(21, 57, 135, 0.28);
      border-radius: 5px;
    }

    .v-text-field__details {
      display: none;
    }

    label {
      padding-right: 10px;
      margin-bottom: 10px;
    }

    .v-text-field .v-label {
      top: 3px !important;
    }
  }

}

</style>
