<template>
  <section class="registrationStudent">
    <v-main class="main-container">
      <v-row class="ma-0">
        <!-- right section image -->
        <v-col cols="12" md="6" lg="5" class="pa-0">
          <div class="overly">
            <img :src="images.overlay" alt="overlay">
          </div>
        </v-col>

        <!-- left section form -->
        <v-col cols="12 " md="6" lg="7" class="pa-0">
          <section class="left-section">
            <!--   content all section -->
            <div class="content-section">
              <!--  section header image logo-->
              <header-component/>

              <!--  section form-->
              <div class="form-section mt-5">
                <h4>مرحبا بكم معنا في ناظم نسخة البرامج النوعية </h4>
                <h2 class="mb-8">تسجيل طالب</h2>
                <validation-observer  v-slot="{ invalid, handleSubmit }" >
                  <v-form @submit.prevent="handleSubmit(register)" :disabled="submitOnProgress">
                    <v-row>
                      <v-col cols="12" lg="6">
                        <div class="group-field">
                          <label>رقم الهوية</label>
                          <ValidationProvider name="رقم الهوية" :rules="rules.id" v-slot="{ errors }">
                            <v-text-field 
                              v-model="id" 
                              :error-messages="errors" 
                              placeholder="رقم الهوية"
                              required
                              ></v-text-field>
                          </ValidationProvider>
                        </div>
                      </v-col>
                      <v-col cols="12" lg="6">
                        <div class="group-field">
                          <label>صورة الهوية</label>
                          <v-file-input
                              placeholder="ارفع هنا صورة الهوية"
                              :value="photoId"
                          ></v-file-input>
                        </div>
                      </v-col>
                      <v-col cols="12" lg="6">
                        <div class="group-field">

                          <label>الجنس</label>
                          <ValidationProvider name="الجنس" :rules="rules.gender" v-slot="{ errors }">
                            <v-select
                                :items="['ذكر','انثى']"
                                placeholder="الجنس"
                                :error-messages="errors"
                                v-model="gender"
                            ></v-select>
                          </ValidationProvider>
                        </div>
                      </v-col>
                      <v-col cols="12" lg="6">
                        <div class="group-field">

                          <label>دولة الاقامة</label>
                          <ValidationProvider name="دولة الاقامة" :rules="rules.country" v-slot="{ errors }">
                            <v-select
                                :items="countries"
                                placeholder="دولة الاقامة"
                                :error-messages="errors"
                                v-model="country"
                            ></v-select>
                          </ValidationProvider>
                        </div>
                      </v-col>
                      <v-col cols="12" lg="6">
                        <div class="group-field">
                          <label>رمز المنطقة</label>
                          <ValidationProvider name="رمز المنطقة" :rules="rules.postal" v-slot="{ errors }">
                            <v-text-field v-model="postal" :error-messages="errors" placeholder="رمز المنطقة"></v-text-field>
                          </ValidationProvider>
                        </div>
                      </v-col>
                      <v-col cols="12" lg="6">
                        <div class="group-field">
                          <label>المؤهل العلمي</label>
                          <ValidationProvider name="المؤهل العلمي" :rules="rules.education" v-slot="{ errors }">
                            <v-text-field v-model="education" :error-messages="errors" placeholder="المؤهل العلمي"></v-text-field>
                          </ValidationProvider>
                        </div>
                      </v-col>
                      <v-col cols="12" lg="6">
                        <div class="group-field">

                          <label>الجنسية</label>
                          <ValidationProvider name="الجنسية" :rules="rules.nationality" v-slot="{ errors }">
                            <v-select
                                :items="countries"
                                placeholder="الجنسية"
                                :error-messages="errors"
                                v-model="nationality"
                            ></v-select>
                          </ValidationProvider>
                        </div>
                      </v-col>
                      <v-col cols="12" lg="6">
                        <div class="group-field">
                          <label>مدينة الاقامة</label>
                          <ValidationProvider name="مدينة الاقامة" :rules="rules.city" v-slot="{ errors }">
                            <v-text-field v-model="city" :error-messages="errors" placeholder="المدينه"></v-text-field>
                          </ValidationProvider>
                        </div>
                      </v-col>
                      <v-col cols="12" lg="6">
                        <div class="group-field">
                          <label>الحي السكني</label>
                          <ValidationProvider name="الحي السكني" :rules="rules.address" v-slot="{ errors }">
                            <v-text-field v-model="address" :error-messages="errors" placeholder="الحي السكني"></v-text-field>
                          </ValidationProvider>
                        </div>
                      </v-col>
                      <v-col cols="12" lg="6">
                        <div class="group-field">
                          <label>الفرع</label>
                          <ValidationProvider name="الفرع" :rules="rules.branch" v-slot="{ errors }">
                            <v-select
                                :items="branches"
                                placeholder="الفرع"
                                :error-messages="errors"
                                v-model="selectedBranch"
                            ></v-select>
                          </ValidationProvider>
                        </div>
                      </v-col>
                    </v-row>
                    <div class="submit-button">
                      <v-btn type="submit" :disabled="invalid" depressed color="#508ABA" class="text-white btn-custom">
                        تسجيل
                      </v-btn>
                    </div>
                    <div class="create-account">
                      <span>لديك حساب؟</span>
                      <router-link to="/signup" class="custom-link"> تسجيل جديد</router-link>
                    </div>
                  </v-form>
                </validation-observer>
              </div>
              <!--  section copy right -->
              <copyrights-component/>
            </div>
          </section>
        </v-col>
      </v-row>
    </v-main>
  </section>
</template>

<script>
import HeaderComponent from "@/views/loginSignup/components/headerComponent";
import CopyrightsComponent from "@/views/loginSignup/components/copyrightsComponent";
import {mapGetters} from "vuex";
import { registerStudent, fetchBranches } from 'Actions';

export default {
  name: "registrationStudentView",
  components: {CopyrightsComponent, HeaderComponent},
  data() {
    return {
      id: '',
      photoId: null,
      country: null,
      nationality: null,
      city: '',
      address: '',
      branches: [],
      selectedBranch: null,
      education: '',
      postal: '',
      gender: null,
      images: {
        overlay: require('@/assets/image/logo/Pateeren.png'),
      },
      show1: false,
      password: 'Password',
      submitOnProgress: false,
      loadingBranchesStatus: true,
      fetchBranchesApiError: {
          status: false,
          error: {},
      },
      createStudentApiError: {
          status: false,
          error: {},
      },
      rules: {
        id: {
          required: true,
        },
        country: {
          required: true,
        },
        city: {
          required: true,
        },
        nationality: {
          required: true,
        },
        education: {
          required: true,
        },
        address: {
          required: true,
        },
        postal: {
          required: true,
        },
        gender: {
          required: true,
        },
        branch: {
          required: true,
        },
      }
    }
  },
  computed: {
    ...mapGetters("settings",[
        'linkBarRight', 
        'showBarRight',
        'countries',
    ]),
  },
  methods: {
    async register() {
        this.submitOnProgress = true;

        this.createStudentApiError = {
            status: false,
            error: {},
        };

        let formData = new FormData();
        formData.append("id", this.id);
        formData.append("photo_id", this.photoId);
        formData.append("nationality", this.nationality);
        formData.append("country", this.country);
        formData.append("city", this.city);
        formData.append("postal", this.postal);
        formData.append("address", this.address);
        formData.append("gender", this.gender);
        formData.append("education", this.education);
        formData.append("branch", this.selectedBranch);
        
        try {
            let res = await registerStudent(formData);
            if (res.data.status) {
                //this.$emit('new-class-created');
            } else {
                this.$toast.error(res.data.message);
                this.createStudentApiError.status = true;
                this.createStudentApiError.error.message = res.data.message;
            }
        } catch (error) {
            this.$toast.error("خدث خطاْ ما");
            this.createStudentApiError.status = true;
            this.createStudentApiError.error.message = error.response ? error.response.data : error;
        } finally {
            this.submitOnProgress = false;
        }
    },
    async getBranches() {
        this.loadingBranchesStatus= true;
        this.fetchBranchesApiError = {
            status: false,
            error: {},
        };
        try {
            let res = await fetchBranches();
            if (res.data.status) {
                this.branches = res.data.data;
            } else {
                this.fetchBranchesApiError.status = true;
                this.fetchBranchesApiError.error.message = res.data.message;
            }
        } catch (error) {
            this.fetchBranchesApiError.status = true;
            this.fetchBranchesApiError.error.message = error.response ? error.response.data : error;
        } finally {
            this.loadingBranchesStatus = false;
        }
    },
    setup() {
      this.getBranches();
    }
  },
  beforeMount() {
    //this.showRightBar();
    this.$store.dispatch('settings/showBarRight');
  },
  created() {
    this.setup();
  }

}
</script>

<style lang="scss" scoped>
@import "@/assets/css/variable.scss";


.registrationStudent {
  @media (min-width: 1280px) {
    .col-lg-7 {
      flex: 0 0 54.3333333333%;
      max-width: 54.3333333333%;
    }
    .col-lg-5 {
      flex: 0 0 45.666667%;
      max-width: 45.666667%;
    }
  }

  .main-container {
    min-height: 100vh;
    background-image: url("@/assets/image/logo/Sign In.png");
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;

    .overly {
      position: absolute;
      right: 0;
      width: 120px;
      opacity: 0.8;

      img {
        width: 100%;
      }
    }

    //start left section

    .left-section {
      padding: 20px 10PX;
      min-height: 100vh;
      background-color: white;
      border-radius: 0 20px 20px 0;
      width: 100%;

      .content-section {
        width: 70%;
        margin: 0 auto;
      }

      .logo-image {
        width: 100%;

        .image {
          width: 70%;

        }
      }

      .form-section {
        .col-12 {
          padding: 7px 10px !important;
        }

        h4 {
          font-size: 17px;
          line-height: 24px;
          margin-bottom: 20px;
          color: #707070;
          font-weight: 600;

        }

        h2 {
          font-size: 38px;
          line-height: 30px;
          margin-bottom: 30px;
          color: #000000;
        }

        label {
          font-size: 18px;
          color: #272727;
          margin-bottom: 10px;
          display: inline-block;
        }

        .remember-login {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .checkbox-input {

            input[type=checkbox] {
              margin-left: 15px;
              padding: 10px;
              transform: scale(1.2);
            }


          }
        }

        .password-remember {
          .custom-link {
            color: $linkColor;
            font-size: 16px;
          }
        }

        .submit-button {
          margin-top: 20px;
          margin-bottom: 20px;

          .btn-custom {
            width: 100%;
            min-height: 64px;
            font-size: 16px;
            color: white;
          }
        }

        .create-account {
          text-align: center;

          .custom-link {
            font-size: 16px !important;
            color: $linkColor;
          }

          span {
            font-size: 16px;
            margin-left: 5px;
            color: $linkColor;
          }
        }
      }
    }

    //end left section
  }
}

</style>

<!--custom style input vuetify-->
<style lang="scss">
.registrationStudent {

  ::placeholder {
    font-size: 15px;
  }


  .v-field__input {
    opacity: .8;
    border: none;
    min-height: 48px;
  }

  //.v-field__outline {
  //  display: none;
  //}
  .group-field {
    position: relative !important;
  }

  .v-input__icon {
    position: absolute;
    left: 3%;
    top: 50%;
    transform: translateY(-50%);
    font-size: 15px;
  }

  .v-messages__message {
    font-size: 16px !important;
    margin-bottom: 10px;
  }

  .v-application--is-ltr .v-messages {
    text-align: right !important;
  }

  .login-page .v-messages__message {
    text-align: center !important;
  }

  .v-text-field {
    padding-top: 0;
    margin-top: 0;
  }

  .group-field {
    line-height: 1em;
  }

  .theme--light.v-input input, .theme--light.v-input textarea {
    padding: 20px;
  }

  .theme--light.v-text-field > .v-input__control > .v-input__slot:before {
    border: none !important;
  }

  .v-text-field > .v-input__control > .v-input__slot:after {
    border: none !important;
  }

  .form-section {
    .remember-login {
      .v-label {
        margin-right: 8px !important;
      }
    }
  }

  .v-input__control {
    border: 1px solid #8692A6;
    min-height: 45px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    background-color: #FAFBFC;
    text-align: right;
  }

  .v-text-field__details {
    display: none;
  }

  .v-input__slot {
    margin-bottom: 0px;
  }

  .v-label {
    left: auto !important;
    right: 10px !important;
    position: absolute;

  }

  ::placeholder {
    font-size: 15px !important;
  }

  .main-container .left-section .form-section label {
    font-size: 16px !important;
    margin-right: 5px;
  }

  .v-file-input__text {
    font-size: 15px !important;
    padding-right: 10px;
    color: #b0b0b0 !important;
  }

  .v-input__icon {
    transform: translateY(-50%) !important;
  }

  .v-input {
    position: relative !important;
  }

  .v-input__prepend-outer {
    display: none !important;
  }
}

</style>
