<template>
<section class="detailsBranches-view">
    <v-main>
        <div class="container--fluid">
            <div class="all-card">
                <v-row>

                    <!-- Manager -->
                    <v-col cols="12" md="4">
                        <div class="title-section">
                            <span>مدير الفرع</span>
                        </div>
                        <v-row>
                            <v-col style="padding-left: 5px" cols="12">
                                <card-branches-details :employee="manger" />
                            </v-col>
                        </v-row>
                    </v-col>

                    <!-- Assistant -->
                    <v-col cols="12" md="4">
                        <div class="title-section">
                            <span>المساعد</span>
                        </div>
                        <v-row>
                            <v-col style="padding-left: 5px" cols="12">
                                <card-branches-details :employee="assistant" />
                            </v-col>
                        </v-row>
                    </v-col>

                    <!-- Secretarial -->
                    <v-col cols="12" md="4">
                        <div class="title-section">
                            <span>السكرتارية</span>
                        </div>
                        <v-row>
                            <v-col style="padding-left: 5px" cols="12">
                                <card-branches-details :employee="secretarial" />
                            </v-col>
                        </v-row>
                    </v-col>

                </v-row>

                <div class="reciters mt-10">
                    <div class="title-section">
                        <span>المشرفين</span>
                    </div>
                    <v-row>
                        <v-col v-for="item in supervisors" :key="item.id" cols="12" lg="4" md="6">
                            <card-branches-details :employee="item" />
                        </v-col>
                    </v-row>
                </div>

                <div class="reciters mt-10">
                    <div class="title-section">
                        <span>المقرئين</span>
                    </div>
                    <v-row>
                        <v-col v-for="item in readers" :key="item.id" cols="12" lg="4" md="6">
                            <card-branches-details :employee="item" />
                        </v-col>
                    </v-row>
                </div>

            </div>

            <pagination-components />

        </div>
    </v-main>
</section>
</template>

<script>
import PaginationComponents from "@/components/dashboard/paginationComponents";
import { mapGetters } from "vuex";
import CardBranchesDetails from "@/components/cards/card-branchesDetails";
import { fetchBranchById } from 'Actions';

export default {
    name: "detailsBranches",
    props: ['branch'],
    components: {
        CardBranchesDetails,
        PaginationComponents
    },
    data() {
        return {
            items: [
                'ربط ', 'مراجعه', 'تكرار',
            ],
            employee: {},
            manger: {},
            assistant: {},
            secretarial: {},
            supervisors: {},
            readers: {},
            styleSearch: {
                backgroundColor: 'transparent',
                border: "1px solid #E2E2EA",
                borderRadius: "10px",

            },
        }
    },
    computed: {
        ...mapGetters('settings', ['allInterview'])
    },
    methods: {
        // branch details
        async fetchItem() {
            const id = this.$route.params.id
            let res = await fetchBranchById(id);
            console.log(res.data.data)
            if (res.data.status) {
                this.manger = res.data.data.manger;
                this.assistant = res.data.data.assistant;
                this.secretarial = res.data.data.secretarial;
                this.supervisors = res.data.data.supervisors;
                this.readers = res.data.data.readers;
            }

        },

    },
    beforeMount() {
        this.$store.dispatch('settings/pageTitle', 'الفروع')
        this.$store.dispatch('settings/subTitleTop', ['الفرع الاول']);
        this.$store.dispatch('settings/showTopTitle');
    },
    created() {
        this.fetchItem();
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/variable.scss";

.detailsBranches-view {
    background-color: $background-main-page;
    padding: 40px 15px;

    .container--fluid {
        border-radius: 15px;
        background-color: white;
        padding: 15px
    }

    .all-card {
        .title-section {
            span {
                display: block;
                position: relative;
                margin-bottom: 30px;
                color: #202020;
                font-size: 20px;
            }

            span:after {
                content: '';
                width: 100px;
                height: 3px;
                background-color: #00B5AD;
                position: absolute;
                bottom: -25%;
                right: 0;
                border-radius: 10px;
            }

            span:before {
                content: '';
                width: 200px;
                height: 3px;
                background-color: #B4B4B4;
                position: absolute;
                bottom: -25%;
                right: 0;
                border-radius: 10px;
            }
        }
    }

    .top-bar-search {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;

        @media only screen and (max-width: 880px) {
            flex-direction: column;
        }

        .search-section {
            width: 55%;

            @media only screen and (max-width: 880px) {
                width: 100%;
            }

            .search {
                display: flex;
                align-items: center;
                padding: 12px 0;

                button {
                    border: 1px solid #00B5AD;
                    padding: 10px 40px;
                    font-size: 14px;
                    border-radius: 10px;
                    color: #00B5AD;
                    margin-right: 10px;
                }

            }
        }

    }

    .import-export-file {
        display: flex;
        align-items: center;
        margin-bottom: 8px;

        div {
            margin: 0 5px;
        }
    }

}
</style><style lang="scss">
.theme--light.v-text-field>.v-input__control>.v-input__slot:before {
    display: none !important;
    border: none !important;
}

.detailsBranches-view {

    .theme--light.v-input input,
    .theme--light.v-input textarea {
        font-size: 14px !important;
        font-weight: bold !important;
        color: #707070 !important;
        margin-top: 5px;
    }

    .v-input__icon i {
        font-size: 14px !important;
        font-weight: normal !important;
        color: #707070 !important;
    }

    .v-text-field__details {
        display: none;
    }

    .v-input__slot:focus .v-text-field__details {
        border: none !important;
        display: none;

    }

    .v-input__slot:active .v-text-field__details {
        border: none !important;
        display: none;

    }

    .theme--light.v-input {
        margin: 0px 10px 0px 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #D2D5E1;
        border-radius: 5px;
    }

    .v-calendar-daily__interval-text {
        color: transparent !important
    }

    .v-text-field {
        padding: 0;
    }

    .v-text-field__slot input {
        padding: 2px 0 !important;
    }

    .v-input__prepend-outer {
        margin-right: 3px;
    }

}

.form-modal {
    padding: 15px 0;

    .col-12 {
        position: relative;
    }

    .v-label {
        right: 0 !important;
        left: auto !important;
    }

    label {
        display: block;
        margin-bottom: 10px;
        font-size: 15px;
        background-color: white;
        position: absolute;
        top: 0;
        z-index: 50;
        right: 7%;
    }

    label.custom-label {
        font-size: 14px !important;
    }

    .v-input__slot {
        margin-bottom: 0 !important;
    }

    .custom-input {
        .v-text-field {
            border: 1px solid rgba(21, 57, 135, 0.28);
            border-radius: 5px;
        }

        .v-text-field__details {
            display: none;
        }

        label {
            padding-right: 10px;
            margin-bottom: 10px;
        }

        .v-text-field .v-label {
            top: 3px !important;
        }
    }

}
</style>
