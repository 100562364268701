import { render, staticRenderFns } from "./card-formTracks.vue?vue&type=template&id=b63cb4ce&scoped=true&"
import script from "./card-formTracks.vue?vue&type=script&lang=js&"
export * from "./card-formTracks.vue?vue&type=script&lang=js&"
import style0 from "./card-formTracks.vue?vue&type=style&index=0&id=b63cb4ce&prod&lang=scss&scoped=true&"
import style1 from "./card-formTracks.vue?vue&type=style&index=1&id=b63cb4ce&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b63cb4ce",
  null
  
)

export default component.exports