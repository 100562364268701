<template>
  <section class="question-interviews">
    <v-main>
        <section class="question-interviews"  cols="12" lg="12" md="12">
          <div class="container--fluid">
            <div class="todo shadow-lg  rounded-3" v-for="question in questions" :key="question.id">
              <div class="container-question" v-if="question.type == 'text'">
                <div class="number-question">
                  <div class="right-section">
                    <div class="icon">
                      <img :src="imageIcon.squareIcon" alt="image icon">
                    </div>
                    <h3>{{ question.name }}</h3>
                  </div>
                  <div class="left-section">
                    <div class="degree">
                      <h3>درجة السؤال</h3>
                      <input type="text" placeholder="50" :value="question.grade" class="number ml-4" disabled>
                    </div>
                         
                  </div>
                </div>
                <div class="content-question">
                  <div class="question">
                    <h5>{{ question.question }}</h5>
                  </div>
                  <div class="all-answer">
                    <v-textarea
                        solo
                        name="input-7-4"
                        :value="question.text_right_answer"
                    ></v-textarea>
                    <span class="rows-number">2000 سطر</span>
                  </div>
                </div>
              </div>
              <!-- choice -->
              <div class="container-question" v-if="question.type == 'choice'">
                <div class="number-question">
                  <div class="right-section">
                    <div class="icon">
                      <img :src="imageIcon.squareIcon" alt="image icon">
                    </div>
                    <h3> {{ question.name }} </h3>
                  </div>
                  <div class="left-section">
                    <div class="degree">
                      <h3>درجة السؤال</h3>
                      <input type="text" placeholder="50" :value="question.grade" class="number ml-4">
                    </div>
                      
                  </div>
                </div>
                <div class="content-question">
                  <div class="question">
                    <h5>{{ question.question }}</h5>
                    <span class="type-question">سؤال أختر</span>
                  </div>
                  <div class="all-answer">
                    <div class="content-answer" >
                      <div class="image-option">
                        <div class="image">
                          <img :src="imageIcon.squareIcon2">
                        </div>
                        <div class="number-option">
                          <span>الاختيار الاول</span>
                        </div>
                      </div>
                      <div class="answer">
                        <v-radio-group  dir="ltr">
                          <div class="container-radio"> 
                            <p>{{ question.choice1 }}</p>
                          </div>
                        </v-radio-group>
                      </div>
                       <div class="delete-option" v-if="question.choice_right_answer == 1" style="border-color:#13d313">
                        <span class="text-success" style="color:#13d313"><i class="fa fa-check " aria-hidden="true"></i> </span>
                      </div>
                       <div class="delete-option" v-if="question.choice_right_answer != 1" style="border-color:#d35013">
                        <span class="text-success" style="color:#d35013"><i class="fa fa-times " aria-hidden="true"></i> </span>
                      </div>
                    </div>
                    <div class="content-answer" >
                      <div class="image-option">
                        <div class="image">
                          <img :src="imageIcon.squareIcon2">
                        </div>
                        <div class="number-option">
                          <span>الاختيار الثانى</span>
                        </div>
                      </div>
                      <div class="answer">
                        <v-radio-group  dir="ltr">
                          <div class="container-radio " > 
                            <p>{{ question.choice2 }}</p>
                          </div>
                        </v-radio-group>
                      </div>
                      <div class="delete-option" v-if="question.choice_right_answer == 2" style="border-color:#13d313">
                        <span class="text-success" style="color:#13d313"><i class="fa fa-check " aria-hidden="true"></i> </span>
                      </div>
                        <div class="delete-option" v-if="question.choice_right_answer != 2" style="border-color:#d35013">
                        <span class="text-success" style="color:#d35013"><i class="fa fa-times " aria-hidden="true"></i> </span>
                      </div>
                    </div>
                    <div class="content-answer " >
                      <div class="image-option">
                        <div class="image">
                          <img :src="imageIcon.squareIcon2">
                        </div>
                        <div class="number-option">
                          <span>الاختيار الثالث</span>
                        </div>
                      </div>
                      <div class="answer">
                        <v-radio-group  dir="ltr">
                          <div class="container-radio" > 
                            <p>{{ question.choice3 }}</p>
                          </div>
                        </v-radio-group>
                      </div>
                      <div class="delete-option" v-if="question.choice_right_answer == 3" style="border-color:#13d313">
                         <span class="text-success" style="color:#13d313"><i class="fa fa-check " aria-hidden="true"></i> </span>
                       </div>
                        <div class="delete-option" v-if="question.choice_right_answer != 3" style="border-color:#d35013">
                         <span class="text-success" style="color:#d35013"><i class="fa fa-times " aria-hidden="true"></i> </span>
                       </div>
                    </div>
                    <div class="content-answer " >
                      <div class="image-option">
                        <div class="image">
                          <img :src="imageIcon.squareIcon2">
                        </div>
                        <div class="number-option">
                          <span>الاختيار الرابع &nbsp;&nbsp;</span>
                        </div>
                      </div>
                      <div class="answer">
                        <v-radio-group  dir="ltr">
                          <div class="container-radio" > 
                            <p>{{ question.choice4 }}</p>
                          </div>
                        </v-radio-group>
                      </div>
                      <div class="delete-option" v-if="question.choice_right_answer == 4" style="border-color:#13d313">
                         <span class="text-success" style="color:#13d313"><i class="fa fa-check " aria-hidden="true"></i> </span>
                       </div>
                        <div class="delete-option" v-if="question.choice_right_answer != 4" style="border-color:#d35013">
                         <span class="text-success" style="color:#d35013"><i class="fa fa-times " aria-hidden="true"></i> </span>
                       </div>
                    </div>
          
                  </div>
                </div>
              </div>
              <!-- choice -->
            </div>
          </div>
        </section>
    </v-main>
  </section>
</template>

<script>
import { getMethods } from '@/api/Helper';
export default {
  name: "questionInterviewsView",
  components: {},
  data() {
    return {
      imageIcon: {
        squareIcon: require('@/assets/image/icon/Group 78247.png'),
        squareIcon2: require('@/assets/image/icon/Group 78248.png'),
        blockIcon: require('@/assets/image/icon/Icon ionic-md-remove-circle-outline.png'),
      },
      questions:[]
    }
  },  
  methods: {
      async getQuestions() {

      try {
              const id = this.$route.params.id
        let res = await getMethods('questions/' + id);
        if (res.data.status) {
          this.questions = res.data.data;
        }
      } catch (error) {
        if (error.response.status == 400) {
          this.$toast.error(error.response.data.message)
        } else {
          this.$toast.error('فشلت العملية');
        }
      }
    },
  },
  beforeMount() {
    this.$store.dispatch('settings/pageTitle', 'أسئلة المقابلات');
  },
  created() {
    this.getQuestions();
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/variable.scss";

.question-interviews {
  background-color: $background-main-page;
  padding: 20px 15px 40px 15px;
  @media only screen and (max-width: 700px) {
    padding: 20px 8px 40px 8px;
  }

  .container--fluid {
    border-radius: 15px;
    padding: 10px 15px 15px 15px;
  }

  .container-question {
    display: v-bind('displayShow');
    background-color: white;
    border-radius: 20px;
    padding-bottom: 30px;
    overflow: hidden;
    margin-bottom: 30px;

    .number-question {
      display: flex;
      background-color: rgba(56, 91, 114, 0.16);
      padding: 25px 30px;
      justify-content: space-between;
      align-items: center;

      .right-section {
        display: flex;
        align-items: center;

        .icon {
          width: 28px;
          margin-left: 15px;
          display: flex;
          align-items: center;

          img {
            width: 100%;
          }
        }

        h3 {
          font-size: 22px !important;
          font-weight: bold !important;
          color: #354052;
          @media only screen and (max-width: 700px) {
            font-size: 16px !important;
          }
        }
      }

      .left-section {
        display: flex;
        .degree {
          display: flex;
          align-items: center;
          h3 {
            font-size: 22px !important;
            color: #272727;
            margin-left: 10px;
            @media only screen and (max-width: 700px) {
              font-size: 16px !important;
            }
          }

          input.number {
            width: 40px;
            background-color: #FAFBFC;
            padding: 4px 8px;
            border: 1px solid rgba(6, 83, 254, 0.2);
            border-radius: 10px;
            color: #385B72;
            margin-left: 50px;
            margin-right: 5px;
            font-size: 18px;
            display: inline-block;

          }

          span.close-icon {
            margin-left: 10px;
            background-color: rgba(231, 29, 54, 0.25);
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            font-weight: 300 !important;
            font-size: 15px;
            color: rgba(231, 29, 54, 0.76);
            @media only screen and (max-width: 700px) {
              width: 30px;
              height: 30px;
            }
          }
           span.edit-icon {
            margin-left: 10px;
            background-color: orange;
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            cursor: pointer;
            font-weight: 300 !important;
            font-size: 15px;
            color: white;
            @media only screen and (max-width: 700px) {
              width: 30px;
              height: 30px;
            }
          }

        }
      }
    }

    .content-question {
      padding: 30px 50px;
      @media only screen and (max-width: 700px) {
        padding: 30px 15px !important;
      }

      .question {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .type-question {
          @media only screen and (max-width: 700px) {
            display: none;
          }
        }

        h5 {
          color: #272727;
          font-size: 18px;
          margin-bottom: 20px;
          @media only screen and (max-width: 700px) {
            font-size: 16px;
          }
        }

        span {
          font-size: 15px;
          color: #385B72;
        }
      }
    }

    .all-answer {
      width: 100%;
      position: relative;

      .content-answer {
        display: flex;
        align-items: center;

        .image-option {
          display: flex;
          align-items: center;
          margin-bottom: 19px;
          margin-left: 20px;
          @media only screen and (max-width: 700px) {
            display: none !important;
          }

          .image {
            width: 22px;
            margin-top: 5px;

            img {
              width: 100%;
            }
          }

          .number-option {
            margin-right: 10px;
            margin-bottom: 7px;

            span {
              font-size: 15px !important;
            }
          }

        }

        .answer {
          width: 83%;

          .container-radio {
            border: 1px solid rgba(6, 83, 254, 0.21);
            border-radius: 5px;
            padding: 25px 15px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            @media only screen and (max-width: 700px) {
              .v-label {
                display: none !important;
              }
            }

            p {
              margin-bottom: 0;
              font-size: 16px;
              text-align: right;
              position: absolute;
              right: 2%;
              @media only screen and (max-width: 700px) {
                font-size: 14px;
              }

            }
          }
        }

        .delete-option {
          border: 1px solid rgba(6, 83, 254, 0.21);
          border-radius: 5px;
          margin-right: 5px;
          display: flex;
          align-items: center;
          padding: 14px 5px;
          cursor: pointer;
          margin-bottom: 19px !important;

          img {
            width: 22px;
          }
        }
      }

      .rows-number {
        display: inline-block;
        position: absolute;
        left: 5%;
        bottom: 25%;
        font-size: 12px;
        color: #272727;

      }

      .add-new-option {
        position: absolute;
        bottom: -2%;
        left: 1%;
        font-size: 14px;
        text-decoration: underline
      }
    }
  }

  .v-input--radio-group--column .v-radio {
    margin-bottom: 0;
  }

}
</style>
<style lang="scss">
.question-interviews {
  .v-size--default {
    padding-top: 22px !important;
    padding-bottom: 24px !important;
  }

  .v-input--selection-controls .v-input__slot > .v-label, .v-input--selection-controls .v-radio > .v-label {
    color: #00B5AD !important;
    margin-bottom: 7px;
    @media only screen and (max-width: 700px) {
      font-size: 13px;
    }
  }

  .v-application .primary--text {
    color: #00B5AD !important;
  }

  .v-icon.v-icon {
    font-size: 20px !important;
  }

  .v-input--radio-group--column .v-radio {
    margin-bottom: 0;
    width: 100% !important;
    position: absolute !important;
    z-index: 1;
  }

  .v-input--selection-controls.v-input {
    margin-top: 0px !important;
  }

  .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
    box-shadow: none !important;
    border: 1px solid rgba(6, 83, 254, 0.15);

  }

  .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control {
    padding-top: 15px;
  }

  .v-textarea.v-text-field--enclosed .v-text-field__slot textarea {
    font-size: 16px !important;
  }
  .input-7-4{
    border:1px solid #00B5AD
  }
  .modal-custom .button-modal {
    width: 100%;
  }


}


label.custom-label-recitation-interview {
  display: block;
  margin-bottom: 10px;
  font-size: 14px;
  background-color: white;
  position: absolute;
  top: 14%;
  z-index: 50;
  right: 4%;
}

label.custom-label-recitation-interview {
  font-size: 14px !important;
}

.createQuestion-one {
  padding: 20px 0;
}

.container-question-modal {
  background-color: white;
  border-radius: 20px;
  overflow: hidden;

  .content-question {
    padding: 10px 10px;

    .question {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .type-question {
        @media only screen and (max-width: 700px) {
          display: none;
        }
      }

      h5 {
        color: #272727;
        font-size: 18px;
        margin-bottom: 20px;
      }

      span {
        font-size: 15px;
        color: #385B72;
      }
    }
  }

  .all-answer {
    width: 100%;
    position: relative;

    .content-answer {
      display: flex;
      align-items: center;

      .image-option {
        display: flex;
        align-items: center;
        margin-bottom: 19px;
        margin-left: 10px;
        @media only screen and (max-width: 700px) {
          display: none !important;
        }
        .image {
          width: 22px;
          margin-top: 5px;

          img {
            width: 100%;
          }
        }

        .number-option {
          margin-right: 10px;
          margin-bottom: 7px;

          span {
            font-size: 15px !important;
          }
        }

      }

      .answer {
        width: 77%;
        @media only screen and (max-width: 700px) {
          width: 100%;
        }
        .container-radio {
          border: 1px solid rgba(6, 83, 254, 0.21);
          border-radius: 5px;
          padding: 25px 15px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          p {
            margin-bottom: 0;
            font-size: 16px;
            text-align: right;
            position: absolute;
            right: 2%;
          }
        }
      }

      .delete-option {
        border: 1px solid rgba(6, 83, 254, 0.21);
        border-radius: 5px;
        margin-right: 5px;
        display: flex;
        align-items: center;
        padding: 14px 5px;
        cursor: pointer;
        margin-bottom: 19px !important;

        img {
          width: 22px;
        }
      }
    }

    .rows-number {
      display: inline-block;
      position: absolute;
      left: 5%;
      bottom: 25%;
      font-size: 12px;
      color: #272727;

    }

    .add-new-option {
      position: absolute;
      bottom: -2%;
      left: 1%;
      font-size: 14px;
      text-decoration: underline
    }
  }

}

.v-dialog {
  .v-input--selection-controls {
    margin-top: 0 !important;
  }

  .container-question-modal .all-answer .content-answer .answer .container-radio {
    padding: 10px !important;
  }

  .v-size--default {
    padding-top: 22px !important;
    padding-bottom: 24px !important;
  }
}

.v-btn__content {
  font-size: 18px;
}
</style>
