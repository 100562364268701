import Api from 'Api';

export function getMethods(uri, params) {
  let defultParams = {
    limit: 10,
    offset: 0,
    field: 'id',
    sort: 'desc',
    resource: 'all',
    deleted: 'false',
    paginate: 'true',
    columns: ['active'],
    operand: ['='],
    column_values: [1],
  }

  if (typeof defultParams !== undefined) {
    defultParams = {
      ...defultParams,
      ...params,
    }
  }

  return new Promise((resolve, reject) => {
    Api.get(`${uri}`, {
      params: {
        ...defultParams,
      },
      headers: {
        'Content-Type': 'application/json',
        accept: 'application/json',
      },
    })
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        if (error.response) {
          error.response.status == 400
            ? console.log(error.response.data.message)
            : 'no'
          error.response.status == 401
            ? console.log(error.response.data.message)
            : 'no'
          error.response.status == 404
            ? console.log(error.response.data.message)
            : 'no'
          error.response.status == 500
            ? console.log(error.response.data.message)
            : 'no'
        }
        reject(error)
      })
  })
}
// End Get Helper Mthods

// Start Post Helper Mthods
export function postMethods(uri, payload) {
  return new Promise((resolve, reject) => {
    Api.post(`${uri}`, payload, {
      headers: {
        'Content-Type': 'application/json',
        accept: 'application/json',
        'Access-Control-Allow-Origin': '*'
      },
    })
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        if (error.response) {
          error.response.status == 400
            ? console.log(error.response.data.message)
            : 'no'
          error.response.status == 401
            ? console.log(error.response.data.message)
            : 'no'
          error.response.status == 404
            ? console.log(error.response.data.message)
            : 'no'
          error.response.status == 500
            ? console.log(error.response.data.message)
            : 'no'
        }
        reject(error)
      })
  })
}
// End Post Helper Mthods

// Start Put Helper
export function putMethods(uri, payload) {
  return new Promise((resolve, reject) => {
    Api.put(`${uri}`, payload, {
      headers: {
        'Content-Type': 'application/json',
        accept: 'application/json',
        'Access-Control-Allow-Origin': '*'
      },
    })
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        if (error.response) {
          error.response.status == 400
            ? console.log(error.response.data.message)
            : 'no'
          error.response.status == 401
            ? console.log(error.response.data.message)
            : 'no'
          error.response.status == 404
            ? console.log(error.response.data.message)
            : 'no'
          error.response.status == 500
            ? console.log(error.response.data.message)
            : 'no'
        }
        reject(error)
      })
  })
}
// End Put Helper

// Start delete Helper
export function deleteMethods(uri, payload) {
  return new Promise((resolve, reject) => {
    Api.delete(`${uri}`, {
      params: payload,
      headers: {
        'Content-Type': 'application/json',
        accept: 'application/json',
      },
    })
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        if (error.response) {
          error.response.status == 400
            ? console.log(error.response.data.message)
            : 'no'
          error.response.status == 401
            ? console.log(error.response.data.message)
            : 'no'
          error.response.status == 404
            ? console.log(error.response.data.message)
            : 'no'
          error.response.status == 500
            ? console.log(error.response.data.message)
            : 'no'
        }
        reject(error)
      })
  })
}
// End delete Helper

export const arrayUnique = (array) => {
  var a = array.concat();
  for (var i = 0; i < a.length; ++i) {
    for (var j = i + 1; j < a.length; ++j) {
      if (a[i] === a[j])
        a.splice(j--, 1);
    }
  }

  return a;
}


export function hexToRgbA(hex, alpha) {
  var c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join('');
    return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + alpha + ')';
  }
  throw new Error('Bad Hex');
}