import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { store } from './store';
import vuetify from './plugins/vuetify'
import './assets/css/material-nazem.scss'
import VCalendar from 'v-calendar';
import {Chart} from 'chart.js'
import Chartkick from 'vue-chartkick'
import VueNumber from 'vue-number-animation';
import Toast from "vue-toastification";
import PermissionsAction from './mixins/PermissionsAction';
const toastOptions = {
  transition: "Vue-Toastification__fade",
  maxToasts: 20,
  newestOnTop: true,
  timeout: 3000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: false,
  icon: true,
  position: "top-right",
  rtl: true,
};

import "vue-toastification/dist/index.css";

// global components
import GlobalComponents from './globalComponents'

Vue.use(VueNumber)
Vue.use(Chartkick.use(Chart));
Vue.use(VCalendar);
Vue.use(Toast, toastOptions);
Vue.use(GlobalComponents);
Vue.mixin(PermissionsAction );

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

