<template>
  <div class="select-component">
    <label class="main-label"> {{ label }}</label>
    <v-select
        :items="items"
        :label="name"
        solo
        :item-text="itemText"
        :item-value="itemValue"
        v-model="content"
        :multiple="multiple"
        @input="dataChanged"
        @blur="dataChanged"
        @change="$emit('change', $event)"
        :disabled="disabled"
    ></v-select>
    <span class="fs-14 error--text" v-if="fieldErrors.length">{{ fieldErrors[0] }}</span>
  </div>

</template>

<script>
export default {
  name: "select-input",
  props: {
    
    name: {
      required: true,
      type: String,
    },
    items: {
      required: true,
      type: Array,
    },
    itemText: {
      type: String,
      default: null,
    },
    itemValue: {
      type: String,
      default: null,
    },
    label: {
      required: true,
      type: String,
    },
    fieldErrors: {
      required: false,
      type: Array,
      default: () => [],
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  data(){
    return{
      content: this.value,
    }
  },
  methods: {
    dataChanged() {
      this.$emit('input', this.content);
    }
  }

}
</script>

<style lang="scss">
.select-component {
  width: 100%;


  .v-text-field.v-text-field--solo .v-label {
    right: 0 !important;
    left: auto !important;
  }

  .v-text-field.v-text-field--enclosed .v-text-field__details {
    display: none;
  }

  .v-text-field.v-text-field--solo .v-input__control {
    width: 170px;
    min-width: 100px;
  }

  .theme--light.v-text-field--solo > .v-input__control > .v-input__slot {
    background-color: transparent !important;
    width: 100%;
    border: 1px solid rgba(6, 83, 254, 0.13);
    font-size: 14px;
  }

  .v-select__selection--comma {
    font-size: 14px;
  }

  .v-text-field.v-text-field--solo > .v-input__control > .v-input__slot {
    box-shadow: none;
  }

  .v-text-field.v-text-field--solo .v-label {
    color: #CBCACA !important;
    font-size: 13px !important;
  }

  .v-label {
    right: 0 !important;
    left: auto !important;
  }

  label {
    display: block;
    margin-bottom: 10px;
    font-size: 15px ;
    background-color: white;
    position: absolute;
    top: 0;
    z-index: 1;
    right: 25px !important;
  }
  label.main-label{
    font-weight: bold;
    font-size: 14px !important;
    color: rgba(0,0,0,0.6) !important;
  }

}

</style>
