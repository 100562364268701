<template>
    <app-dialog @close-edit-item="$emit('close-edit-item')" title="تعديل السرد الاسبوعى" event-name="close-edit-item"
        :dialog-status="dialogStatus" max-width="750px">

        <validation-observer v-slot="{ invalid, handleSubmit }">

            <v-form @submit.prevent="handleSubmit(updateItem)" :disabled="submitOnProgress">
                <v-row>
                    
                    <v-col cols="12" lg="6" md="6">
                        <ValidationProvider name="المسار" :rules="rules.path_id" v-slot="{ errors }">
                            <v-select :items="paths" item-text="text" item-value="id" placeholder="المسار"
                                :error-messages="errors" v-model="path" outlined dense></v-select>
                        </ValidationProvider>
                    </v-col>
                    <v-col cols="12" lg="6" md="6">
                        <ValidationProvider name="الفترات" :rules="rules.duration_id" v-slot="{ errors }">
                            <v-select 
                            :items="durations"
                             tem-text="text" 
                             item-value="id" 
                             placeholder="الفترات"  
                             :error-messages="errors" 
                             v-model="duration" 
                             outlined 
                             dense
                             multiple
                             ></v-select>
                        </ValidationProvider>
                    </v-col>
                    <v-col cols="12" lg="6" md="6">
                        <ValidationProvider name="'أختر يوم السرد'" :rules="rules.read_day_date" v-slot="{ errors }">                               
                            <date-customer 
                            :field-errors="errors" 
                            v-model="read_day"
                            dense
                            outlined
                            @data-entered="read_day_date = $event"
                            :label="'أختر يوم السرد'"
                            :name-placeholder="'أختر يوم السرد'"
                            />
                        </ValidationProvider>
                    </v-col>
                    <v-col cols="12" lg="6" md="6">
                    <ValidationProvider name="'أختر من بنود السرد'" :rules="rules.active_band_id" v-slot="{ errors }">
                            <v-select
                                :items="bandTypes"
                                item-text="text"
                                item-value="id"
                                placeholder="أختر من بنود السرد"
                                :error-messages="errors"
                                v-model="active_band"
                                outlined
                                multiple
                                dense
                            ></v-select>   
                                    
                    </ValidationProvider>
                    </v-col>
                    <v-col cols="12" lg="6" md="6">
                    <ValidationProvider name="'اتمام السرد'" :rules="rules.is_read" v-slot="{ errors }">    
                            <v-select 
                            :items="compleateNarrative" 
                            item-text="text" 
                            item-value="value" 
                            placeholder="اتمام السرد"
                            :error-messages="errors" 
                            v-model="is_reads" 
                            outlined 
                            dense
                            ></v-select> 
                    </ValidationProvider>
                    </v-col>
                    <v-col cols="12" lg="6" md="6" class="custom-input">
                    <ValidationProvider name="'مقدار البند'" :rules="rules.band_value" v-slot="{ errors }">     
                        <input-text  :field-errors="errors"  v-model="bandvalue"  @data-entered="band_value = $event"  :label-top="'مقدار البند'" :placeholder="'مقدار البند'"  dense outlined  />
                    </ValidationProvider>
                    </v-col>
                    <v-col cols="12" lg="6" md="6" class="custom-input">
                    <ValidationProvider name="'من الوجه'" :rules="rules.from_verse" v-slot="{ errors }">     
                        <input-text   :field-errors="errors"  v-model="fromverse" @data-entered="from_verse = $event"  :label-top="'من الوجه'" :placeholder="'من الوجه'"/>
                    </ValidationProvider>
                    </v-col>
                    <v-col cols="12" lg="6" md="6" class="custom-input">
                    <ValidationProvider name="'الى الوجه'" :rules="rules.to_verse" v-slot="{ errors }">     
                        <input-text  :field-errors="errors"  v-model="toverse"  item-text="text" @data-entered="to_verse = $event"   :label-top="'الى الوجه'" :placeholder="'الى الوجه'"/>
                    </ValidationProvider>
                    </v-col>
                    <v-col cols="12">
                        <v-btn :disabled="invalid || !anyChanged" type="submit" block color="#00B5AD"
                            style="color: white;font-size: 15px;height: 45px">تعديل</v-btn>
                    </v-col>
                </v-row>
            </v-form>

        </validation-observer>

    </app-dialog>
</template>

<script>

import AppDialog from 'Components/AppDialog/AppDialog.vue';
import DateCustomer from "@/components/date-customer";
import InputText from "@/components/input-text";

import { listDurations, listPaths, updateweeklyNarrative } from 'Actions';

export default {
    components: {
        AppDialog,
        DateCustomer,
        InputText

    },
    props: {
        dialogStatus: {
            type: Boolean,
            default: false,
        },
        item: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            loadingItemesStatus: false,
            fetchItemesApiError: {
                status: false,
                error: {},
            },
            submitOnProgress: false,
            createItemApiError: {
                status: false,
                error: {},
            },
            paths: [],
            durations: [],
            name: '',
            path: null,
            active_band: null,
            read_day: null,
            read_day_date: null,
            duration: null,
            duration_id: null,
            path_id: null,
            item2delete: null,
            band_value: 0,
            bandvalue: 0,
            is_read: 0,
            is_reads: 0,
            compleateNarrative: [
                {
                    id: 1,
                    text: 'نعم',
                    value: 'yes',
                },
                {
                    id: 2,
                    text: 'لا',
                    value: 'no',
                },
            ],
            from_verse: 0,
            fromverse: 0,
            toverse: 0,
            to_verse: 0,
            active_band_id: null,
            assistant: null,
            secretarial: null,
            readers: [],
             bandTypes: [
                {
                    id: 4,
                    text: 'تكرار',
                    value: 4
                },
                {
                    id: 5,
                    text: 'ربط',
                    value: 5
                },
                {
                    id: 6,
                    text: 'مراجعة',
                    value: 6
                }
            ],
            weekDayes: [
                {
                    id: 'saturday',
                    text: 'السبت',
                    value: 'saturday',
                },
                {
                    id: 'sunday',
                    text: 'الاحد',
                    value: 'sunday',
                },
                {
                    id: 'monday',
                    text: 'الاثنين',
                    value: 'monday',
                },
                {
                    id: 'tuesday',
                    text: 'الثلاثاء',
                    value: 'tuesday',
                },
                {
                    id: 'wednesday',
                    text: 'الاربعاء',
                    value: 'wednesday',
                },
                {
                    id: 'thursday',
                    text: 'الخميس',
                    value: 'thursday',
                },
                {
                    id: 'friday',
                    text: 'الجمعة',
                    value: 'friday',
                }
            ],
           
            rules: {
                name: {
                    required: true,
                },
                path: {
                    required: true,
                },
            },
        }
    },
    computed: {
        anyChanged() {            
            return true;
        }
    },
    methods: {
       async getPaths() {
            try {
                let res = await listPaths();
                if (res.data.status) {
                    this.paths = res.data.data;
                }
            } catch (error) {
                this.$toast.error("حدث خطأ ما");
            }
        },
        async getDurations() {
            try {
                let res = await listDurations();
                if (res.data.status) {
                    this.durations = res.data.data;
                }
            } catch (error) {
                this.$toast.error("حدث خطأ ما");
            }
        },
        async updateItem() {
            this.submitOnProgress = true;
            this.updateItemApiError = {
                status: false,
                error: {},
            };
            let item = this.item;
            let data = {};
            data.path_id =this.path;
            data.is_read =this.is_reads;
            data.active_band_id =this.active_band;
            data.from_verse =this.fromverse;
            data.to_verse =this.toverse;
            data.band_value =this.bandvalue;
            data.read_day_date = this.read_day;
            data.id = item.id;
            // data._method = 'put';

            // if (parseInt(item.duration_id) !== this.duration) data.duration_id = this.duration;
            // try {
                let res = await updateweeklyNarrative( data);
            if (res.data.status) {
                    // this.$emit('item-updated');
                } else {
                    this.$toast.error(res.data.message);
                    this.updateItemApiError.status = true;
                    this.updateItemApiError.error.message = res.data.message;
                }
            // } catch (error) {
            //     this.$toast.error('حدث خطأ ما');
            //     this.updateItemApiError.status = true;
            //     this.updateItemApiError.error.message = error.response ? error.response.data : error;
            // } finally {
            //     this.submitOnProgress = false;
            // }
        },
        resetForm() {
            let item = this.item;
            this.path = item.path_id ? parseInt(item.path_id) : item.path_id;
            this.is_reads = item.is_read;
            this.active_band = item.active_band_id;
            this.fromverse = item.from_verse;
            this.toverse = item.to_verse;
            this.bandvalue = item.band_value;
            this.read_day = item.read_day_date;
            this.duration = item.duration;
        },
        setup() {
            this.getPaths();
            this.getDurations();
            this.resetForm();
        },
    },
    created() {
        this.setup();
    }
}
</script>