<template>
  <section class="weekly-narrative">
    <v-main>
      <div class="container--fluid">
        <tabs-custom :items="items">
          <div class="all-card">
            <div class="top-bar-search">
              <div class="search-section">
                <form>
                  <div class="search">
                    <v-row>
                      <v-col cols="12" lg="5" style="padding-left: 4px">
                        <search-input :style="styleSearch">
                        </search-input>
                      </v-col>
                      <v-col cols="12" lg="7">
                        <div class="date-top">
                          <div class="container-date">
                            <date-select-modal/>
                          </div>
                          <div class="container-date">
                            <date-select-modal/>
                          </div>
                          <div class="input-search">
                            <btn-search/>
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </form>
              </div>
              <div class="import-export-file">
                <div style="display: flex">
                  <div class="export-file">
                    <import-file :icon="'fa fa-upload'" :name="'رفع ملف اكسل'"/>
                  </div>
                  <div class="import-file">
                    <import-file :icon="'fa fa-cloud-download'" :name="'تصدير الملف'"/>
                  </div>
                </div>
                <div class="add-new">
                  <dialog-modal :name-input="'إضافة سرد'" :title="'إضافة سرد اسبوعي'">
                    <div class="form-modal">
                      <validation-observer ref="form" v-slot="{ invalid, handleSubmit }" >
                        <v-form @submit.prevent="handleSubmit(createItem)" :disabled="submitOnProgress">   
                        <v-row>
                          <v-col cols="12" lg="6" md="6">
                            <select-input item-value="value" v-model="path_id"  item-text="text" @data-entered="path_id = $event" :label="'أختر المسار'" @change="updatePath()" :name="'أختر المسار'" :items="paths"/>
                          </v-col>
                          <v-col cols="12" lg="6" md="6">
                              <ValidationProvider name="أختر الفترات" :rules="rules.duration_id" v-slot="{ errors }">
                                <v-select
                                    :items="durations"
                                    item-text="text"
                                    item-value="id"
                                    placeholder="أختر الفترات"
                                    :error-messages="errors"
                                    v-model="duration_id"
                                    outlined
                                    multiple
                                    dense
                                ></v-select>
                              </ValidationProvider>
                            </v-col>
                          <!-- <v-col cols="12" lg="6" md="6">
                            <select-input item-value="value" v-model="duration_id"  item-text="text" @data-entered="duration_id = $event" :label="'أختر الفترة'" :name="'أختر الفترة'" :items="durations"/>
                          </v-col> -->
                          <v-col cols="12" lg="6" md="6">
                            <ValidationProvider name="'أختر يوم السرد'" :rules="rules.read_day_date" v-slot="{ errors }">                               
                              <date-customer item-value="value" :field-errors="errors" v-model="read_day_date"  item-text="text" @data-entered="read_day_date = $event" :label="'أختر يوم السرد'" :name-placeholder="'أختر يوم السرد'" />
                            </ValidationProvider>
                          </v-col>
                          <v-col cols="12" lg="6" md="6">
                               <ValidationProvider name="'أختر من بنود السرد'" :rules="rules.active_band_id" v-slot="{ errors }">
                                 <v-select
                                        :items="bandTypes"
                                        item-text="text"
                                        item-value="id"
                                        placeholder="أختر من بنود السرد"
                                        :error-messages="errors"
                                        v-model="active_band_id"
                                        outlined
                                        multiple
                                        dense
                                    ></v-select>        
                            </ValidationProvider>
                          </v-col>
                          <v-col cols="12" lg="6" md="6">
                            <ValidationProvider name="'اتمام السرد'" :rules="rules.is_read" v-slot="{ errors }">     
                              <select-input item-value="value" :field-errors="errors"  v-model="is_read"  item-text="text" @data-entered="is_read = $event" :label="'اتمام السرد'" :name="'نعم'" :items="compleateNarrative"/>
                            </ValidationProvider>
                          </v-col>
                          <v-col cols="12" lg="6" md="6" class="custom-input">
                            <ValidationProvider name="'مقدار البند'" :rules="rules.band_value" v-slot="{ errors }">     
                              <input-text  item-value="value" :field-errors="errors"  v-model="band_value"  item-text="text" @data-entered="band_value = $event"  :label-top="'مقدار البند'" :placeholder="'مقدار البند'"/>
                            </ValidationProvider>
                          </v-col>
                          <v-col cols="12" lg="6" md="6" class="custom-input">
                            <ValidationProvider name="'من الوجه'" :rules="rules.from_verse" v-slot="{ errors }">     
                              <input-text   item-value="value" :field-errors="errors"  v-model="from_verse"  item-text="text" @data-entered="from_verse = $event"  :label-top="'من الوجه'" :placeholder="'من الوجه'"/>
                            </ValidationProvider>
                          </v-col>
                          <v-col cols="12" lg="6" md="6" class="custom-input">
                            <ValidationProvider name="'الى الوجه'" :rules="rules.to_verse" v-slot="{ errors }">     
                              <input-text  item-value="value" :field-errors="errors"  v-model="to_verse"  item-text="text" @data-entered="to_verse = $event"   :label-top="'الى الوجه'" :placeholder="'الى الوجه'"/>
                            </ValidationProvider>
                          </v-col>
                          <v-col  cols="12">
                            <v-btn type="submit" :disabled="invalid" block color="#00B5AD" style="color: white;font-size: 15px;height: 45px">اضافة</v-btn>
                          </v-col>
                        </v-row>
                      </v-form>
                    </validation-observer>

                    </div>
                  </dialog-modal>
                </div>
              </div>
            </div>
            <v-data-iterator
                  :items="weeklyNarratives"
                  no-results-text="0 نتائج مطابقة"
                  :search="search"
                  hide-default-footer
                  :page.sync="page"
                  :items-per-page.sync="itemsPerPage"      
                  @page-count="pageCount = $event"
                  :loading="loadingweeklyNarrativesStatus"
              >
            <v-row>
              <v-col v-for="weeklyNarrative in weeklyNarratives" :key="weeklyNarrative.id" cols="12" lg="4" md="6">
                <card-narrative
                 :weeklyNarrative="weeklyNarrative"
                  @delete-btn-clicked="openDeleteItem(weeklyNarrative.id)" 
                  @edit-btn-clicked="openEditweeklyNarratives(weeklyNarrative)" 

                />
              </v-col>
            </v-row>
          </v-data-iterator>
          </div>
        </tabs-custom>
        <pagination-components />
      </div>
    </v-main>
    <confirmation-dialog ref="deleteItemConfirmationDialog" heading="تأكيد عملية حذف الفصل" message='لن تتمكن من استرجاعه مجدداً' @onConfirm="deleteItem"></confirmation-dialog>
        
       <edit-item v-if="itemDialogStatus" :dialog-status="itemDialogStatus" @close-edit-item="closeEditItemDialog" @item-updated="itemUpdated" :item="item2edit"></edit-item>
  </section>
</template>

<script>
import PaginationComponents from "@/components/dashboard/paginationComponents";
import TabsCustom from "@/components/tabsCustom";
import ImportFile from "@/components/import-file";
import DialogModal from "@/components/dialogModal";
import CardNarrative from "@/components/cards/cardNarrative";
import SelectInput from "@/components/select-input";
import DateSelectModal from "@/components/dashboard/dateSelectModal";
import SearchInput from "@/components/search-input";
import BtnSearch from "@/components/btnSearch";
import DateCustomer from "@/components/date-customer";
import InputText from "@/components/input-text";
import { fetchweeklyNarratives, createweeklyNarrative, deleteweeklyNarrative, listDurations, listPaths } from "Actions";
import EditItem from './EditItem.vue';


export default {
  name: "weeklyNarrativesView",
  components: {
    InputText,
    BtnSearch,
    SelectInput,
    DateCustomer,
    EditItem,
    CardNarrative,
    DialogModal, ImportFile, DateSelectModal, SearchInput, TabsCustom, PaginationComponents
  },
  data() {
    return {
      items: [
        'ربط ', 'مراجعه', 'تكرار',
      ],
      styleSearch: {
        backgroundColor: 'transparent',
        border: "1px solid #E2E2EA",
        borderRadius: "10px"
      },
      duration: [],
      durations: [],
      itemDialogStatus: false,
      bandTypes: [
         
      ],
      weekDayes: [
        {
          id:'saturday',
          text:'السبت',
          value:'saturday',
        },
        {
          id:'sunday',
          text:'الاحد',
          value:'sunday',
        },
        {
          id:'monday',
          text:'الاثنين',
          value:'monday',
        },
        {
          id:'tuesday',
          text:'الثلاثاء',
          value:'tuesday',
        },
        {
          id:'wednesday',
          text:'الاربعاء',
          value:'wednesday',
        },
        {
          id:'thursday',
          text:'الخميس',
          value:'thursday',
        },
        {
          id:'friday',
          text:'الجمعة',
          value:'friday',
        }
      ],
      paths: [],
      name: '',
      loadingweeklyNarrativesStatus: false,
      read_day_date: null,
      read_dadate: this.getweeklyNarratives(),
      duration_id: null,
      pathe: this.getPaths(),
      path_id: null,
      item2delete: null,
      band_value: 0,
      is_read: 0,
      from_verse: 0,
      to_verse: 0,
      active_band_id: null,
      weeklyNarratives: [],
      itemsPerPage: 4,
      page: 1,
      pageCount: 0,
      search: '',
      submitOnProgress: false,
      compleateNarrative: [
        {
          id: 1,
          text: 'نعم',
          value: 'yes',
        },
        {
          id: 2,
          text: 'لا',
          value: 'no',
        },
      ],
      rules: {
        name: {
          required: true,
        },
        path_id: {
          required: true,
        },
        duration_id: {
          required: true,
        },
        read_day_date: {
          required: true,
        },
      }
    }
  },
  methods: {
    async getweeklyNarratives() {
      this.loadingweeklyNarrativesStatus = true;
      this.fetchweeklyNarrativesApiError = {
        status: false,
        error: {},
      };
      let query = '';
      try {
        let res = await fetchweeklyNarratives(query);
        if (res.data.status) {
          this.weeklyNarratives = res.data.data.data;
        } else {
          this.fetchweeklyNarrativesApiError.status = true;
          this.fetchweeklyNarrativesApiError.error.message = res.data.message;
        }
      } catch (error) {
        this.fetchweeklyNarrativesApiError.status = true;
        this.fetchweeklyNarrativesApiError.error.message = error.response ? error.response.data : error;
      } finally {
        this.loadingweeklyNarrativesStatus = false;
      }
    },
    async createItem() {
      this.submitOnProgress = true;

      this.createweeklyNarrativesApiError = {
        status: false,
        error: {},
      };
      
      let formData = new FormData();
      formData.append("path_id", this.path_id);
      formData.append("duration_id", this.duration_id);
      formData.append("read_day_date", this.read_day_date);
      formData.append("band_value", this.band_value);
      formData.append("active_band_id", this.active_band_id);
      formData.append("is_read", this.is_read);
      formData.append("from_verse", this.from_verse);
      formData.append("to_verse", this.to_verse);
      try {
        let res = await createweeklyNarrative(formData);
        if (res.data.status) {
          this.resetFields();
          this.itemUpdated();
        } else {
          if (res.code == 422) {
            this.$toast.error(res.message);
            this.createweeklyNarrativesApiError.status = true;
            this.createweeklyNarrativesApiError.error.message = res.message;
          } else {
            this.$toast.error(res.data.message);
            this.createweeklyNarrativesApiError.status = true;
            this.createweeklyNarrativesApiError.error.message = res.data.message;
            
          }
        }
      } catch (error) {
        this.$toast.error('فشلت العملية');

        this.createweeklyNarrativesApiError.status = true;
        this.createweeklyNarrativesApiError.error.message = error.response ? error.response.data : error;
      } finally {
        this.submitOnProgress = false;
      }
    },
    openEditweeklyNarratives(item) {
      this.item2edit = item;
      this.itemDialogStatus = true;
      console.log(item);
    },
    openDeleteItem(item) {
      this.$refs.deleteItemConfirmationDialog.openDialog();
      this.item2delete = item;
    },
    async deleteItem() {
      this.$refs.deleteItemConfirmationDialog.close();
      try {
        let res = await deleteweeklyNarrative(this.item2delete);
        if (res.data.status) {
          this.item2delete = null;
          this.$toast.success('تمت العملية بنجاح');
          this.getweeklyNarratives();
        } else {
          this.$toast.error(res.data.message);
        }
      } catch (error) {
        this.$toast.error("حدث خطأ ما");
      }
    },
    async getDurations() {
      try {
        let res = await listDurations(this.path_id);
        if (res.data.status) {
          this.durations = res.data.data;
        } 
      } catch (error) {
        this.$toast.error("حدث خطأ ما");
      }
    },
    async getPaths() {
      try {
        let res = await listPaths();
        if (res.data.status) {
          this.paths = res.data.data;
        } 
      } catch (error) {
        this.$toast.error("حدث خطأ ما");
      } 
    },
   
    itemUpdated() {
      this.closeEditItemDialog();
      this.$toast.success('تمت العملية بنجاح');
      this.getweeklyNarratives();
    },
    closeEditItemDialog() {
      this.itemDialogStatus = false;
      this.item2edit = null;
    },
    resetFields() {
      this.name = "";
      this.path_id=null;
      this.duration_id=null;
      this.read_day_date=null;
      this.band_value=null;
      this.active_band_id=null;
      this.is_read=null;
      this.from_verse=null;
      this.to_verse=null;
      this.$nextTick(() => {
        this.$refs.form.reset();
      });
    },
    updatePath() {
      this.duration_id = null;
      this.active_band_id = null;
      this.durations = [];
      this.bandTypes =this.paths.find(x => x.id == this.path_id).band_type;
      this.getDurations();
    },
    searchChanged(val) {
      this.search = val.data
    },
 
    setup() {
      this.getweeklyNarratives();
      this.getDurations();
      this.getPaths();
      this.getBands();
    },
  },
  beforeMount() {
    this.$store.dispatch('settings/pageTitle', 'السرد الأسبوعي');
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/variable.scss";

.weekly-narrative {
  background-color: $background-main-page;
  padding: 40px 15px;

  .container--fluid {
    border-radius: 15px;
    background-color: white;
    padding: 15px
  }

  .top-bar-search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    @media only screen and (max-width: 1200px) {
      flex-direction: column;
    }

    .search-section {
      width: 55%;
      @media only screen and (max-width: 1200px) {
        width: 100%;
      }

      .date-top {
        display: flex;
        align-items: center;
        @media only screen and (max-width: 1200px) {
          .container-date {
            width: 33%;

            .theme--light.v-input {
              width: 100%;
            }
          }
          .input-search {
            width: 33%;
          }
        }
      }

      .search {
        display: flex;
        align-items: center;
        padding: 12px 0;

        button {
          border: 1px solid #00B5AD;
          padding: 10px 40px;
          font-size: 14px;
          border-radius: 10px;
          color: #00B5AD;
          margin-right: 10px;
        }

      }
    }

  }

  .import-export-file {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    @media only screen and (max-width: 1200px) {
      width: 100%;
      div {
        width: 50%;
        label {
          width: 100%;
          display: inline-block;
        }
      }
      .export-file, .import-file {
        margin-top: 8px;
        text-align: center;
      }
      .text-center{
        width: 100%;
      }
    }
    @media only screen and (max-width: 800px) {
      width: 100%;
      display: unset;
      div {
        width: 100%;
      }
    }
    div {
      margin: 0 5px;
      @media only screen and (max-width: 800px) {
        margin: 0 0px;
        padding: 0 5px;

      }
    }
  }

}

</style>
