<template>
  <section class="vacations-student">
    <v-main>
      <div class="container--fluid" style="">
        <div class="all-card">
          <div class="top-bar-search">
            <div class="search-section">
              <form>
                <div class="search">
                  <v-row>
                    <v-col cols="12" lg="5" style="padding-left: 4px">
                      <search-input :style="styleSearch"/>
                    </v-col>
                    <v-col cols="12" lg="7">
                      <div class="date-top">
                        <div class="container-date">
                          <date-select-modal/>
                        </div>
                        <div class="container-date">
                          <date-select-modal/>
                        </div>
                        <div class="input-search">
                          <btn-search/>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </form>
            </div>
            <div class="import-export-file">
              <div style="display: flex">
                <div class="export-file">
                  <import-file :icon="'fa fa-upload'" :name="'رفع ملف اكسل'"/>
                </div>
                <div class="import-file">
                  <import-file :icon="'fa fa-cloud-download'" :name="'تصدير الملف'"/>              </div>
              </div>
              <div class="add-new">
                <dialog-modal :name-input="' إضافة اجازة'" :title="'إضافة اجازة جديد'">
                  <div class="form-modal">
                     <validation-observer ref="form" v-slot="{ invalid, handleSubmit }" >
                        <v-form @submit.prevent="handleSubmit(createItem)" :disabled="submitOnProgress">                      
                        <v-row>
                        <v-col cols="12" lg="6" md="6">
                          <ValidationProvider name="' الاسم'" :rules="rules.name" v-slot="{ errors }">
                            <input-text  v-model="name"  item-text="text" @data-entered="name = $event" :field-errors="errors" :label-top="' الاسم'"  :placeholder="' الاسم'"  />
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="12" lg="6" md="6">
                          <ValidationProvider name="'أختر من هنا الطالب'" :rules="rules.student_id" v-slot="{ errors }">
                            <select-input item-value="value" v-model="student_id"  item-text="text" @data-entered="student_id = $event" :field-errors="errors" :label="'أختر من هنا الطالب'" :name="'أختر من هنا الطالب'" :items="studentsList"/>
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="12" lg="6" md="6">
                          <ValidationProvider name="'أختر المسار'" :rules="rules.path_id" v-slot="{ errors }">
                            <select-input  item-value="value" v-model="path_id"  item-text="text" @data-entered="path_id = $event" :field-errors="errors" :label="'أختر المسار'" :name="'أختر المسار'" :items="paths"/>
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="12" lg="6" md="6">
                          <ValidationProvider name="'أختر فترة الاجازة'" :rules="rules.duration_id" v-slot="{ errors }">
                            <select-input item-value="value" v-model="duration_id" :field-errors="errors" item-text="text" @data-entered="duration_id = $event" :label="'أختر الفترة الاجازة'" :name="'أختر الفترة الاجازة'" :items="durations"/>
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="12" lg="6" md="6">
                          <ValidationProvider name="' نوع الاجازة '" :rules="rules.holidayType" v-slot="{ errors }">
                            <input-text  v-model="holidayType"  item-text="text" @data-entered="holidayType = $event" :field-errors="errors" :label-top="' نوع الاجازة '"  :placeholder="' نوع الاجازة '"  />
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="12" lg="6" md="6">
                          <ValidationProvider name="'اختر بداية الاجازة'" :rules="rules.startDate" v-slot="{ errors }">
                            <date-customer  v-model="startDate" item-text="date" @data-entered="startDate = $event" :field-errors="errors"  :label="'اختر بداية الاجازة'" :name-placeholder="'اختر بداية الاجازة'" :name="'اختر بداية الاجازة'" />
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="12" lg="6" md="6">
                          <ValidationProvider name="'اختر نهاية الاجازة'" :rules="rules.endtDate" v-slot="{ errors }">
                            <date-customer  v-model="endtDate" item-text="date" @data-entered="endtDate = $event" :field-errors="errors"  :label="'اختر نهاية الاجازة'" :name-placeholder="'اختر نهاية الاجازة'"/>
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="12">
                          <ValidationProvider name="'ملاحظات'" :rules="rules.notes" v-slot="{ errors }">
                            <text-area  v-model="notes" item-text="text" @data-entered="notes = $event" :field-errors="errors"  :label="'ملاحظات'" :placeholder="'ملاحظات'"/>
                          </ValidationProvider>
                        </v-col>
                        
                        <v-col cols="12">
                              <v-btn type="submit" :disabled="invalid" block color="#00B5AD" style="color: white;font-size: 15px;height: 45px">اضافة</v-btn>
                        </v-col>
                      </v-row>
                      </v-form>
                    </validation-observer>
                  </div>
                </dialog-modal>
              </div>
            </div>
          </div>
          <v-row>
            <v-col v-for="student in itemData" :key="student.id" cols="12" lg="4" md="6">
              <card-follow
                  :student_name="student.student"
                  :name="student.name"
                  :path="student.path"
                  :holidayType="student.type"
                  :description="student.note"
                  :duration="student.duration"
                  :fromDate="student.from"
                  :toDate="student.to"
                  :status="student.status"
                  :show-vacations-student="true" :show-follow-student="false"
                   @delete-btn-clicked="openDeleteItem(student.id)" 
                  />
            </v-col>
          </v-row>
        </div>
        <v-pagination class="my-4"
              v-model="page"
              v-if="pageCount > 1"
              :length="pageCount"
              :total-visible="itemsPerPage" >
        </v-pagination>
      </div>
    </v-main>
        <confirmation-dialog ref="deleteItemConfirmationDialog" heading="تأكيد عملية حذف الاجازة" message='لن تتمكن من استرجاعه مجدداً' @onConfirm="deleteItem"></confirmation-dialog>

  </section>
</template>

<script>
// import PaginationComponents from "@/components/dashboard/paginationComponents";

import SearchInput from "@/components/search-input";
import DateSelectModal from "@/components/dashboard/dateSelectModal";
import ImportFile from "@/components/import-file";
import DialogModal from "@/components/dialogModal";
import SelectInput from "@/components/select-input";
import CardFollow from "@/components/cards/cardFollow";
import DateCustomer from "@/components/date-customer";
import TextArea from "@/components/textArea";
import BtnSearch from "@/components/btnSearch";
import InputText from "@/components/input-text";
import { createStudentHoliday, deleteStudentHoliday, listDurations, listPaths, listBandType, listStudents } from "Actions";
import { fetchStudentHoliday } from '@/api/Actions';

export default {
  name: "studentVacationsView",
  components: {
    BtnSearch,
    TextArea,
    InputText,
    DateCustomer,
    CardFollow,
    SelectInput,
    DialogModal, ImportFile, DateSelectModal, SearchInput // , PaginationComponents
  },
  data() {
    return {
      styleSearch: {
        backgroundColor: 'transparent',
        border: "1px solid #E2E2EA",
        borderRadius: "10px"
      },
      durations: [],
      bandTypes: [],
      studentsList: [],
      paths: [],
      durationss: this.getDurations(),
      bandTypess: this.getStudents(),
      studentsLists: this.getBands(),
      pathss: this.getPaths(),
      pathsds: this.getItems(),
      name: '',
      duration_id: null,
      path_id: null,
      item2delete: null,
      holidayType: null,
      startDate: null,
      endtDate: null,
      notes: null,
      student_id: null,
      itemData: [],
      itemsPerPage: 10,
      page: 0,
      pageCount: 0,
      search: '',
      submitOnProgress: false,
      compleateBoolean: [
        {
          id: 1,
          text: 'نعم',
          value: 'yes',
        },
        {
          id: 2,
          text: 'لا',
          value: 'no',
        },
      ],
      rules: {
        name: {
          required: true,
        },
        path_id: {
          required: true,
        },
        duration_id: {
          required: true,
        },
        holidayType: {
          required: true,
        },
        notes: {
          required: true,
        },
        startDate: {
          required: true,
        },
        endtDate: {
          required: true,
        }
      }
    }
  },
  computed: {
  },
  methods: {
    async getItems() {
      this.loadingItemsStatus = true;
      this.fetchItemsApiError = {
        status: false,
        error: {},
      };
      try {
                let res = await fetchStudentHoliday();

        // let res = await getMethods('holidays');
        console.log(res.data);
        if (res.data.status) {
          this.itemData = res.data.data.data;
          this.pageCount = res.data.data.meta.lastPage ;
          this.itemsPerPage = res.data.data.meta.total;
        } else {
          this.fetchItemsApiError.status = true;
          this.fetchItemsApiError.error.message = res.data.message;
        }
        console.log(this.pageCount);
        console.log(this.itemsPerPage);
      } catch (error) {
        this.fetchItemsApiError.status = true;
        this.fetchItemsApiError.error.message = error.response ? error.response.data : error;
      } finally {
        this.loadingItemsStatus = false;
      }
    },
    async createItem() {
      this.submitOnProgress = true;
      this.createItemsApiError = {
        status: false,
        error: {},
      };
      let formData = new FormData();
      formData.append("name", this.name);
      formData.append("path_id", this.path_id);
      formData.append("student_id", this.student_id);
      formData.append("duration_id", this.duration_id);
      formData.append("type", this.holidayType);
      formData.append("from", this.startDate);
      formData.append("to", this.endtDate);
      formData.append("note", this.notes);

      try {
        let res = await createStudentHoliday(formData);
        if (res.data.status) {
          this.resetFields();
          this.itemUpdated();
        } else {
          this.$toast.error(res.data.message);
          this.createItemsApiError.status = true;
          this.createItemsApiError.error.message = res.data.message;
        }
      } catch (error) {
        this.$toast.error('فشلت العملية');

        this.createItemsApiError.status = true;
        this.createItemsApiError.error.message = error.response ? error.response.data : error;
      } finally {
        this.submitOnProgress = false;
      }
    },
    openEditItem(item) {
      this.item2edit = item;
      this.ediitemsDialogStatus = true;
    },
    openDeleteItem(item) {
      this.$refs.deleteItemConfirmationDialog.openDialog();
      this.item2delete = item;
    },
    async deleteItem() {
      this.$refs.deleteItemConfirmationDialog.close();
      try {
        let res = await deleteStudentHoliday(this.item2delete);
        if (res.data.status) {
          this.item2delete = null;
          this.$toast.success('تمت العملية بنجاح');
          this.getItems();
        } else {
          this.$toast.error(res.data.message);
        }
      } catch (error) {
        this.$toast.error("حدث خطأ ما");
      }
    },
    async getDurations() {
      try {
        let res = await listDurations();
        if (res.data.status) {
          this.durations = res.data.data;
        }
      } catch (error) {
        this.$toast.error("حدث خطأ ما");
      }
    },
    async getPaths() {
      try {
        let res = await listPaths();
        if (res.data.status) {
          this.paths = res.data.data;
        }
      } catch (error) {
        this.$toast.error("حدث خطأ ما");
      }
    },
    async getStudents() {
      try {
        let res = await listStudents();
        if (res.data.status) {
          this.studentsList = res.data.data;
        }
      } catch (error) {
        this.$toast.error("حدث خطأ ما");
      }
    },
    async getBands() {
      try {
        let res = await listBandType();
        if (res.data.status) {
          this.bandTypes = res.data.data;
        }
      } catch (error) {
        this.$toast.error("حدث خطأ ما");
      }

    },
    itemUpdated() {
      // this.closeEdiitemsDialog();
      this.$toast.success('تمت العملية بنجاح');
      this.getItems();
    },
    closeEdiitemsDialog() {
      this.ediitemsDialogStatus = false;
      this.item2edit = null;
    },
    resetFields() {
      this.name = "";
      this.path_id = null;
      this.duration_id = null;
      this.read_day_date = null;
      this.band_value = null;
      this.student_id = null;
      this.is_read = null;
      this.from_verse = null;
      this.to_verse = null;
      this.$nextTick(() => {
        this.$refs.form.reset();
      });
    },
    searchChanged(val) {
      this.search = val.data
    },
    setup() {
      this.getItems();
      this.getDurations();
      this.getPaths();
      this.getBands();
      this.getStudents();
    },
  },
  beforeMount() {
    this.$store.dispatch('settings/pageTitle', 'اجازات الطلاب');
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/variable.scss";

.vacations-student {
  background-color: $background-main-page;
  padding: 40px 15px;

  .container--fluid {
    border-radius: 15px;
    background-color: white;
    padding: 15px
  }

  .top-bar-search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    @media only screen and (max-width: 1200px) {
      flex-direction: column;
    }

    .search-section {
      width: 55%;
      @media only screen and (max-width: 1200px) {
        width: 100%;
      }

      .date-top {
        display: flex;
        align-items: center;
        @media only screen and (max-width: 1200px) {
          .container-date {
            width: 33%;

            .theme--light.v-input {
              width: 100%;
            }
          }
          .input-search {
            width: 33%;
          }
        }
      }

      .search {
        display: flex;
        align-items: center;
        padding: 12px 0;

        button {
          border: 1px solid #00B5AD;
          padding: 10px 40px;
          font-size: 14px;
          border-radius: 10px;
          color: #00B5AD;
          margin-right: 10px;
        }

      }
    }

  }

  .import-export-file {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    @media only screen and (max-width: 1200px) {
      width: 100%;
      div {
        width: 50%;
        label {
          width: 100%;
          display: inline-block;
        }
      }
      .export-file, .import-file {
        margin-top: 8px;
        text-align: center;
      }
      .text-center{
        width: 100%;
      }
    }
    @media only screen and (max-width: 800px) {
      width: 100%;
      display: unset;
      div {
        width: 100%;
      }
    }

    div {
      margin: 0 5px;
      @media only screen and (max-width: 800px) {
        margin: 0 0px;
        padding: 0 5px;

      }
    }
  }

}

</style>
<style lang="scss">
  .form-modal .custom-input label {
    padding-right: 10px;
    margin-bottom: 10px;
    top: -13px;
  }
</style>
