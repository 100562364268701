<template>
  <section class="admission-requests">
    <v-main>
      <div class="container--fluid">
        <div class="all-card">
          <div class="top-bar-search">
            <div class="search-section">
              <form>
                <div class="search">
                  <v-row>
                    <v-col cols="12" lg="5" style="padding-left: 4px">
                      <search-input :style="styleSearch">
                      </search-input>
                    </v-col>
                    <v-col cols="12" lg="7">
                      <div class="date-top">
                        <div class="container-date">
                          <date-select-modal/>
                        </div>
                        <div class="container-date">
                          <date-select-modal/>
                        </div>
                        <div class="button-search">
                          <button style="width: 100%">بحث</button>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </form>
            </div>
            <div class="import-export-file">
              <div style="display: flex">
                <div class="export-file">
                  <import-file :icon="'fa fa-upload'" :name="'رفع ملف اكسل'"/>
                </div>
                <div class="import-file">
                  <import-file :icon="'fa fa-cloud-download'" :name="'تصدير الملف'"/>
                </div>
              </div>
              <div class="add-new">
                <dialog-modal :name-input="'تحديد موعد مقابلة'" :title="'تحديد موعد مقابلة'">
                  <div class="form-modal">
                    <validation-observer  v-slot="{ invalid, handleSubmit }" >
                      <v-form @submit.prevent="handleSubmit(createAdminssion)" :disabled="submitOnProgress">
                        <v-row>
                          <v-col cols="12" lg="6" md="6">
                            <select-input :label="'اسم الطالب'" v-model="student_id" :name="'أختر من هنا اسم الطالب'"   item-text="text"
                                      item-value="id" :items="students"/>
                          </v-col>
                          <v-col cols="12" lg="6" md="6">
                            <select-input :label="'نموذج اسئلة المقابلة'" v-model="template_id" :name="'أختر نموذج اسئلة المقابلة'"   item-text="name"
                                      item-value="id" :items="templates"/>
                          </v-col>
                          <v-col cols="12" lg="6" md="6">
                            <ValidationProvider name="تاريخ التسميع" :rules="rules.appointment_date" v-slot="{ errors }">
                              <input-text  field-type="date" v-model="appointment_date" :fieldErrors="errors" :label-top="'تاريخ التسميع'" :placeholder="'تاريخ التسميع'"/>
                            </ValidationProvider>   
                          </v-col>
                         
                          <v-col cols="12" lg="6" md="6">
                            <ValidationProvider name="وقت التسميع" :rules="rules.conserve_time" v-slot="{ errors }">
                              <input-text  field-type="time" v-model="conserve_time" :fieldErrors="errors" :label-top="'وقت التسميع'" :placeholder="'وقت التسميع'"/>
                            </ValidationProvider>                          
                          </v-col>
                          
                          <v-col cols="12" lg="6" md="6">
                            <select-input :label="'كيفية التسميع'" v-model="type"  :name="'أونلاين'" item-value="id" item-text="text" :items="types"/>
                          </v-col>
                         
                          <v-col cols="12">
                            <v-btn block color="#00B5AD" :disabled="invalid" type="submit" style="color: white;font-size: 15px;height: 45px">اضافة</v-btn>
                          </v-col>
                        </v-row>
                      </v-form>
                    </validation-observer>
                  </div>
                </dialog-modal>
              </div>
            </div>
          </div>
          <v-row>
            <v-col v-for="appointment in appointments" :key="appointment.id" cols="12" lg="4" md="6">
              <card-inter-view
                  :name="appointment.student_name"
                  :class-name="appointment.path"
                  :statusStudent="appointment.status_text"
                  :items-path-array="appointment.detailsAdmission"
                  :status="appointment.status"
                  :idCardStudent="appointment.id"
                  :show-btn="false"
                  :show-edit-delete="true"
                  :student="appointment"
                  @edit-btn-clicked="openEditITem(appointment)"
                  @delete-btn-clicked="openDeleteITem(appointment.id)"
              />
            </v-col>
          </v-row>
        </div>
        <pagination-components/>
            <confirmation-dialog ref="deleteItemConfirmationDialog" heading="تأكيد عملية المقابلة الفرع" message='لن تتمكن من استرجاعه مجدداً' @onConfirm="deleteItem"></confirmation-dialog>

      </div>
    </v-main>
  </section>
</template>

<script>
import PaginationComponents from "@/components/dashboard/paginationComponents";
import SearchInput from "@/components/search-input";
import DateSelectModal from "@/components/dashboard/dateSelectModal";
import ImportFile from "@/components/import-file";
import DialogModal from "@/components/dialogModal";
import InputText from "@/components/input-text";
import SelectInput from "@/components/select-input";
import {mapGetters} from "vuex";
import CardInterView from "@/components/cards/card-interView";
import { getMethods, postMethods } from 'Helper';
import { deleteItem } from 'Actions';
import { listInterviewTemplates } from '@/api/Actions';

export default {
  name: "admissionRequestsView",
  components: {
    CardInterView,
    SelectInput,
    DialogModal, ImportFile, DateSelectModal, SearchInput, PaginationComponents,
    InputText
  },
  data() {
    return {
      items: [
        'ربط ', 'مراجعه', 'تكرار',
      ],
      locations: [
        
        'اون لاين ', 'حضورية',
      ],
      submitOnProgress: false,
      student_id: null,
      day: null,
      perfection_value: null,
      supervisor_message: null,
      conservePartsOptions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30],
      templates:[],
      template_id: null,
      types: [
        {
          id:'online',
          text:'اونلاين ',
        },
        {
          id:'in_location',
          text:'حضورية',
        }
      ],
      days: [{value: "Sunday",text: "الاحد"},{value: "Monday",text: "الاثنين"},{value: "Tuesday",text: "الثلاثاء"},{value: "Wednesday",text: "الاربعاء"},{value: "Thursday",text: "الخميس"},{value: "Friday",text: "الجمعة"},{value: "Saturday",text: "السبت"}],
      location:null,
      appointment_date:null,
      type:null,
      conserve_time: null,
      item2delete: null,
      start_verse:null,
      styleSearch: {
        backgroundColor: 'transparent',
        border: "1px solid #E2E2EA",
        borderRadius: "10px",

      },
      appointments: [],
      students: [],
      rules: {
        conserve_time: {
          required: true,
        },
        day: {
          required: true,
        },
       start_verse: {
          required: true,
        },
        perfection_value: {
          required: true,
        },
        supervisor_message: {
          required: true,
        },
      }
    }
  },
  computed: {
    ...mapGetters('settings', ['allInterview'])
  },
  methods: {
    async getItems() {
      try {
        let res = await getMethods('meeting-appointments');
        this.appointments = res.data.data;
      } catch (error) {
        console.log(error)
      }
    },
    async getStudents() {
      try {
        let res = await getMethods('students/list')
        this.students = res.data.data;
      } catch (error) {
        console.log(error)
      }
    },
    async getTemplate() {
      try {
        let res = await listInterviewTemplates('interview')
        this.templates = res.data.data;
      } catch (error) {
        console.log(error)
      }
    },
     async createAdminssion() {
      try {
        let formData = new FormData();
        formData.append("student_id", this.student_id);
        formData.append("template_id", this.template_id);
        formData.append("type", this.type);
        formData.append("appointment_date", this.appointment_date);
        formData.append("time", this.conserve_time);
        let res = await postMethods('meeting-appointments',formData);
        if (res.data.status) {
          this.$toast.success("تمت العملية بنجاح");
          this.getItems();

        } else {
          this.$toast.error(res.data.message);
        }
      } catch (error) {
        this.$toast.error('فشلت العملية');
      } finally {
        this.submitOnProgress = false;
      }
    },
     openDeleteITem(item) {
      this.item2delete = item;
      this.$refs.deleteItemConfirmationDialog.openDialog();
    },
    async deleteItem() {

      this.$refs.deleteItemConfirmationDialog.close();

      try {
        let res = await deleteItem(this.item2delete,'meeting-appointments');
        if (res.data.status) {
          this.item2delete = null;
          this.$toast.success('تمت العملية بنجاح');
          this.getItems();
        } else {
          this.$toast.error(res.data.message);
        }
      } catch (error) {
        this.$toast.error("حدث خطأ ما");
      }
    },
  },
  beforeMount() {
    this.$store.dispatch('settings/pageTitle', 'طلبات القبول')
  },
  created() {
    this.getItems();
    this.getStudents();
    this.getTemplate();
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/variable.scss";

.admission-requests {
  background-color: $background-main-page;
  padding: 40px 15px;

  .container--fluid {
    border-radius: 15px;
    background-color: white;
    padding: 15px
  }

  .top-bar-search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    @media only screen and (max-width: 1300px) {
      flex-direction: column;
    }


    .search-section {
      width: 55%;
      @media only screen and (max-width: 1300px) {
        width: 100%;
      }

      .date-top {
        display: flex;
        align-items: center;
        @media only screen and (max-width: 1300px) {
          .button-search{
            width: 33%;
          }
          .container-date {
            width: 33%;

            .theme--light.v-input {
              width: 100%;
            }
          }
          .input-search {
            width: 33%;
          }
        }
      }

      .search {
        display: flex;
        align-items: center;
        padding: 12px 0;

        button {
          border: 1px solid #00B5AD;
          padding: 10px 40px;
          font-size: 14px;
          border-radius: 10px;
          color: #00B5AD;
          margin-right: 10px;
        }

      }
    }

  }

  .import-export-file {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    @media only screen and (max-width: 1300px) {
      width: 100%;
      div {
        width: 50%;
        label {
          width: 100%;
          display: inline-block;
        }
      }
      .export-file, .import-file {
        margin-top: 8px;
        text-align: center;
      }
      .text-center{
        width: 100%;
      }
    }
    @media only screen and (max-width: 800px) {
      width: 100%;
      display: unset;
      div {
        width: 100%;
      }
    }


    div {
      margin: 0 5px;
      @media only screen and (max-width: 800px) {
        margin: 0 0px;
        padding: 0 5px;

      }
    }
  }

}

</style>
