<template>

    <app-dialog @close-edit-branch="$emit('close-edit-branch')" title="تعديل الفرع" event-name="close-edit-branch" :dialog-status="dialogStatus" max-width="750px">

        <validation-observer  v-slot="{ invalid, handleSubmit }" >

            <v-form @submit.prevent="handleSubmit(updateBranch)" :disabled="submitOnProgress">
                <v-row>
                    <v-col cols="12">
                    <ValidationProvider name="إسم الفرع" :rules="rules.name" v-slot="{ errors }">
                        <v-text-field v-model="name" :error-messages="errors" dense outlined placeholder="اكتب اسم الفرع"></v-text-field>
                    </ValidationProvider>
                    </v-col>
                    <v-col cols="12" lg="6" md="6">
                    <ValidationProvider name="مدير الفرع" :rules="rules.manager" v-slot="{ errors }">
                        <v-select
                            :items="employees"
                            item-text="full_name"
                            item-value="id"
                            placeholder="مدير الفرع"
                            :error-messages="errors"
                            v-model="manager"
                            outlined
                            dense
                        ></v-select>
                    </ValidationProvider>
                    </v-col>
                    <v-col cols="12" lg="6" md="6">
                    <ValidationProvider name="المساعد" :rules="rules.assistant" v-slot="{ errors }">
                        <v-select
                            :items="employees"
                            item-text="full_name"
                            item-value="id"
                            placeholder="المساعد"
                            :error-messages="errors"
                            v-model="assistant"
                            outlined
                            dense
                        ></v-select>
                    </ValidationProvider>
                    </v-col>
                    <v-col cols="12" lg="6" md="6">
                    <ValidationProvider name="سكرتارية" :rules="rules.secretarial" v-slot="{ errors }">
                        <v-select
                            :items="employees"
                            item-text="full_name"
                            item-value="id"
                            placeholder="سكرتارية"
                            :error-messages="errors"
                            v-model="secretarial"
                            outlined
                            dense
                        ></v-select>
                    </ValidationProvider>
                    </v-col>
                    <v-col cols="12" lg="6" md="6">
                    <ValidationProvider name="المشرفين" :rules="rules.supervisors" v-slot="{ errors }">
                        <v-select
                            :items="employees"
                            item-text="full_name"
                            item-value="id"
                            placeholder="المشرفين"
                            :error-messages="errors"
                            v-model="supervisors"
                            outlined
                            multiple
                            dense
                        ></v-select>
                    </ValidationProvider>
                    </v-col>

                    <v-col cols="12" lg="6" md="6">
                    <ValidationProvider name="المقرئين" :rules="rules.readers" v-slot="{ errors }">
                        <v-select
                            :items="employees"
                            item-text="full_name"
                            item-value="id"
                            placeholder="المقرئين"
                            :error-messages="errors"
                            v-model="readers"
                            outlined
                            multiple
                            dense
                        ></v-select>
                    </ValidationProvider>
                    </v-col>

                    <v-col cols="12">
                    <v-btn :disabled="invalid || !anyChanged" type="submit" block color="#00B5AD" style="color: white;font-size: 15px;height: 45px">تعديل</v-btn>
                    </v-col>
                </v-row>
            </v-form>

        </validation-observer>

    </app-dialog>
</template>

<script>

import AppDialog from 'Components/AppDialog/AppDialog.vue';
import { updateBranch, fetchEmployees } from 'Actions';

export default {
    components: {
        AppDialog,
    },
    props: {
        dialogStatus: {
            type: Boolean,
            default: false,
        },
        branch: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            loadingBranchesStatus: false,
            fetchBranchesApiError: {
                status: false,
                error: {},
            },
            submitOnProgress: false,
            createBranchApiError: {
                status: false,
                error: {},
            },
            employees: [],
            name: '',
            manager: null,
            assistant: null,
            secretarial: null,
            supervisors: [],
            readers: [],
            loadingEmployeesStatus: true,
            fetchEmployeesApiError: {
                status: false,
                error: {},
            },
            rules: {
                name: {
                    required: true,
                },
                // manager: {
                //     required: true,
                // },
                // assistant: {
                //     required: true,
                // },
                // secretarial: {
                //     required: true,
                // },
                // supervisors: {
                //     required: true,
                // },
                // readers: {
                //     required: true,
                // },
            },
        }
    },
    computed: {
        anyChanged() {
            let branch = this.branch;
            if (
                this.name === branch.name &&
                this.manager === branch.manger_id &&
                this.assistant === branch.assistant_id &&
                this.secretarial === branch.secretarial_id &&
                this.supervisors.length === branch.supervisor.length && 
                (this.supervisors.every(function(value, index) { return value === branch.supervisor[index]})) &&
                this.readers.length === branch.reader.length && 
                (this.readers.every(function(value, index) { return value === branch.reader[index]}))
            ) {
                return false;
            }

            return true;
        }
    },
    methods: {
        containsKey(obj, key) {
            return Object.keys(obj).includes(key);
        },
        async getEmployees() {
            this.loadingEmployeesStatus= true;
            this.fetchEmployeesApiError = {
                status: false,
                error: {},
            };
            try {
                let res = await fetchEmployees();
                if (res.data.status) {
                    this.employees = res.data.data;
                } else {
                    this.fetchEmployeesApiError.status = true;
                    this.fetchEmployeesApiError.error.message = res.data.message;
                }
            } catch (error) {
                this.fetchEmployeesApiError.status = true;
                this.fetchEmployeesApiError.error.message = error.response ? error.response.data : error;
            } finally {
                this.loadingEmployeesStatus = false;
            }
        },
        async updateBranch() {
            this.submitOnProgress = true;

            this.updateBranchApiError = {
                status: false,
                error: {},
            };

            let branch = this.branch;

            let data = {};
            data._method = "POST";
            if(branch.name !== this.name) data.name = this.name;
            if(parseInt(branch.manger_id) !== this.manager) data.manger_id = this.manager;
            if(parseInt(branch.assistant_id) !== this.assistant) data.assistant_id = this.assistant;
            if(parseInt(branch.secretarial_id) !== this.secretarial) data.secretarial_id = this.secretarial;
            if (this.containsKey(branch, 'supervisors')) {
                if (branch.supervisor.length !== this.supervisors.length || branch.supervisor.every(function (value, index) { return value === this.supervisors[index] })) data.supervisor_id = this.supervisors;
            }
            if (this.containsKey(branch, 'readers')) {
                if (branch.reader.length !== this.readers.length || branch.reader.every(function (value, index) { return value === this.readers[index] })) data.reader_id = this.readers;
            }
            try {
                let res = await updateBranch(branch.id, data);
                if (res.data.status) {
                    this.$emit('branch-updated');
                } else {
                    this.$toast.error(res.data.message);
                    this.updateBranchApiError.status = true;
                    this.updateBranchApiError.error.message = res.data.message;
                }
            } catch (error) {
                this.$toast.error('حدث خطأ ما');
                this.updateBranchApiError.status = true;
                this.updateBranchApiError.error.message = error.response ? error.response.data : error;
            } finally {
                this.submitOnProgress = false;
            }
        },
        resetForm() {
            let branch = this.branch;
            this.name = branch.name;
            this.manager = branch.manger_id ? parseInt(branch.manger_id) : branch.manger_id;
            this.assistant = branch.assistant_id ? parseInt(branch.assistant_id) : branch.assistant_id;
            this.secretarial = branch.secretarial_id ? parseInt(branch.secretarial_id) : branch.secretarial_id;
            this.supervisors = branch.supervisor;
            this.readers = branch.reader;
            console.log(branch)
        },
        setup() {
            this.getEmployees();
            this.resetForm();
        },
    },
    created() {
        console.log(this.branch);
        this.setup();
    }
}
</script>