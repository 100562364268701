import Api from 'Api';


/**
 * Function to retrieve current logged-in user's data
 */

export let currentUser = () => {
    return Api.get(`/user`);
};
export let dashboard = () => {
    return Api.get(`/dashboard`);
};

/**
 * Branches
 */
export let fetchStudents = (data = '') => {
    return Api.get(`/students${data}`);
};
export let fetchTracks = () => {
    return Api.get(`/paths`);
};

export let fetchBranches = () => {
    return Api.get(`/branches`);
};
export let createBranch = (data) => {
    return Api.post(`/branches`, data);
};


export let updateBranch = (id, data) => {
    return Api.put(`/branches/${id}`, data);
};

export let deleteBranch = (id) => {
    return Api.delete(`/branches/${id}`);
};
export let createInterviwe = (data) => {
    return Api.post(`/interview/create`, data);
};
export let deleteItem = (id, type) => {
    return Api.delete(`/${type}/${id}`);
};

export let fetchBranchById = (id) => {
    return Api.get(`/branches/${id}`);
};

export let confirmRegister = (id) => {
    return Api.get(`/students/confirm/${id}`);
};
export let rejectRegister = (id) => {
    return Api.get(`/students/reject/${id}`);
};

export let fetchTrackDurations = (id) => {
    return Api.get(`/paths/durations/${id}`);
};
export let fetchTrackStudents = (id) => {
    return Api.get(`/paths/students/${id}`);
};

/**
 * Students
 */
export let registerStudent = (data) => {
    return Api.post(`/students`, data);
};

/**
 * Nazem
 */
export let fetchEmployees = async () => {
    return Api.get(`/nazem/employees`);
};

/**
 * Terms
*/
export let exportTerms = async () => {
    return Api.get(`/terms/export-excel`);
};
export let fetchTerms = (query = '') => {
    let url = query ? `/terms?${query}` : `/terms`;
    return Api.get(url);
};

export let createTerm = (data) => {
    return Api.post(`/terms`, data);
};

export let updateTerm = (id, data) => {
    return Api.put(`terms/${id}`, data);
};
export let deleteTerm = (id) => {
    return Api.delete(`/terms/${id}`);
};


/**
 * weeklyWeeklyNarratives
 */
export let fetchweeklyNarratives = () => {
    return Api.get(`/weekly_reads`);
};

export let createweeklyNarrative = (data) => {
    return Api.post(`/weekly_reads`, data);
};

export let updateweeklyNarrative = (data) => {
    return Api.post(`/weekly_reads`, data);
};

export let deleteweeklyNarrative = (id) => {
    return Api.delete(`/weekly_reads/${id}`);
};
export let fetchStudent = (id) => {
    return Api.get(`/students/${id}`);
};
export let updateInterviwe = (id, data) => {
    return Api.post(`meeting-appointments/update/${id}`, data);
};
export let fetchInterView = (id) => {
    return Api.get(`/meeting-appointments/${id}`);
};
export let createRole = (data) => {
    return Api.post(`/roles`, data);
};

export let fetchRoles = () => {
    return Api.get(`/roles`);
};
export let updateRole = (id, data) => {
    return Api.put(`/roles/${id}`, data);
};

export let deleteRole = (id) => {
    return Api.delete(`/roles/${id}`);
};

export let fetchWeeklyNarrativeById = (id) => {
    return Api.get(`/weekly_reads/${id}`);
};

/**
 * students Holiday
 */
export let fetchStudentHoliday = () => {
    return Api.get(`/holidays`);
};

export let createStudentHoliday = (data) => {
    return Api.post(`/holidays`, data);
};

export let updateStudentHoliday = (data) => {
    return Api.put(`/holidays`, data);
};

export let deleteStudentHoliday = (id) => {
    return Api.delete(`/holidays/${id}`);
};

export let fetchStudentHolidayById = (id) => {
    return Api.get(`/holidays/${id}`);
};
///////////////////////////// drop down lists /////////////////
export let listDurations = (path_id = null) => {
    if (path_id != null) {   
        return Api.get(`/durations/list?path_id=${path_id}`);
    }
    return Api.get(`/durations/list`);
};
export let listPaths = () => {
    return Api.get(`/paths/list`);
};
export let listBandType = () => {
    return Api.get(`/band-types/list`);
};

export let fetchBandTypes = () => {
    return Api.get(`/band-types`);
};
export let createBandType = (data) => {
    return Api.post(`/band-types`, data);
};


export let updateBandType = (id, data) => {
    return Api.put(`/band-types/${id}`, data);
};

export let deleteBandType = (id) => {
    return Api.delete(`/band-types/${id}`);
};

export let listStudents = () => {
    return Api.get(`/students/list`);
};


export let listInterviewTemplates = (type) => {
    return Api.get(`/interview-template?type=${type}`);
};
