<template>
  <v-app dir="rtl">
    <div v-if="showDesk">
      <main-bar-right  v-if="showBarRight"/>
    </div>
    <div v-if="showDesk">
    <main-bar-top v-if="showBarRight"/>
    </div>
    <div v-if="showMobile">
      <mobileright-bar v-if="showBarRight"/>
    </div>
    <router-view />
  </v-app>
</template>

<script>
import {mapGetters} from "vuex";
import MobilerightBar from "@/components/rightBar/mobilerightBar";
import MainBarRight from "@/components/rightBar/mainBarRight";
import MainBarTop from "@/components/topBar/mainBarTop";
import { extend } from 'vee-validate';
import { required, required_if, oneOf, alpha, email, max, min, max_value, min_value, alpha_spaces, alpha_dash, confirmed, image, digits } from 'vee-validate/dist/rules';
import Api from 'Api';

export default {
  name: 'App',
  components: {MainBarTop, MainBarRight, MobilerightBar},
  data: () => ({
    showDesk:true,
    showMobile:false,
    
  }),
  computed: {
    ...mapGetters('settings', ['showBarRight'])
  },
  methods:{

    showBar(){
      if (window.innerWidth < 998){
        this.showDesk = false
        this.showMobile = true
      }else {
        this.showDesk = true
        this.showMobile = false
      }
    },
    extendGlobalValidationsRules() {
      extend('required', {
          ...required,
          message: '{_field_} ' + 'لا يمكن تركه فارغاً',
      })

      extend('required_if', {
          ...required_if,
          message: '{_field_} ' + 'لا يمكن تركه فارغاً',
      })

      extend('alpha', {
          ...alpha,
          message: 'غير صالح' + ' {_field_}',
      })

      extend('alpha_dash', {
          ...alpha_dash,
          message: 'غير صالح' + ' {_field_}',
      })

      extend('digits', {
          ...digits,
          message: '{_field_} ' + 'غير صحيح',
      })

      extend('confirmed', {
          ...confirmed,
          message: "غير مطابق" + ' {_field_}',
      })

      extend('email', {
          ...email,
          message: 'غير صالح' + ' {_field_}',
      })

      extend('max', {
          ...max,
          message: '{_field_} ' + "طويل جداً",
      })

      extend('min', {
          ...min,
          message: '{_field_} ' + "فصير جداً",
      })

      extend('max_value', {
          ...max_value,
          message: '{_field_} ' + "أكبر من المسموح به",
      })

      extend('min_value', {
          ...min_value,
          message: '{_field_} ' + "أصغر من المسموج به",
      })

      extend('oneOf', {
          ...oneOf,
          message: 'غير صالح' + ' {_field_}',
      })

      extend('alpha_spaces', {
          ...alpha_spaces,
          message: 'غير صالح' + ' {_field_}',
      })

      extend('image', {
          ...image,
          message: '{_field_} ' + "حقل إجباري",
      })

      extend("uniq", {
        validate: async (value, args) => {

          let formData = new FormData();
          formData.append(args.fieldName || 'name', value);

          try {
              const res = await Api.post(`${args.url}`, formData);

              if (res.data.status) {
                  return true;
              }else {
                  return "هذا" + '{_field_} ' + "محجوز مسبقاُ";
              }
          } catch (error) {
              return "هذا" + '{_field_} ' + "محجوز مسبقاُ";
          }
        },
        params: ['url', 'fieldName']
      });

      extend("uniq_except_original", {
        validate: async (value, args) => {

          if (value === args.except_value) {
            return true;
          }

          let formData = new FormData();
          formData.append(args.fieldName || 'name', value);

          try {
              const res = await Api.post(`${args.url}`, formData);

              if (res.data.status) {
                  return true;
              }else {
                  return "هذا" + ' {_field_} ' + "محجوز مسبقاّ";
              }
          } catch (error) {
              return "هذا" + ' {_field_} ' + "محجوز مسبقاّ";
          }
        },
        params: ['url', 'except_value', 'fieldName']
      });

      extend('url', {
        validate(value) {
          if (value) {
            return /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(value);
          }

          return false;
        },
        message: '{_field_} ' + 'عنوان غير صالح',
      })
    },
  },
  beforeMount() {
  this.showBar();
    window.addEventListener('resize', this.showBar)
  },
  beforeUpdate() {
      this.$store.dispatch('settings/resetData')
  },
  created() {
    this.extendGlobalValidationsRules();
  }

};
</script>
<style>

@font-face {
  font-family: "DIN Next LT Arabic";
  src: local("DIN Next LT Arabic"),
  url(./assets/fonts/DINNextLTArabic-Regular-3.ttf) format("truetype");
  /*font-display: swap;*/
}

.v-application {
  font-family: "DIN Next LT Arabic", Helvetica, Arial !important;
  /*font-display: swap;*/
}

main.v-main {
  padding: 1px
}

body {
  font-family: "DIN Next LT Arabic", Helvetica, Arial !important;
  /*font-display: swap;*/
}

* {
  font-size: 20px;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/*.content-site{*/
/*  background-color: #F8F8F8;*/
/*  min-height: 89vh;*/
/*}*/

</style>
