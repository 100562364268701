<template>
  <section class="branches-view">
    <v-main>
      <div class="container--fluid" style="border-radius: 15px; background-color: white; padding: 15px">
        <div class="all-card">
          <div class="top-bar-search">
            <div class="search-section">
              <form>
                <div class="search">
                  <v-row>
                    <v-col cols="8"  style="padding-left: 4px">
                      <search-input @search-input-updated="searchChanged" :style="styleSearch">
                      </search-input>
                    </v-col>
                    <!-- <v-col cols="4" >
                      <div style="display: flex;align-items: center">
                        <button>بحث</button>
                      </div>
                    </v-col> -->
                  </v-row>
                </div>
              </form>
            </div>
            <div class="import-export-file">
              <div style="display: flex">
                <div class="export-file">
                  <import-file :icon="'fa fa-upload'" :name="'رفع ملف اكسل'"/>
                </div>
                <div class="import-file">
                  <import-file :icon="'fa fa-cloud-download'" :name="'تصدير الملف'"/>
                </div>
              </div>
              <div class="add-new">
                <dialog-modal :name-input="'اضافة فرع جديد'" :title="'اضافة فرع جديد'">
                  <div class="form-modal">
                    <validation-observer ref="form" v-slot="{ invalid, handleSubmit }" >
            
                      <v-form @submit.prevent="handleSubmit(createBranch)" :disabled="submitOnProgress">
                        <v-row>
                          <v-col cols="12">
                            <ValidationProvider name="إسم الفرع" :rules="rules.name" v-slot="{ errors }">
                              <v-text-field v-model="name" :error-messages="errors" dense outlined placeholder="اكتب اسم الفرع"></v-text-field>
                            </ValidationProvider>
                          </v-col>
                          <v-col cols="12" lg="6" md="6">
                            <ValidationProvider name="مدير الفرع" :rules="rules.manager" v-slot="{ errors }">
                              <v-select
                                  :items="employees"
                                  item-text="full_name"
                                  item-value="id"
                                  placeholder="مدير الفرع"
                                  :error-messages="errors"
                                  v-model="manager"
                                  outlined
                                  dense
                              ></v-select>
                            </ValidationProvider>
                          </v-col>
                          <v-col cols="12" lg="6" md="6">
                            <ValidationProvider name="المساعد" :rules="rules.assistant" v-slot="{ errors }">
                              <v-select
                                  :items="employees"
                                  item-text="full_name"
                                  item-value="id"
                                  placeholder="المساعد"
                                  :error-messages="errors"
                                  v-model="assistant"
                                  outlined
                                  dense
                              ></v-select>
                            </ValidationProvider>
                          </v-col>
                          <v-col cols="12" lg="6" md="6">
                            <ValidationProvider name="سكرتارية" :rules="rules.secretarial" v-slot="{ errors }">
                              <v-select
                                  :items="employees"
                                  item-text="full_name"
                                  item-value="id"
                                  placeholder="سكرتارية"
                                  :error-messages="errors"
                                  v-model="secretarial"
                                  outlined
                                  dense
                              ></v-select>
                            </ValidationProvider>
                          </v-col>
                          <v-col cols="12" lg="6" md="6">
                            <ValidationProvider name="المشرفين" :rules="rules.supervisors" v-slot="{ errors }">
                              <v-select
                                  :items="employees"
                                  item-text="full_name"
                                  item-value="id"
                                  placeholder="المشرفين"
                                  :error-messages="errors"
                                  v-model="supervisors"
                                  outlined
                                  multiple
                                  dense
                              ></v-select>
                            </ValidationProvider>
                          </v-col>

                          <v-col cols="12" lg="6" md="6">
                            <ValidationProvider name="المقرئين" :rules="rules.readers" v-slot="{ errors }">
                              <v-select
                                  :items="employees"
                                  item-text="full_name"
                                  item-value="id"
                                  placeholder="المقرئين"
                                  :error-messages="errors"
                                  v-model="readers"
                                  outlined
                                  multiple
                                  dense
                              ></v-select>
                            </ValidationProvider>
                          </v-col>

                          <v-col cols="12">
                            <v-btn :disabled="invalid" type="submit" block color="#00B5AD" style="color: white;font-size: 15px;height: 45px">اضافة</v-btn>
                          </v-col>
                        </v-row>
                      </v-form>
                    </validation-observer>
                  </div>
                </dialog-modal>
              </div>
            </div>
          </div>

          <v-row>

            <v-col cols="12">
              <v-data-iterator
                  :items="branches"
                  no-results-text="0 نتائج مطابقة"
                  :search="search"
                  hide-default-footer
                  :page.sync="page"
                  :items-per-page.sync="itemsPerPage"
                  @page-count="pageCount = $event"
                  class="mt-6"
                  :loading="loadingBranchesStatus"
              >

                <template v-slot:default="props">
                  <v-row>
                    <v-col v-for="branch in props.items" :key="branch.id" cols="12" lg="4" md="6">
                      <card-branches
                          :name="branch.name"
                          :text-card="branch.description"
                          :items-path-array="branch.detailsAdmission"
                          :show-btn="true"
                          :show-edit-delete="true"
                          :path-link="'detailsBranches/'+branch.id"
                          @edit-btn-clicked="openEditBranch(branch)"
                          @delete-btn-clicked="openDeleteBranch(branch.id)"
                          
                      />
                    </v-col>
                  </v-row>
                </template>

                <!-- DataTable text to show when table is empty -->
                <template v-slot:no-data>
                    <div class="d-flex justify-center align-center">
                        <span>لا يوجد أي عناصر</span>
                    </div>
                </template>

                <!-- DataTable custom footer -->
                <template v-slot:[`footer.page-text`]= "{ pageStart, pageStop, itemsLength }">
                    <span>{{ pageStart }}-{{ pageStop }} من {{ itemsLength }}</span>
                </template>

                <template v-slot:loading>
                    <div class="d-flex justify-center align-center">
                        <v-progress-circular
                            color="primary"
                            indeterminate
                        ></v-progress-circular>
                    </div>
                </template>

              </v-data-iterator>
            </v-col>

            <v-col cols="12">
              <v-pagination
                  v-if="pageCount > 1"
                  v-model="page"
                  :length="pageCount"
                  class="mt-5"
                  :total-visible="10"
              ></v-pagination>
            </v-col>
          </v-row>
        </div>
        <!-- <pagination-components/> -->
        <confirmation-dialog ref="deleteBranchConfirmationDialog" heading="تأكيد عملية حذف الفرع" message='لن تتمكن من استرجاعه مجدداً' @onConfirm="deleteBranch"></confirmation-dialog>
        <edit-branch v-if="editBranchDialogStatus" :dialog-status="editBranchDialogStatus" @close-edit-branch="closeEditBranchDialog" @branch-updated="branchUpdated" :branch="branch2edit"></edit-branch>

      </div>
    </v-main>
  </section>
</template>

<script>

import SearchInput from "@/components/search-input";
import ImportFile from "@/components/import-file";
import DialogModal from "@/components/dialogModal";
import CardBranches from "@/components/cards/card-branches";
import { fetchBranches, fetchEmployees, createBranch, deleteBranch } from 'Actions';
import EditBranch from './EditBranch.vue';

export default {
  name: "branchesView",
  components: {
    CardBranches,
    EditBranch,
    DialogModal, ImportFile, SearchInput, //PaginationComponents
  },
  data() {
    return {
      items: [
        'ربط ', 'مراجعه', 'تكرار',
      ],
      styleSearch: {
        backgroundColor: 'transparent',
        border: "1px solid #E2E2EA",
        borderRadius: "10px",

      },
      branches: [],
      itemsPerPage: 8,
      page: 1,
      pageCount: 0,
      search: '',
      loadingBranchesStatus: false,
      fetchBranchesApiError: {
          status: false,
          error: {},
      },
      submitOnProgress: false,
      createBranchApiError: {
          status: false,
          error: {},
      },
      employees: [],
      name: '',
      manager: null,
      assistant: null,
      secretarial: null,
      supervisors: [],
      readers: [],
      loadingEmployeesStatus: true,
      fetchEmployeesApiError: {
          status: false,
          error: {},
      },
      branch2edit: null,
      branch2delete: null,
      editBranchDialogStatus: false,
      rules: {
        name: {
          required: true,
        },
        // manager: {
        //   required: true,
        // },
        // assistant: {
        //   required: true,
        // },
        // secretarial: {
        //   required: true,
        // },
        // supervisors: {
        //   required: true,
        // },
        // readers: {
        //   required: true,
        // },
      }
    }
  },
  methods: {
    async getBranches() {
      this.loadingBranchesStatus= true;
      this.fetchBranchesApiError = {
          status: false,
          error: {},
      };
      try {
          let res = await fetchBranches();
          if (res.data.status) {
              this.branches = res.data.data;
          } else {
              this.fetchBranchesApiError.status = true;
              this.fetchBranchesApiError.error.message = res.data.message;
          }
      } catch (error) {
          this.fetchBranchesApiError.status = true;
          this.fetchBranchesApiError.error.message = error.response ? error.response.data : error;
      } finally {
          this.loadingBranchesStatus = false;
      }
    },
    async getEmployees() {
      this.loadingEmployeesStatus= true;
      this.fetchEmployeesApiError = {
          status: false,
          error: {},
      };
      try {
          let res = await fetchEmployees();
          if (res.data.status) {
              this.employees = res.data.data;
          } else {
              this.fetchEmployeesApiError.status = true;
              this.fetchEmployeesApiError.error.message = res.data.message;
          }
      } catch (error) {
          this.fetchEmployeesApiError.status = true;
          this.fetchEmployeesApiError.error.message = error.response ? error.response.data : error;
      } finally {
          this.loadingEmployeesStatus = false;
      }
    },
    async createBranch() {
      this.submitOnProgress = true;

      this.createBranchApiError = {
          status: false,
          error: {},
      };

      let formData = new FormData();
      formData.append("name", this.name);
      formData.append("manger_id", this.manager);
      formData.append("assistant_id", this.assistant);
      formData.append("secretarial_id", this.secretarial);
      formData.append("supervisor_id", JSON.stringify(this.supervisors));
      formData.append("reader_id", JSON.stringify(this.readers));

      try {
          let res = await createBranch(formData);
          if (res.data.status) {
            this.$toast.success("تمت العملية بنجاح");
            this.resetFields();
          } else {
              this.$toast.error(res.data.message);
              this.createBranchApiError.status = true;
              this.createBranchApiError.error.message = res.data.message;
          }
      } catch (error) {
          this.$toast.error('فشلت العملية');
          this.createBranchApiError.status = true;
          this.createBranchApiError.error.message = error.response ? error.response.data : error;
      } finally {
          this.submitOnProgress = false;
      }
    },
    openEditBranch(branch) {
      this.branch2edit = branch;
      this.editBranchDialogStatus = true;
    },
    openDeleteBranch(branch) {
      this.branch2delete = branch;
      this.$refs.deleteBranchConfirmationDialog.openDialog();
    },
    async deleteBranch() {

        this.$refs.deleteBranchConfirmationDialog.close();

        try {
            let res = await deleteBranch(this.branch2delete);
            if (res.data.status) {
                this.branch2delete = null;
              this.$toast.success('تمت العملية بنجاح');
                
                this.getBranches();
            } else {
                this.$toast.error(res.data.message);
            }
        } catch (error) {
            this.$toast.error("حدث خطأ ما");
        }
    },
    branchUpdated() {
      this.closeEditBranchDialog();
      this.$toast.success('تمت العملية بنجاح');
      this.getBranches();
    },
    closeEditBranchDialog() {
      this.editBranchDialogStatus = false;
      this.branch2edit = null;
    },
    resetFields() {
      this.name = "";
      this.manager = this.assistant = this.secretarial = null;
      this.supervisors = this.readers = [];
      this.$nextTick(() => {
        this.$refs.form.reset();
      });
      this.getBranches();

    },
    searchChanged(val) {
      this.search = val.data
    }
  },
  beforeMount() {
    this.$store.dispatch('settings/pageTitle', 'الفروع');
  },
  created() {
    this.getBranches();
    this.getEmployees();
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/variable.scss";

.branches-view {
  background-color: $background-main-page;
  padding: 40px 15px;

  .top-bar-search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    @media only screen and (max-width: 1250px) {
      flex-direction: column;
    }

    .search-section {
      width: 55%;
      @media only screen and (max-width: 1250px) {
        width: 100%;
      }


      .search {
        display: flex;
        align-items: center;
        padding: 12px 0;

        button {
          border: 1px solid #00B5AD;
          padding: 10px 40px;
          font-size: 14px;
          border-radius: 10px;
          color: #00B5AD;
          margin-right: 10px;
          @media only screen and (max-width: 1250px) {
            width: 100%;
          }
        }

      }
    }

  }

  .import-export-file {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    @media only screen and (max-width: 1250px) {
      width: 100%;
      div {
        width: 50%;
        label {
          width: 100%;
          display: inline-block;
        }
      }
      .export-file, .import-file {
        margin-top: 8px;
        text-align: center;
      }
      .text-center{
        width: 100%;
      }
    }
    @media only screen and (max-width: 800px) {
      width: 100%;
      display: unset;
      div {
        width: 100%;
      }
    }
    div {
      margin: 0 5px;
      @media only screen and (max-width: 800px) {
        margin: 0 0px;
        padding: 0 5px;

      }
    }
  }

}

</style>
<style lang="scss">
.theme--light.v-text-field > .v-input__control > .v-input__slot:before {
  display: none !important;
  border: none !important;
}

.branches-view {
  .theme--light.v-input input, .theme--light.v-input textarea {
    font-size: 14px !important;
    font-weight: bold !important;
    color: #707070 !important;
    margin-top: 5px;
  }

  .v-input__icon i {
    font-size: 14px !important;
    font-weight: normal !important;
    color: #707070 !important;
  }

  .v-text-field__details {
    display: none;
  }

  .v-input__slot:focus .v-text-field__details {
    border: none !important;
    display: none;

  }

  .v-input__slot:active .v-text-field__details {
    border: none !important;
    display: none;

  }

  .theme--light.v-input {
    margin: 0px 10px 0px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #D2D5E1;
    border-radius: 5px;
  }

  .v-calendar-daily__interval-text {
    color: transparent !important
  }

  .v-text-field {
    padding: 0;
  }

  .v-text-field__slot input {
    padding: 2px 0 !important;
  }

  .v-input__prepend-outer {
    margin-right: 3px;
  }


}

.form-modal {
  padding: 15px 0;

  .col-12 {
    position: relative;
  }

  .v-label {
    right: 0 !important;
    left: auto !important;
  }

  label {
    display: block;
    margin-bottom: 10px;
    font-size: 15px;
    background-color: white;
    position: absolute;
    top: 0;
    z-index: 50;
    right: 7%;
  }

  label.custom-label {
    font-size: 14px !important;
  }

  .v-input__slot {
    margin-bottom: 0 !important;
  }

  .custom-input {
    .v-text-field {
      border: 1px solid rgba(21, 57, 135, 0.28);
      border-radius: 5px;
    }

    .v-text-field__details {
      display: none;
    }

    label {
      padding-right: 10px;
      margin-bottom: 10px;
    }

    .v-text-field .v-label {
      top: 3px !important;
    }
  }

}

</style>
