<template>
<section class="tracks-student">
    <v-main>
        <div class="container--fluid" style="border-radius: 15px; background-color: white; padding: 15px">
            <div class="top-bar-search">
                <div class="search-section">
                    <form>
                        <div class="search">
                            <v-row>
                                <v-col cols="12" lg="5" style="padding-left: 4px">
                                    <search-input :style="styleSearch">
                                    </search-input>
                                </v-col>
                                <v-col cols="12" lg="7">
                                    <div class="date-top">
                                        <div class="container-date">
                                            <date-select-modal />
                                        </div>
                                        <div class="container-date">
                                            <date-select-modal />
                                        </div>
                                        <div class="input-search">
                                            <btn-search />
                                        </div>
                                    </div>
                                </v-col>
                            </v-row>
                        </div>
                    </form>
                </div>
                <div class="import-export-file">
                    <div style="display: flex">
                        <div class="export-file">
                            <import-file :icon="'fa fa-upload'" :name="'رفع ملف اكسل'" />
                        </div>
                        <div class="import-file">
                            <import-file :icon="'fa fa-cloud-download'" :name="'تصدير الملف'" />
                        </div>
                    </div>
                    <div class="add-new">
                        <btn-submit style="height: 35px" :value-btn="'إضافة مسار جديد'" :route-link="'/addTracks'">
                            <i class="fa fa-plus ml-3 mt-2"></i>
                        </btn-submit>
                    </div>
                </div>
            </div>
            <!--  content tabs 'card follow student'-->
            <div class="tabs-top">
                <v-tabs color="deep-purple accent-4">
                    <v-tab v-for="item in items" :key="item">{{ item }}</v-tab>
                    <v-tabs-slider color="#00B5AD"></v-tabs-slider>
                    <v-tab-item v-for="(value, index) in tracks" :key="index">
                        <v-container fluid>
                            <div class="all-card mt-5">
                                <v-row>
                                    <v-col v-for="track in value" :key="track.id" cols="12" lg="4" md="6">
                                        <card-tracks :name="track.name" :item_id="track.id" :value-button="'تفاصيل المسار'" :path-time-section="true" :text-card="track.description" :items-path-array="detailsAdmission" :show-btn="true" :show-edit-delete="true" :path-link="'detailsTracks/'+track.id" @edit-btn-clicked="openEdittrack(track.id)" @delete-btn-clicked="openDeleteItem(track.id)" />
                                    </v-col>
                                </v-row>
                            </div>
                        </v-container>
                    </v-tab-item>
                </v-tabs>
            </div>

            <pagination-components />
         <confirmation-dialog ref="deleteItemConfirmationDialog" heading="تأكيد عملية حذف المسار" message='لن تتمكن من استرجاعه مجدداً' @onConfirm="deleteItem"></confirmation-dialog>

        </div>
    </v-main>
</section>
</template>

<script>
import PaginationComponents from "@/components/dashboard/paginationComponents";
import SearchInput from "@/components/search-input";
import DateSelectModal from "@/components/dashboard/dateSelectModal";
import ImportFile from "@/components/import-file";
import { mapGetters } from "vuex";
import BtnSubmit from "@/components/btnSubmit";
import BtnSearch from "@/components/btnSearch";
import { fetchTracks, deleteItem } from 'Actions';
import CardTracks from "@/components/cards/card-tracks";

export default {
    name: "tracksView",
    components: {
        BtnSearch,
        BtnSubmit,
        CardTracks,
        ImportFile,
        DateSelectModal,
        SearchInput,
        PaginationComponents
    },
    data() {
        return {
            items: [
                'الحفظ', 'اتقان', 'سماع', 'تكرار', 'ربط', 'مراجعه', 'تسميع', 'اختبار', 'تلاوة', 'سرد اسبوعي',
            ],
            styleSearch: {
                backgroundColor: 'transparent',
                border: "1px solid #E2E2EA",
                borderRadius: "10px",

            },
            detailsAdmission: [{
                    id: 1,
                    icon: require('@/assets/image/icon/video.png'),
                    title: 'كيفية التسميع ',
                    subTitle: 'اونلاين'
                },
                {
                    id: 2,
                    icon: require('@/assets/image/icon/New folder/Group 78094.png'),
                    title: 'موقع التسميع  ',
                    subTitle: 'زووم'
                },
                {
                    id: 3,
                    icon: require('@/assets/image/icon/Group 77770.png'),
                    title: 'أيام التسميع ',
                    subTitle: 'السبت - الاحد- الثنين'
                },
                {
                    id: 4,
                    icon: require('@/assets/image/icon/New folder/Color.png'),
                    title: 'وقت التسميع',
                    subTitle: 'الساعه (12:12) ص'
                },
            ],
            tracks: [],
            itemsPerPage: 8,
            page: 1,
            pageCount: 0,
            search: '',
            loadingTracksStatus: false,
            fetchItemsApiError: {
                status: false,
                error: {},
            },
            submitOnProgress: false,
            createItemApiError: {
                status: false,
                error: {},
            },
            employees: [],
            name: '',
            manager: null,
            assistant: null,
            secretarial: null,
            supervisors: [],
            readers: [],
            loadingEmployeesStatus: true,
            fetchEmployeesApiError: {
                status: false,
                error: {},
            },
            item2edit: null,
            item2delete: null,
            editBranchDialogStatus: false,
            rules: {
                name: {
                    required: true,
                },

            }
        }
    },
    computed: {
        ...mapGetters('settings', ['allInterview'])
    },
    methods: {
        async getItems() {
            this.loadingTracksStatus = true;
            this.listPathsApiError = {
                status: false,
                error: {},
            };
            try {
                let res = await fetchTracks();
                console.log(res.data)
                if (res.data.status) {
                    this.tracks = res.data.data;
                } else {
                    this.listPathsApiError.status = true;
                    this.listPathsApiError.error.message = res.data.message;
                }
            } catch (error) {
                this.listPathsApiError.status = true;
                this.listPathsApiError.error.message = error.response ? error.response.data : error;
            } finally {
                this.loadingTracksStatus = false;
            }
        },
        
        openDeleteItem(item) {
            this.item2delete = item;
            this.$refs.deleteItemConfirmationDialog.openDialog();
        },
        async deleteItem() {

            this.$refs.deleteItemConfirmationDialog.close();

            try {
                let res = await deleteItem(this.item2delete,'paths');
                if (res.data.status) {
                    this.item2delete = null;
                    this.getItems();
                    this.$toast.success('تمت العملية بنجاح');
                } else {
                    this.$toast.error(res.data.message);
                }
            } catch (error) {
                this.$toast.error("حدث خطأ ما");
            }
        },
        itemUpdated() {
            this.closeEditItemDialog();
            this.$toast.success('تمت العملية بنجاح');
            this.getItems();
        },
        closeEditItemDialog() {
            this.editItemDialogStatus = false;
            this.item2edit = null;
        },
        resetFields() {
            // this.name = "";
            // this.manager = this.assistant = this.secretarial = null;
            // this.supervisors = this.readers = [];
            this.$nextTick(() => {
                this.$refs.form.reset();
            });
            this.getItems();

        },
        searchChanged(val) {
            this.search = val.data
        }

    },

    beforeMount() {
        this.$store.dispatch('settings/pageTitle', 'المسارات')
    },
    created() {
        this.getItems();
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/variable.scss";

.tracks-student {
    background-color: $background-main-page;
    padding: 40px 15px;

    .top-bar-search {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;

        @media only screen and (max-width: 1300px) {
            flex-direction: column;
        }

        .search-section {
            width: 55%;

            @media only screen and (max-width: 1300px) {
                width: 100%;
            }

            .date-top {
                display: flex;
                align-items: center;

                @media only screen and (max-width: 1300px) {
                    .container-date {
                        width: 33%;

                        .theme--light.v-input {
                            width: 100%;
                        }
                    }

                    .input-search {
                        width: 33%;
                    }
                }
            }

            .search {
                display: flex;
                align-items: center;
                padding: 12px 0;

                button {
                    border: 1px solid #00B5AD;
                    padding: 10px 40px;
                    font-size: 14px;
                    border-radius: 10px;
                    color: #00B5AD;
                    margin-right: 10px;
                }

            }
        }

    }

    .import-export-file {
        display: flex;
        align-items: center;
        margin-bottom: 8px;

        @media only screen and (max-width: 1300px) {
            width: 100%;

            div {
                width: 50%;

                label {
                    width: 100%;
                    display: inline-block;
                }

                .btn-submit-component {
                    width: 100%;
                }
            }

            .export-file,
            .import-file {
                margin-top: 8px;
                text-align: center;
            }

            .text-center {
                width: 100%;
            }
        }

        @media only screen and (max-width: 800px) {
            width: 100%;
            display: unset;

            div {
                width: 100%;
            }
        }

        div {
            margin: 0 5px;

            @media only screen and (max-width: 800px) {
                margin: 0 0px;
                padding: 0 5px;

            }
        }
    }

}
</style><style lang="scss">
.theme--light.v-text-field>.v-input__control>.v-input__slot:before {
    display: none !important;
    border: none !important;
}

.tracks-student {

    .theme--light.v-input input,
    .theme--light.v-input textarea {
        font-size: 14px !important;
        font-weight: bold !important;
        color: #707070 !important;
        margin-top: 5px;
    }

    .v-input__icon i {
        font-size: 14px !important;
        font-weight: normal !important;
        color: #707070 !important;
    }

    .v-text-field__details {
        display: none;
    }

    .v-input__slot:focus .v-text-field__details {
        border: none !important;
        display: none;

    }

    .v-input__slot:active .v-text-field__details {
        border: none !important;
        display: none;

    }

    .theme--light.v-input {
        margin: 0px 10px 0px 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #D2D5E1;
        border-radius: 5px;
    }

    .v-calendar-daily__interval-text {
        color: transparent !important
    }

    .v-text-field {
        padding: 0;
    }

    .v-text-field__slot input {
        padding: 2px 0 !important;
    }

    .v-input__prepend-outer {
        margin-right: 3px;
    }

    .add-new {
        .btn-submit-component .btn-submit {
            height: 38px !important;
            margin-top: 2px;
        }
    }

}

.form-modal {
    padding: 15px 0;

    .col-12 {
        position: relative;
    }

    .v-label {
        right: 0 !important;
        left: auto !important;
    }

    label {
        display: block;
        margin-bottom: 10px;
        font-size: 15px;
        background-color: white;
        position: absolute;
        top: 0;
        z-index: 50;
        right: 7%;
    }

    label.custom-label {
        font-size: 14px !important;
    }

    .v-input__slot {
        margin-bottom: 0 !important;
    }

    .custom-input {
        .v-text-field {
            border: 1px solid rgba(21, 57, 135, 0.28);
            border-radius: 5px;
        }

        .v-text-field__details {
            display: none;
        }

        label {
            padding-right: 10px;
            margin-bottom: 10px;
        }

        .v-text-field .v-label {
            top: 3px !important;
        }
    }

}
</style>
