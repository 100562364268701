import Cookies from "js-cookie";

const userToken = "userToken";
const userRoles = "userRoles";
const authUser = "authUser";

export function getToken() {
  return Cookies.get(userToken);
}
export function getRoles() {
  return Cookies.get(userRoles);
}

export function setToken(token) {
  return Cookies.set(userToken, token);
}

export function authUserData(user) {
  return Cookies.set(authUser, user);
}
