<script>

  import { mapGetters } from "vuex";
  import { arrayUnique } from 'Helper';

  export default {
    computed: {
      ...mapGetters("auth", [
        'currentUser'
      ])
    },
    methods: {
      $can(permissionName) {
        let user = this.currentUser;
        if(!user || typeof user !== 'object' || !Object.prototype.hasOwnProperty.call(user, 'roles') || !user.roles.length) return false;
        let user_roles = user.roles;
        let user_total_permissions = [];
        user_roles.forEach(role => {
            user_total_permissions = user_total_permissions.concat(role.permissions);
        });
        console.log(user_total_permissions);
        if(Array.isArray(user_total_permissions) && user_total_permissions.length) {
          let can = arrayUnique(user_total_permissions).find((permission) => {
            if(Array.isArray(permissionName)) {
              return permissionName.includes(permission);
            } else {
              return permission === permissionName;
            }
          });
          if(can) return true;
        }
        return false;
      },
      $hasAccess(access) {

        let user = this.currentUser;

        if(!user || typeof user !== 'object' || !Object.prototype.hasOwnProperty.call(user, 'roles') || !user.roles.length) return false;

        let user_roles = user.roles;

        let flag = false;

        for (let index = 0; index < user_roles.length; index++) {
          if (user_roles[index].access === access ) {
            flag = true;
            break;
          }
        }

        return flag;
      },
      $hasRole(role) {

        let user = this.currentUser;

        if(!user || typeof user !== 'object' || !Object.prototype.hasOwnProperty.call(user, 'roles') || !user.roles.length) return false;

        let user_roles = user.roles;

        let flag = false;

        for (let index = 0; index < user_roles.length; index++) {
          if (user_roles[index].name === role ) {
            flag = true;
            break;
          }
        }

        return flag;
      }
    },
  };
</script>
