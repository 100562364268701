<template>
  <!--start page reviewup student-->
  <section class="review-up-student">
    <v-main>
      <div class="container--fluid">
        <div class="top-bar-search">
          <div class="search-section">
            <form>
              <div class="search">
                     <search-input @search-input-updated="searchChanged" :style="styleSearch">
                        </search-input>
                <div class="input-search">
                  <btn-search/>
                </div>
              </div>
            </form>
          </div>
          <div class="select-way">
            <select-option-customer :items="itemsSelected" :name-section="'طريقة التحديد'"/>
          </div>
        </div>
        <div class="all-card">
          <v-row>
            <v-col v-for="student in allStudents" :key="student.id" cols="12" lg="4" md="6">
              <card-follow
                  :name="student.name"
                  :student_name="student.student_name"
                  :path="student.pathName"
                  :duration="student.durationName"
                  :repeatMistake="student.repeat_mistake"
                  :showRepeat="true"
                  :description="student.description"
                  :score="student.score"
                  :passing-score="student.passingScore"
                  :status="student.status"
                  :review-student="student.reviewStudent"
                  :show-review-student="true"
                  :status-review="student.statusReview"
              />
            </v-col>
          </v-row>
        </div>
        <pagination-components/>
      </div>
    </v-main>
  </section>
</template>

<script>
import PaginationComponents from "@/components/dashboard/paginationComponents";
import CardFollow from "@/components/cards/cardFollow";
import SearchInput from "@/components/search-input";
import BtnSearch from "@/components/btnSearch";
import SelectOptionCustomer from "@/components/select-option-customer";
import { getMethods } from '@/api/Helper';

export default {
  name: "reviewStudentView",
  components: {SelectOptionCustomer, BtnSearch, SearchInput, CardFollow, PaginationComponents},
  data() {
    return {
      styleSearch: {
        backgroundColor: 'transparent',
        border: "1px solid #E2E2EA",
        borderRadius: "10px",
      },
      items: ['foo', 'bar', 'fizz', 'buzz'],
      value: ['foo', 'bar', 'fizz', 'buzz'],
      search: '',
      allStudents:[],
      itemsSelected: [
        {title: 'الكل'},
        {title: 'تم المراجعه'},
        {title: 'لم يتم المراجعه'},
      ],
    }
  },
  computed: {
  },
  methods: {
    async getStudents() {
      this.loadingStudentsStatus = true;
      this.fetchStudentsApiError = {
        status: false,
        error: {},
      };
      try {
        let res = await getMethods('dailyStudents/review');
        console.log(res.data);
        if (res.data.status) {
          this.allStudents = res.data.data;
        } 
      } catch (error) {
        this.allStudents = [];
      } 
    },
      searchChanged(val) {
      this.search = val.data
    },
  },
  beforeMount() {
    this.$store.dispatch('settings/pageTitle', 'المراجعة');
    this.getStudents();
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/variable.scss";

.review-up-student {
  background-color: $background-main-page;
  padding: 40px 20px;

  .container--fluid {
    border-radius: 15px;
    background-color: white;
    padding: 15px
  }

  .top-bar-search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media only screen and (max-width: 880px) {
      flex-direction: column;
    }

    .search-section {
      width: 60%;
      @media only screen and (max-width: 880px) {
        width: 100%;
      }

      .search {
        display: flex;
        align-items: center;
        padding: 12px 0;

        .input-search {
          padding-right: 15px;
        }

      }
    }

  }
  @media only screen and (max-width: 880px) {
   .select-way{
     width: 100%;
     margin-bottom: 10px;
   }
  }
}
</style>
