<template>
<div class="card-terms">
    <div class="name-terms">

        <div class="name">
            <div class="name-branch">
                <p>{{ term.name }}</p>
                <i class="fa fa-check" v-if="term.is_active"></i>
                <div :style="{ 'background-color': term.is_active == 1 ? 'rgba(56,91,114,0.26)' : 'rgba(209,26,42,0.23)', 'color': term.is_active ? '#385B72' : '#D11A2A' }" class="status-student">
                    {{ term.status }}
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="path-time" v-show="pathTimeSection"> -->
    <v-row>
        <v-col cols="6">
            <div class="path d-flex align-center">
                <div class="image">
                    <img :src="require('@/assets/image/icon/Group 77770.png')">
                </div>
                <div class="text-path me-3">
                    <p class="mb-0 h6">تاريخ البداية</p>
                    <span class="caption">{{ term.start_date }}</span>
                </div>
            </div>
        </v-col>
        <v-col cols="6">
            <div class="path d-flex align-center">
                <div class="image">
                    <img :src="require('@/assets/image/icon/New folder/Color.png')">
                </div>
                <div class="text-path me-3">
                    <p class="mb-0">بداية اليوم الجديد</p>
                    <span class="caption">{{ term.day_start_hour || 'غير متوفر' }}</span>
                </div>
            </div>
        </v-col>
        <v-col cols="6">
            <div class="path d-flex align-center">
                <div class="image">
                    <img :src="require('@/assets/image/icon/Group 77770.png')">
                </div>
                <div class="text-path me-3">
                    <p class="mb-0">تاريخ النهاية</p>
                    <span class="caption">{{ term.end_date }}</span>
                </div>
            </div>
        </v-col>
        <v-col cols="6">
            <div class="path d-flex align-center">
                <div class="image">
                    <img :src="require('@/assets/image/icon/Group 77857.png')">
                </div>
                <div class="text-path me-3">
                    <p class="mb-0">إختبار نهاية الفصل</p>
                    <span class="caption">{{ term.end_term_exam_text }}</span>
                </div>
            </div>
        </v-col>
        <v-col cols="6">
            <div class="path d-flex align-center">
                <div class="image">
                    <img :src="require('@/assets/image/icon/Group 78361.png')">
                </div>
                <div class="text-path me-3">
                    <p class="mb-0">عرض نهاية الفصل</p>
                    <span class="caption">{{ term.end_term_show_text }}</span>
                </div>
            </div>
        </v-col>
        <div class="last-of-type" style="width: 100%"></div>
    </v-row>
    <!-- </div> -->
    <div class="text-card">
        <!-- <p v-show="textCard">{{  textCard }}</p> -->

        <div class="btn-done" v-show="showBtn">
            <btn-submit :value-btn="valueButton" :route-link="pathLink" class="mt-3 mb-4" />
        </div>

        <div class=" last-of-type" v-show="pathLine" style="width: 100% "></div>
    </div>
    <div class="edit-delete mt-3">
        <v-btn @click="$emit('edit-btn-clicked')" class="edit">
            <span>تعديل </span>
            <i class="fa fa-pencil"></i>
        </v-btn>
        <div class="border"></div>

        <v-btn @click="$emit('delete-btn-clicked')" class="delete">
            <span>حذف </span>
            <i class="fa fa-trash-o"></i>
        </v-btn>
    </div>
</div>
</template>

<script>
import BtnSubmit from "@/components/btnSubmit";

export default {
    name: "card-terms",
    components: { BtnSubmit },
    // props: ['term'],
    props: {
        term: {},
        pathLine: {
            default: null
        },
        pathLink: {
            default: null
        },
        valueButton: {
            default: 'التفاصيل'
        },
        showBtn: {
            type: Boolean,
            default: true
        },
    },
    data() {
        return {
            imageStudent: require('@/assets/image/avatar/ebc8fbda-c735-4d9e-a8be-c5329afba775.png'),
        }
    },

}
</script>

<style lang="scss" scoped>
.card-terms {
    border: 1px solid #D2D5E1;
    border-radius: 5px;
    padding: 20px 10px 0 10px;

    .name-terms {
        display: flex;
        justify-content: right;
        margin-bottom: 15px;
        padding-bottom: 10px;
        border-bottom: 1px solid rgba(143, 146, 161, 0.26);

        .image {
            width: 22%;
            padding-top: 6px;

            img {
                width: 100%;
            }
        }

        .name {
            width: 100%;
            position: relative;
            margin-right: 10px;

            .name-branch {
                display: flex;
                align-items: center;

                p {
                    font-size: 21px;
                    font-weight: bold;
                    margin-bottom: 10px;
                }

                i {
                    background-color: #00B5AD;
                    margin-right: 13px;
                    width: 21px;
                    height: 21px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    font-size: 11px;
                    font-weight: 300;
                    color: white;
                }
            }

            //p {
            //  font-size: 15px;
            //  font-weight: bold;
            //  margin-bottom: 0;
            //}

            span {
                font-size: 13px;
                color: #B4B4B4;
            }
        }
    }

    .text-card {
        p {
            color: #B4B4B4;
            font-size: 14px;
            line-height: 2em;
        }
    }

    .avatar-branch {
        display: flex;
        align-items: center;

        span {
            margin-right: -3%;
            width: 27px;
            height: 27px;
            border-radius: 50%;

            img {
                width: 100%;
            }
        }

        span:first-child {
            margin-right: 0;
        }

        span.icon-plus {
            background-color: #1FD0A3;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 13px;
            color: white;
            border: 2px solid white;

            i {
                margin-top: 2px;
            }
        }

    }

    .status-student {
        position: absolute;
        left: 2%;
        top: -2%;
        background-color: #DBF7F0;
        text-align: center;
        color: #1FD0A3;
        font-size: 11px;
        padding: 10px 15px;
        border-radius: 10px;
        margin-bottom: 30px;
        margin-right: 10px;
    }

    .path-time {
        display: flex;
        justify-content: space-around;
        padding: 15px 10px;

        .col {
            padding-right: 2px;
            padding-left: 2px
        }

        .path {
            display: flex;

            .image {
                background-color: rgba(56, 91, 114, 0.2);
                border-radius: 50%;
                width: 40px;
                height: 40px;
                min-width: 40px;
                max-height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    width: 45%;
                }
            }

            .text-path {
                margin-right: 8px;

                p {
                    margin-bottom: 0;
                    color: #0F1222;
                    font-size: 14px;
                    font-weight: bold;
                }

                span {
                    color: #B4B4B4;
                    font-size: 13px;
                }
            }
        }
    }

    .last-of-type {
        border-bottom: 1px solid rgba(143, 146, 161, 0.26);
    }

    .edit-delete {
        display: flex;
        justify-content: space-around;
        color: #B4B4B4;
        padding-left: 5px;
        padding-right: 5px;
        cursor: pointer !important;

        .edit,
        .delete {
            padding-top: 20px;
            padding-bottom: 25px;

        }

        .delete {
            i {
                padding-left: 3px;
            }
        }

        .v-btn:not(.v-btn--round).v-size--default {
            background-color: transparent !important;
            border: none;
            outline: none;
            box-shadow: none;
            color: #B4B4B4 !important;
            cursor: pointer !important;
        }

        .theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
            background-color: transparent !important;
        }

        i {
            font-size: 15px;
            margin-right: 10px;
            margin-top: 5px;
            background-color: #FAFCFE;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 35px;
            height: 35px;
            border-radius: 50%;
            border: 1px solid #D2D5E1;
        }

        i.fa-pencil {
            padding-left: 5px;
        }

        .border {
            border-right: 1px solid rgba(143, 146, 161, 0.26);
            padding: 0;
        }

        span {
            font-size: 15px;
        }

    }

}
</style><style lang="scss">
.card-terms {
    .v-btn:not(.v-btn--round).v-size--default {
        background-color: #00B5AD !important;
        font-size: 14px !important;
        color: white;
        border-radius: 5px !important;
    }

}
</style>
