<template>

    <app-dialog @close-edit-term="$emit('close-edit-term')" title="تعديل الفصل" event-name="close-edit-term" :dialog-status="dialogStatus" max-width="750px">
        <validation-observer  v-slot="{ invalid, handleSubmit }" >
            <v-form @submit.prevent="handleSubmit(updateTerm)" >
                <v-row>
                    <v-col cols="12">
                        <ValidationProvider name="الإسم" :rules="rules.name" v-slot="{ errors }">
                            <v-text-field v-model="name" :error-messages="errors" dense outlined placeholder="اكتب الإسم"></v-text-field>
                        </ValidationProvider>
                    </v-col>
                    <v-col cols="12" lg="6" md="6">
                    <ValidationProvider name="تاريخ البداية" v-slot="{ errors }">
                        <date-customer :field-errors="errors" @data-entered="startDate = $event" :label="'تاريخ البداية'" dense outlined :name-placeholder="'تاريخ البداية'"/>
                    </ValidationProvider>
                    </v-col>
                    <v-col cols="12" lg="6" md="6">
                    <ValidationProvider name="تاريخ النهاية"  v-slot="{ errors }">
                        <date-customer dense outlined  :field-errors="errors" @data-entered="endDate = $event" :label="'تاريخ النهاية'" :name-placeholder="'تاريخ النهاية'" v-model="endDate" />
                    </ValidationProvider>
                    </v-col>
                    <v-col cols="12" lg="6" md="6">
                    <ValidationProvider name="بداية اليوم"  v-slot="{ errors }">
                        <input-text dense outlined  :field-errors="errors" @data-entered="startDate = $event" :label-top="'بداية اليوم '" :placeholder="'بداية اليوم '" v-model="startDay"/>
                    </ValidationProvider>
                    </v-col>
                    <v-col cols="12" lg="6" md="6">
                         <ValidationProvider name="إختبار نهاية الفصل" v-slot="{ errors }">
                            <v-select
                                :items="booleanOptions"
                                item-text="text"
                                item-value="id"
                                placeholder="إختبار نهاية الفصل"
                                :error-messages="errors"
                                v-model="termFinalExam"
                                outlined
                                dense
                            ></v-select>
                        </ValidationProvider>
                   
                    </v-col>
                    <v-col cols="12" lg="6" md="6">
                    <ValidationProvider name=" عرض نهاية الفصل"  v-slot="{ errors }">
                        <v-select
                            :items="booleanOptions"
                            item-text="text"
                            item-value="id"
                            placeholder="عرض نهاية الفصل"
                            :error-messages="errors"
                            v-model="showEndTerm"
                            outlined
                            dense
                        ></v-select>
                    </ValidationProvider>
                    </v-col>
                    <v-col cols="12" lg="6" md="6">
                     <ValidationProvider name="مدير الفرع"  v-slot="{ errors }">
                            <v-select
                                :items="booleanOptions"
                                item-text="text"
                                item-value="id"
                                placeholder="فعال / غير فعال"
                                :error-messages="errors"
                                v-model="status"
                                outlined
                                dense
                            ></v-select>
                        </ValidationProvider>
                    </v-col>
                        <v-col cols="12">
                            <v-btn :disabled="invalid || !anyChanged" type="submit" block color="#00B5AD" style="color: white;font-size: 15px;height: 45px">تعديل</v-btn>
                        </v-col>
                        </v-row>
                </v-form>
        </validation-observer>

    </app-dialog>
</template>

<script>

import AppDialog from 'Components/AppDialog/AppDialog.vue';
import { updateTerm } from 'Actions';
// import SelectInput from "@/components/select-input";
import DateCustomer from "@/components/date-customer";
import InputText from "@/components/input-text";

export default {
    components: {
        AppDialog,
        InputText,
        DateCustomer,
        // SelectInput,
    },
    props: {
        dialogStatus: {
            type: Boolean,
            default: false,
        },
        term: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            items: [
                'ربط ', 'مراجعه', 'تكرار',
            ],
            styleSearch: {
                backgroundColor: 'transparent',
                border: "1px solid #E2E2EA",
                borderRadius: "10px",

            },
            loadingTermsStatus: false,
            fetchTermsApiError: {
                status: false,
                error: {},
            },
            submitOnProgress: false,
            createTermApiError: {
                status: false,
                error: {},
            },
            booleanOptions: [
            {
                id: 1,
                text: 'نعم',
                value: 1,
            },
            {
                id: 2,
                text: 'لا',
                value: 0,
            },
            ],
            name: '',
            startDate: null,
            endDate: null,
            termFinalExam: 0,
            status: 0,
            showEndTerm: 0,
            startDay: null,
            rules: {
                name: {
                    required: true,
                }
            }
        }
    },
    computed: {
        anyChanged() {
            let term = this.term;
            if (
                this.name === term.name &&
                this.startDate === term.start_date &&
                this.endDate === term.end_date &&
                this.status === term.is_active &&
                this.showEndTerm === term.end_term_show && 
                this.termFinalExam === term.end_term_exam && 
                this.startDay === term.day_start_hour
            ) {
                return false;
            }

            return true;
        }
    },
    methods: {
        async updateTerm() {
            this.submitOnProgress = true;
            this.updateTermApiError = {
                status: false,
                error: {},
            };
            let term = this.term;
            let data = {};
            if(term.name !== this.name) data.name = this.name;
            if(term.start_date !== this.startDate) data.startDate = this.startDate;
            if(term.end_date !== this.endDate) data.endDate = this.endDate;
            if(term.is_active !== this.status) data.status = this.status;
            if(term.end_term_show !== this.showEndTerm) data.showEndTerm = this.showEndTerm;
            if(term.end_term_exam !== this.termFinalExam) data.termFinalExam = this.termFinalExam;
            if(term.day_start_hour !== this.startDay) data.startDay = this.startDay;
            try {
                let res = await updateTerm(term.id, data);
                if (res.data.status) {
                    this.termUpdated();
                } else {
                    this.$toast.error(res.data.message);
                    this.updateTermApiError.status = true;
                    this.updateTermApiError.error.message = res.data.message;
                }
            } catch (error) {
                this.$toast.error('حدث خطأ ما');
                this.updateTermApiError.status = true;
                this.updateTermApiError.error.message = error.response ? error.response.data : error;
            } finally {
                this.submitOnProgress = false;
            }
        },
        resetForm() {
            let term = this.term;
            this.name = term.name;
            this.startDate = term.start_date;
            this.endDate = term.end_date;
            this.status = term.is_active;
            this.showEndTerm = term.end_term_show;
            this.termFinalExam = term.end_term_exam; 
            this.startDay = term.day_start_hour;
            this.$nextTick(() => {
                // this.$refs.fo rm.reset();
            });
        },
           termUpdated() {
            this.$toast.success('تمت العملية بنجاح');
        },
      
        setup() {
            this.resetForm();
        },
    },
    created() {
        this.setup();
    }
}
</script>