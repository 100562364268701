/**
 * Nazem Global Components
 */

import { ValidationObserver, ValidationProvider } from 'vee-validate';
// import AppSectionLoader from 'Components/AppSectionLoader/AppSectionLoader.vue';
// import AppCard from 'Components/AppCard/AppCard.vue';
// import ApiError from 'Components/ApiError.vue';
import ConfirmationDialog from 'Components/ConfirmationDialog/ConfirmationDialog.vue';


const GlobalComponents = {
   install(Vue) {
      Vue.component('ValidationObserver', ValidationObserver);
      Vue.component('ValidationProvider', ValidationProvider);
      // Vue.component('AppSectionLoader', AppSectionLoader);
      // Vue.component('AppCard', AppCard);
      // Vue.component('ApiError', ApiError);
      Vue.component('ConfirmationDialog', ConfirmationDialog);
   }
}

export default GlobalComponents
