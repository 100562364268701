import axios from "axios";

import { getToken } from "@/plugins/cookies";
import Cookies from "js-cookie";
const axiosInstance = axios.create({
  baseURL: 'https://api.special-path.com/api',    // timeout: 5000, // Timeout in milliseconds
    // baseURL: 'http://localhost:8000/api',    // timeout: 5000, // Timeout in milliseconds

  headers: {
    "Content-Type": "application/json",
    language: Cookies.get("done-lang") || "en",
    // Add any other default headers here
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    // Modify request config (e.g., add authentication token)
    const userToken = getToken();
    console.log('userToken');
    console.log(userToken);
    console.log('userToken');
    if (userToken) {
      config.headers["Authorization"] = "Bearer " + userToken;
      config.headers["Content-Type"] = "application/json";
      // config.headers['Accept'] = 'application/json';
      // config.headers['Access-Control-Allow-Origin'] = '*';
      // config.headers['language']= Cookies.get("lang") ||'ar'
    }
    return config;
  },
  (error) => {
    // Handle request error
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    // Modify response data
    return response;
  },
  (error) => {
    if (error.response) {
      console.log("error.response -- axios", error.response.data.message);
      console.log("error.response -- axios", error.response.data);
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
