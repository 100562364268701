import Vue from 'vue'
import VueRouter from 'vue-router'
import {  guest, auth,checkPermission } from './middlelwares';
import loginView from "@/views/loginSignup/loginView";
import signupView from "@/views/loginSignup/signupView";
import dashboardView from "@/views/dashboard/dashboardView";
import followUpStudentsView from "@/views/students/FollowUpStudentsView";
import reviewStudentView from "@/views/students/reviewStudentView";
import weeklyNarrativeView from "@/views/students/weeklyNarrativeView";
import studentVacationsView from "@/views/students/studentVacationsView";
import permissionsView from "@/views/students/permissionsView";
import interviewsView from "@/views/interviews/interviewsView";
import recitationView from "@/views/interviews/recitationView";
import questionInterviewsView from "@/views/interviews/questionInterviewsView";
import templatesView from "@/views/interviews/templatesView";
import addQuestionInterView from "@/views/interviews/addQuestionInterView";
import showAllQuestions from "@/views/questions/showAllQuestions";
import admissionRequestsView from "@/views/interviews/admissionRequestsView";
import alarmView from "@/views/records/alarmView";
import warningView from "@/views/records/warningView";
import ourProgramsView from "@/views/ourPrograms/ourProgramsView";
import registrationProgramView from "@/views/ourPrograms/registrationProgramView";
import branchesView from "@/views/branches/branchesView";
import detailsBranchesView from "@/views/branches/detailsBranchesView";
import classesStudentView from "@/views/others/classesStudentView";
import tracksView from "@/views/tracks/tracksView";
import detailsTracksView from "@/views/tracks/detailsTracksView";
import detailsTracksTowView from "@/views/tracks/detailsTracksTowView";
import addTracksView from "@/views/tracks/addTracksView";
import editTracksView from "@/views/tracks/editTracksView";
import recitersView from "@/views/others/recitersView";
import recitationAlertsView from "@/views/others/recitationAlertsView";
import detailsRecitationAlertsView from "@/views/others/detailsRecitationAlertsView";
import forbiddenView from "@/views/forbiddenView";
import officialHolidaysView from "@/views/students/officialHolidaysView";
import executeDutiesView from "@/views/executeDuties/executeDutiesView";
import registrationStudentView from "@/views/loginSignup/registrationStudentView";

import {
    fetchBranchById,
} from "Actions";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'dashboard',
        permission: ['dashboard.view'],
        component: dashboardView,
        meta: {
            meta: {
                models: ['dashboard'],
                requiresAuth: true,
                requiresPermission: true,
                middleware: [ auth,checkPermission],
                permission: ['view.dasshboard'],
                title: "dashboard",
                breadcrumbs: [{
                    text: 'dashboard',
                    disabled: true,
                    to: { name: 'dashboard' },
                    first: true,
                    last: false,
                },]
            },
        }
    },
    {
        path: '/executeDutiesView',
        name: 'executeDutiesView',
        component: executeDutiesView,
        permission: ['executeDutiesView.view'],
        meta: {
            models: [],
            requiresAuth: true,
            requiresPermission: true,
            middleware: [ auth,checkPermission],
            permission: ['view.duties'],
            title: "Execute Dutes",
            breadcrumbs: [{
                text: 'executeDutiesView',
                disabled: true,
                to: { name: 'executeDutiesView' },
                first: true,
                last: false,
            },]
        }
    },
    {
        path: '/login',
        name: 'login',
        permission: ['login.view'],
        component: loginView,
        meta: {
            middleware: [guest],
        },
    },
    {
        path: '/signup',
        name: 'signup',
        permission: ['signup.view'],
        component: signupView,
        // meta: {
        //     middleware: [guest],
        // },
    },
    {
        path: '/registrationStudent',
        name: 'registrationStudent',
        permission: ['registrationStudent.view'],
        component: registrationStudentView,
        meta: {
            models: [],
            requiresAuth: true,
            requiresPermission: true,
            middleware: [ auth,checkPermission],
            permission: ['view.duties'],
            title: "Execute Dutes",
            breadcrumbs: [{
                text: 'executeDutiesView',
                disabled: true,
                to: { name: 'executeDutiesView' },
                first: true,
                last: false,
            },]
        }
    },
    {
        path: '/followStudent',
        name: 'followStudent',
        permission: ['followStudent.view'],
        component: followUpStudentsView,
          meta: {
            models: [],
            requiresAuth: true,
            requiresPermission: true,
            middleware: [ auth,checkPermission],
            permission: ['view.duties'],
            title: "Execute Dutes",
            breadcrumbs: [{
                text: 'executeDutiesView',
                disabled: true,
                to: { name: 'executeDutiesView' },
                first: true,
                last: false,
            },]
        }
    },
    {
        path: '/review',
        name: 'review',
        permission: ['review.view'],
        component: reviewStudentView,
          meta: {
            models: [],
            requiresAuth: true,
            requiresPermission: true,
            middleware: [ auth,checkPermission],
            permission: ['view.duties'],
            title: "Execute Dutes",
            breadcrumbs: [{
                text: 'executeDutiesView',
                disabled: true,
                to: { name: 'executeDutiesView' },
                first: true,
                last: false,
            },]
        }

    },
    {
        path: '/weeklyStudents',
        name: 'weeklyStudents',
        permission: ['weeklyStudents.view'],
        component: weeklyNarrativeView,
          meta: {
            models: [],
            requiresAuth: true,
            requiresPermission: true,
            middleware: [ auth,checkPermission],
            permission: ['view.duties'],
            title: "Execute Dutes",
            breadcrumbs: [{
                text: 'executeDutiesView',
                disabled: true,
                to: { name: 'executeDutiesView' },
                first: true,
                last: false,
            },]
        }
    },
    {
        path: '/officialHolidays',
        name: 'officialHolidays',
        permission: ['officialHolidays.view'],
        component: officialHolidaysView,
          meta: {
            models: [],
            requiresAuth: true,
            requiresPermission: true,
            middleware: [ auth,checkPermission],
            permission: ['view.duties'],
            title: "Execute Dutes",
            breadcrumbs: [{
                text: 'executeDutiesView',
                disabled: true,
                to: { name: 'executeDutiesView' },
                first: true,
                last: false,
            },]
        }
    },
    {
        path: '/studentVacations',
        name: 'studentVacations',
        permission: ['studentVacations.view'],
        component: studentVacationsView,
          meta: {
            models: [],
            requiresAuth: true,
            requiresPermission: true,
            middleware: [ auth,checkPermission],
            permission: ['view.duties'],
            title: "Execute Dutes",
            breadcrumbs: [{
                text: 'executeDutiesView',
                disabled: true,
                to: { name: 'executeDutiesView' },
                first: true,
                last: false,
            },]
        }
    },
    {
        path: '/permissionsView',
        name: 'permissionsView',
        permission: ['permissionsView.view'],
        component: permissionsView,
          meta: {
            models: [],
            requiresAuth: true,
            requiresPermission: true,
            middleware: [ auth,checkPermission],
            permission: ['view.duties'],
            title: "Execute Dutes",
            breadcrumbs: [{
                text: 'executeDutiesView',
                disabled: true,
                to: { name: 'executeDutiesView' },
                first: true,
                last: false,
            },]
        }
    },
    {
        path: '/interviewsView',
        name: 'interviewsView',
        permission: ['interviewsView.view'],
        component: interviewsView,
          meta: {
            models: [],
            requiresAuth: true,
            requiresPermission: true,
            middleware: [ auth,checkPermission],
            permission: ['view.duties'],
            title: "Execute Dutes",
            breadcrumbs: [{
                text: 'executeDutiesView',
                disabled: true,
                to: { name: 'executeDutiesView' },
                first: true,
                last: false,
            },]
        }
    },
    {
        path: '/recitationView/:id',
        name: 'recitationView',
        permission: ['recitationView.view'],
        component: recitationView,
          meta: {
            models: [],
            requiresAuth: true,
            requiresPermission: true,
            middleware: [ auth,checkPermission],
            permission: ['view.duties'],
            title: "Execute Dutes",
            breadcrumbs: [{
                text: 'executeDutiesView',
                disabled: true,
                to: { name: 'executeDutiesView' },
                first: true,
                last: false,
            },]
        }
    },
    {
        path: '/questionInterview/:id',
        name: 'questionInterview',
        permission: ['questionInterview.view'],
        component: questionInterviewsView,
          meta: {
            models: [],
            requiresAuth: true,
            requiresPermission: true,
            middleware: [ auth,checkPermission],
            permission: ['view.duties'],
            title: "Execute Dutes",
            breadcrumbs: [{
                text: 'executeDutiesView',
                disabled: true,
                to: { name: 'executeDutiesView' },
                first: true,
                last: false,
            },]
        }
    },
    {
        path: '/templates',
        name: 'templates',
        permission: ['templates.view'],
        component: templatesView,
        meta: {
            models: ['templates'],
            requiresAuth: true,
            requiresPermission: true,
            middleware: [ auth,checkPermission],
            permission: [],
            title: "templates",
            breadcrumbs: [{
                text: 'templates',
                disabled: true,
                to: { name: 'templates' },
                first: true,
                last: false,
            }]
        }
    },
    {
        path: '/roles',
        name: 'RolesAndPermissions',
        component: () =>
            import('Views/rolesAndPermissions/ManageView.vue'),
        meta: {
            models: ['roles'],
            requiresAuth: true,
            requiresPermission: true,
            middleware: [ auth,checkPermission],
            permission: ['view.roles'],
            title: "Roles & Permissions",
            breadcrumbs: [
                {
                    text: 'Roles & Permissions',
                    disabled: false,
                    to: { name: 'RolesAndPermissions' },
                    first: true,
                    last: false,
                },
            ]
        }
    },
    {
        path: '/addQuestionInterView/:id',
        name: 'addQuestionInterView',
        permission: ['addQuestionInterView.view'],
        component: addQuestionInterView,
          meta: {
            models: [],
            requiresAuth: true,
            requiresPermission: true,
            middleware: [ auth,checkPermission],
            permission: ['view.duties'],
            title: "Execute Dutes",
            breadcrumbs: [{
                text: 'executeDutiesView',
                disabled: true,
                to: { name: 'executeDutiesView' },
                first: true,
                last: false,
            },]
        }
    },
    {
        path: '/allquestions',
        name: 'allquestions',
        permission: ['questions.view'],
        component: showAllQuestions,
          meta: {
            models: [],
            requiresAuth: true,
            requiresPermission: true,
            middleware: [ auth,checkPermission],
            permission: ['view.duties'],
            title: "Execute Dutes",
            breadcrumbs: [{
                text: 'executeDutiesView',
                disabled: true,
                to: { name: 'executeDutiesView' },
                first: true,
                last: false,
            },]
        }
    },
    {
        path: '/admissionRequests',
        name: 'admissionRequests',
        permission: ['admissionRequests.view'],
        component: admissionRequestsView,
          meta: {
            models: [],
            requiresAuth: true,
            requiresPermission: true,
            middleware: [ auth,checkPermission],
            permission: [],
            title: "Execute Dutes",
            breadcrumbs: [{
                text: 'executeDutiesView',
                disabled: true,
                to: { name: 'executeDutiesView' },
                first: true,
                last: false,
            },]
        }
    },
    {
        path: '/alarmView',
        name: 'alarm',
        permission: ['alarm.view'],
        component: alarmView,
          meta: {
            models: [],
            requiresAuth: true,
            requiresPermission: true,
            middleware: [ auth,checkPermission],
            permission: ['view.duties'],
            title: "Execute Dutes",
            breadcrumbs: [{
                text: 'executeDutiesView',
                disabled: true,
                to: { name: 'executeDutiesView' },
                first: true,
                last: false,
            },]
        }
    }, {
        path: '/warningView',
        name: 'warningView',
        permission: ['warningView.view'],
        component: warningView,
          meta: {
            models: [],
            requiresAuth: true,
            requiresPermission: true,
            middleware: [ auth,checkPermission],
            permission: ['view.duties'],
            title: "Execute Dutes",
            breadcrumbs: [{
                text: 'executeDutiesView',
                disabled: true,
                to: { name: 'executeDutiesView' },
                first: true,
                last: false,
            },]
        }
    },
    {
        path: '/ourPrograms',
        name: 'ourPrograms',
        permission: ['ourPrograms.view'],
        component: ourProgramsView,
          meta: {
            models: [],
            requiresAuth: true,
            requiresPermission: true,
            middleware: [ auth,checkPermission],
            permission: ['view.duties'],
            title: "Execute Dutes",
            breadcrumbs: [{
                text: 'executeDutiesView',
                disabled: true,
                to: { name: 'executeDutiesView' },
                first: true,
                last: false,
            },]
        }
    },
    {
        path: '/registrationProgram',
        name: 'registrationProgram',
        permission: ['registrationProgram.view'],
        component: registrationProgramView,
          meta: {
            models: [],
            requiresAuth: true,
            requiresPermission: true,
            middleware: [ auth,checkPermission],
            permission: ['view.duties'],
            title: "Execute Dutes",
            breadcrumbs: [{
                text: 'executeDutiesView',
                disabled: true,
                to: { name: 'executeDutiesView' },
                first: true,
                last: false,
            },]
        }
    },
    {
        path: '/branches',
        name: 'branches',
        permission: ['branches.view'],
        component: branchesView,
          meta: {
            models: [],
            requiresAuth: true,
            requiresPermission: true,
            middleware: [ auth,checkPermission],
            permission: ['view.duties'],
            title: "Execute Dutes",
            breadcrumbs: [{
                text: 'executeDutiesView',
                disabled: true,
                to: { name: 'executeDutiesView' },
                first: true,
                last: false,
            },]
        }
    },
    {
        path: '/detailsBranches',
        name: 'detailsBranches',
        permission: ['detailsBranches.view'],
        component: detailsBranchesView,
          meta: {
            models: [],
            requiresAuth: true,
            requiresPermission: true,
            middleware: [ auth,checkPermission],
            permission: ['view.duties'],
            title: "Execute Dutes",
            breadcrumbs: [{
                text: 'executeDutiesView',
                disabled: true,
                to: { name: 'executeDutiesView' },
                first: true,
                last: false,
            },]
        }
    },
    {
        path: '/detailsBranches/:id',
        name: 'detailsBranchesView',
        permission: ['detailsBranchesView.view'],
        component: () =>
            import('Views/branches/detailsBranchesView.vue'),
        props: (route) => ({ branch: route.meta.tmp.branch }),
        meta: {
            models: [],
            middleware: [ auth,checkPermission],
            tmp: {},
            async beforeResolve(routeTo, routeFrom, next) {
                try {
                    let res = await fetchBranchById(routeTo.params.id);
                    if (res.data.status) {
                        routeTo.meta.tmp.branch = res.data.data;
                        next();
                    } else {
                        next({ name: 'forbiddenAcees', params: { resource: 'profile' } })
                    }
                } catch (error) {
                    next({ name: 'forbiddenAcees', params: { resource: 'profile' } })
                }
            },
        },

    },
    {
        path: '/classesStudent',
        name: 'classesStudent',
        permission: ['classesStudent.view'],
        component: classesStudentView
    },
    {
        path: '/tracks',
        name: 'tracks',
        permission: ['tracks.view'],
        component: tracksView
    },
    {
        path: '/detailsTracks/:id',
        name: 'detailsTracks',
        permission: ['detailsTracks.view'],
        component: detailsTracksView
    },
    {
        path: '/edittrack/:id',
        name: 'edittrack',
        permission: ['edittrack.view'],
        component: editTracksView
    },
    {
        path: '/detectInterview/:id',
        name: 'detectInterview',
        permission: ['detectInterview.view'],
        component: interviewsView
    },
    {
        path: '/detailsTracksTow/:id',
        name: 'detailsTracksTow',
        permission: ['detailsTracksTow.view'],
        component: detailsTracksTowView
    },
    {
        path: '/addTracks',
        name: 'addTracks',
        permission: ['addTracks.view'],
        component: addTracksView
    },
    {
        path: '/reciters',
        name: 'reciters',
        permission: ['reciters.view'],
        component: recitersView
    },
    {
        path: '/recitationAlerts',
        name: 'recitationAlerts',
        permission: ['recitationAlerts.view'],
        component: recitationAlertsView
    },
    {
        path: '/detailsRecitationAlerts',
        name: 'detailsRecitationAlerts',
        permission: ['detailsRecitationAlerts.view'],
        component: detailsRecitationAlertsView
    },
    {
        path: '/forbiddenAcees',
        name: 'forbiddenAcees',
        component: forbiddenView,
        meta: {
            models: [],
            requiresAuth: true,
            requiresPermission: false,
            middleware: [ auth,checkPermission],
            permission: null,
            title: "",
            breadcrumbs: null
        },
        children: []
    },
]
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})
function nextFactory(context, middleware, index) {
    const subsequentMiddleware = middleware[index];
    // If no subsequent Middleware exists,truig to learn vuejs 3 and the new c
    // the default `next()` callback is returned.
    if (!subsequentMiddleware) return context.next;

    return (...parameters) => {
        // Run the default Vue Router `next()` callback first.
        context.next(...parameters);
        // Then run the subsequent Middleware with a new
        // `nextMiddleware()` callback.
        const nextMiddleware = nextFactory(context, middleware, index + 1);
        subsequentMiddleware({ ...context, next: nextMiddleware });
    };
}
router.beforeResolve(async (routeTo, routeFrom, next) => {
    try {
        // For each matched route...
        for (const route of routeTo.matched) {
            await new Promise((resolve, reject) => {
                if (route.meta && route.meta.beforeResolve) {
                    route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
                        if (args.length) {
                            next(...args)
                            reject(new Error('Redirected'))
                        } else {
                            resolve()
                        }
                    })
                } else {
                    resolve()
                }
            })
        }
    } catch (error) {
        return
    }
    next()
})


router.beforeEach((to, from, next) => {
    
    if (to.meta.middleware) {
        const middleware = Array.isArray(to.meta.middleware)
            ? to.meta.middleware
            : [to.meta.middleware];

        const context = { from, next, router, to };
        const nextMiddleware = nextFactory(context, middleware, 1);

        return middleware[0]({ ...context, next: nextMiddleware });
    }

    return next();
});


export default router
