<template>
  <!-- start page follow up students-->
  <section class="follow-up-student">
    <v-main>
      <div class="container--fluid">
         <div class="top-bar-search">
          <div class="search-section">
            <form>
              <div class="search">
                <v-row>
                  <v-col cols="12" lg="12">
                    <div class="date-top">
                      <div class="container-date">
                        <date-select-modal @date-changed="startDateChanged"/>
                      </div>                              
                    </div>
                  </v-col>
                </v-row>
              </div>
            </form>
          </div>
        </div>
        <!--start section tabs top-->
        <div class="section-tabs">
          <!--content tabs-->
              <div class="tabs-top">
                  <v-tabs color="deep-purple accent-4">
                      <v-tab v-for="item in items" :key="item">{{ item }}</v-tab>
                      <v-tabs-slider color="#00B5AD"></v-tabs-slider>
                      <v-tab-item v-for="(value, index) in allStudents" :key="index">
                          <v-container fluid>
                              <div class="all-card mt-5">
                                  <v-row>
                                      <v-col v-for="student in value" :key="student.id" cols="12" lg="4" md="6">
                                        <card-follow :name="student.name"
                                          :student_name="student.student_name"
                                          :path="student.pathName"
                                          :duration="student.durationName"
                                          :passing-score="student.passingScore"
                                          :status="student.status"
                                          :show-follow-student="true"
                                          :show-save-student="true"/>
                                      </v-col>
                                  </v-row>
                              </div>
                          </v-container>
                      </v-tab-item>
                  </v-tabs>
              </div>
           
       
        </div>
        <!--end section tabs top-->
        <!--start section pagination-->
        <div class="section-pagination">
          <pagination-components/>
        </div>
        <!--end section pagination-->
      </div>
    </v-main>
  </section>
  <!-- end page follow up students-->
</template>
<script>
import CardFollow from "@/components/cards/cardFollow";
import PaginationComponents from "@/components/dashboard/paginationComponents";
import DateSelectModal from "@/components/dashboard/dateSelectModal";
// import {mapGetters} from "vuex";
import { getMethods } from '@/api/Helper';
export default {
  name: "FollowUpStudentsView",
  components: {PaginationComponents, CardFollow, DateSelectModal },
  data() {
    return {
      loadingStudentsStatus: true,
      allStudents: [],
       startDate: null,
      endDate: null,
      fetchStudentsApiError: {
        status: false,
        error: {},
      },
      items: [
        'الحفظ', 'اتقان', 'سماع', 'تكرار', 'ربط', 'مراجعه', 'تسميع', 'اختبار', 'تلاوة', 'سرد اسبوعي',
      ],
    }
  },
  computed: {
    // ...mapGetters('settings', ['allStudents'])
  },
  methods: {
      async getStudents() {
        this.loadingStudentsStatus = true;
        this.fetchStudentsApiError = {
          status: false,
          error: {},
        };
      try {
        if (this.startDate == null) { 
          this.startDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
        }
     
          let res = await getMethods('dailyStudents?day='+this.startDate);
          console.log(res.data);
          if (res.data.status) {
            this.allStudents = res.data.data;
          } else {
            this.fetchStudentsApiError.status = true;
            this.fetchStudentsApiError.error.message = res.data.message;
          }
        } catch (error) {
          this.fetchStudentsApiError.status = true;
          this.fetchStudentsApiError.error.message = error.response ? error.response.data : error;
        } finally {
          this.loadingStudentsStatus = false;
        }
    },
    startDateChanged(val) {
      this.startDate = val;
      this.getStudents();
    },
   
    setup() {
    },
  },
  beforeMount() {
    this.getStudents();
    this.$store.dispatch('settings/pageTitle', ' المتابعة الطلاب اليومية');
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/css/variable.scss";
.follow-up-student {
  background-color: $background-main-page;
  padding: 40px 20px;
  .container--fluid {
    border-radius: 15px;
    background-color: white; 
    padding: 15px
  }
  .all-card {
    padding-top: 25px;
  }
}

   .top-bar-search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    @media only screen and (max-width: 1200px) {
      flex-direction: column;
    }

    .search-section {
      width: 55%;
      @media only screen and (max-width: 1200px) {
        width: 100%;
      }

      .date-top {
        display: flex;
        align-items: center;
        @media only screen and (max-width: 1200px) {
          .container-date {
            width: 33%;

            .theme--light.v-input {
              width: 100%;
            }
          }
          .input-search {
            width: 33%;
          }
        }
      }

      .search {
        display: flex;
        align-items: center;
        padding: 12px 0;

        button {
          border: 1px solid #00B5AD;
          padding: 10px 40px;
          font-size: 14px;
          border-radius: 10px;
          color: #00B5AD;
          margin-right: 10px;
        }

      }
    }

  }
      .container-date {
      margin-right: 10px;
      border: 1px solid #D2D5E1;
      border-radius: 5px;
    }
</style>
