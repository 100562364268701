<template>

    <v-dialog persistent no-click-animation :value="dialogStatus" :max-width="maxWidth">

      <v-card>
          <!-- card title -->
        <v-card-title style="height: 120px;position: relative;" class="info font-weight-bold text-capitalize px-8 ">

            {{ title }}

            <!-- close dialog button -->
           <v-btn
                absolute
                color="#252634"
                fab
                left
                top
                text
                large
                style="width: 32px;height: 32px;top: 0;bottom: 0; margin-bottom: auto; margin-top: auto;"
                @click="$emit(eventName);"
            >
                <v-icon style="font-size:24px;">mdi-close</v-icon>
            </v-btn>
            
        </v-card-title>

        <!-- card body -->
        <v-card-text class="mt-5 px-8">
            <slot></slot>
        </v-card-text>
        
      </v-card>

    </v-dialog>
</template>

<script>
    export default {
        props: {
            dialogStatus: {
                type: Boolean,
                required: true,
                default: false,
            },
            title: {
                type: String,
                required: true,
            },
            eventName: {
                type: String,
                required: true
            },
            maxWidth: {
                type: String,
                default: '500px'
            }
        },
    }

</script>